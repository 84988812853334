<template>
  <ar-select
    ref="select"
    enable-filter
    :items="computedMessageLists"
    :default-select-index="selectedMessageListIndex"
    :placeholder="listPlaceholder"
    :filter-placeholder="filterPlaceholder"
    @select="handleSelect"
    :disabled="disabled"
    @clear="() => { $emit('clear') }"
    @close="() => { $emit('close') }"
    data-test-id="message-list-select"
  >
    <template
      slot="selected-item"
      slot-scope="selectedItemScope"
    >
      <div
        v-if="selectedItemScope.item"
        :style="{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          padding: '0 14px',
        }"
        :data-test-id="`message-list-item-${itemName(selectedItemScope)}`"
      >
        <ar-text
          size="xs"
          :text="itemName(selectedItemScope)"
          :style="{
            color: $arStyle.color.skyBlueGrey800,
          }"
        />
        <ar-text
          size="xs"
          v-if="formatStatsTagText(selectedItemScope.item)"
          :text="formatStatsTagText(selectedItemScope.item)"
          :style="{
            width: 'auto',
            marginLeft: '10px',
            color: $arStyle.color.blueGrey700,
          }"
          weight="normal"
        />
      </div>
      <div
        v-else
        :style="{
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0 14px',
        }"
      >
        <ar-text
          size="xs"
          :text="listPlaceholder"
          :style="{
            color: $arStyle.color.blueGrey600,
          }"
        />
      </div>
    </template>
    <template
      slot="item"
      slot-scope="itemScope"
    >
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          height: '55px',
          padding: '0 15px',
          cursor: 'pointer',
          background: itemScope.active ? $arStyle.color.purple100 : null,
          borderBottom: '1px solid #dcdee4',
        }"
        :data-test-id="`message-list-item-${convertListNameToKebabCase(itemScope.item.name)}`"
      >
        <am2-tag
          v-if="itemScope.item.filterGroupOid && !campaignOid && !eventOid"
          text="Dynamic"
          type="purple"
          shape="rectangle"
          :style="{
            height: '22px',
            marginRight: '8px',
          }"
        />
        <ar-text
          size="xs"
          :text="itemName(itemScope)"
          :style="{
            width: 'auto',
            color: itemScope.active ? '#7344c0' : '#43516b',
          }"
          :weight="itemScope.selected ? 'bold' : 'normal'"
        />
        <ar-text
          size="xs"
          v-if="formatStatsTagText(itemScope.item)"
          :text="formatStatsTagText(itemScope.item)"
          :style="{
            width: 'auto',
            marginLeft: '10px',
            color: itemScope.active ? $arStyle.color.blueGrey800 : $arStyle.color.blueGrey700,
          }"
          :weight="itemScope.selected ? 'bold' : 'normal'"
        />
      </div>
    </template>
    <template
      slot="no-item"
    >
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          height: '55px',
          padding: '0 15px',
        }"
      >
        <ar-text
          size="xs"
          :text="noListAvailableForThisChannel ? 'No lists available for this channel' : 'No available lists'"
        />
      </div>
    </template>
  </ar-select>
</template>

<script>
import { mapState } from 'vuex';
import accounting from 'accounting';
import { capitalizeFirstLetter } from '@/utils/helpers';

export default {
  name: 'MessageListSelect',
  props: {
    value: { // Value equals to message-list-oid
      type: Number,
      default: null,
    },
    channels: {
      type: Array,
      required: true,
      validator: (val) => val.length > 0 && val.every(v => (typeof v) === 'string')
    },
    messageListType: {
      type: String,
      default: null,
      validator: (val) => ['manual', 'auto'].indexOf(val) >= 0
    },
    showStats: {
      type: Boolean,
      default: true,
    },
    campaignOid: { // Limits the message lists to ones associated with the provided campaignOid
      type: [Number, String], // If campaignOid is derived from query param, it'll be a string
      default: null,
    },
    eventOid: {
      type: [Number, String], // If campaignOid is derived from query param, it'll be a string
      default: null,
    },
    facebookPageId: { // If a FB Page is selected, we can get the specific count of recipients for that Page
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedMessageListOid: this.value,
      messageLists: [],
      isFetchingMessageLists: false,
      eventId: null,
      campaignId: null,
    };
  },
  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),
    computedMessageLists() {
      return this.messageLists.map( messageList => {
        return {
          ...messageList,
          name: messageList.meta && messageList.meta.name ? messageList.meta.name : messageList.name,
        }
      });

    },
    selectedMessageListIndex() {
      return this.messageLists.findIndex(messageList => messageList.oid === this.selectedMessageListOid);
    },
    noListAvailableForThisChannel() {
      return !this.isFetchingMessageLists && this.messageLists.length === 0;
    },
    listPlaceholder() {
      return this.campaignOid || this.eventOid ? 'Select recipients' : 'Select a list...';
    },
  },
  watch: {
    value(val) {
      this.selectedMessageListOid = val;
    },
    campaignOid(val) {
      if (val) this.campaignId = val
      this.fetchMessageLists();
    },
    eventOid(val) {
      if (val) this.eventId = val
      this.fetchMessageLists();
    }
  },
  mounted() {
    this.eventId = this.eventOid | this.$route.query.eventOid | null
    this.campaignId = this.campaignOid | this.$route.query.campaignOid | null
    this.fetchMessageLists();
  },
  methods: {
    convertListNameToKebabCase(text) {
      return this.$arUtils.general.generateDataTestPrettyName(text);
    },

    filterPlaceholder(selectedItem) {
      return this.listPlaceholder;
    },

    // For external uses, don't delete it
    openDropdown() {
      this.$refs.select.toggleDropdown(true);
    },

    async fetchMessageLists() {
      try {
        this.isFetchingMessageLists = true;

        if (this.campaignId) {
          this.messageLists = await this.$api.messageLists.fetchCampaignMessageLists(this.promoterOid, this.campaignId);
          if (this.messageLists[0] && !this.selectedMessageListOid) {
            this.handleSelect(this.messageLists[0]);
          }
        } else if (this.eventId) {
          this.messageLists = await this.$api.messageLists.fetchEventMessageLists(this.promoterOid, this.eventId);
          if (this.messageLists[0] && !this.selectedMessageListOid) {
            this.handleSelect(this.messageLists[0]);
          }
        } else {
          const { rows } = await this.$api.messageLists.fetchAll(this.promoterOid, {
            top: 'all',
            type: this.messageListType,
            channels: this.channels,
          });
          this.messageLists = rows;
        }
      } catch (error) {
        console.error(error);
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to fetch message lists',
        });
      } finally {
        this.isFetchingMessageLists = false;
      }
    },
    itemName(itemScope) {
      if (!itemScope) {
        return null;
      }

      if (this.eventOid) {
        return "All attendees"
      } else {
        return itemScope.item.meta && itemScope.item.meta.name ? itemScope.item.meta.name : itemScope.item.name
      }
    },

    formatStatsTagText(item) {
      // We should only show stats if we only have ONE
      // channel because we don't capture stats for
      // combinations of channels.
      //
      // Plus it's too hard to express what the number would represent;
      // Would it be say, the number of people subscribed to email
      // AND sms, or the number of people subscribed to email OR sms?
      if (!this.showStats || this.channels.length > 1) {
        return null;
      } else if (!item.statsSnapshot || !item.statsSnapshot.total) {
        if (item.filterGroupOid === null) { // Manual List
          return '(0 recipients)';
        } else {
          return null;
        }
      }

      // Check to see if the statsSnapshot includes a `valid${channel}` value.
      // This value will be the de-duped list size
      // Otherwise, just return the original channel
      const deDupedName = this.channels[0] ? `valid${capitalizeFirstLetter(this.channels[0])}` : null;
      let totalNumber = 0;

      if (this.channels[0] === 'email' && (!!this.campaignOid || !!this.eventOid)) {
        totalNumber = item.statsSnapshot.total;
      } else if (this.channels[0] === 'sms' && (!!this.campaignOid || !!this.eventOid)) {
        const statSnapshotIsNew = typeof item?.statsSnapshot?.sms?.validMobile !== 'undefined';
        totalNumber = statSnapshotIsNew ? (item.statsSnapshot?.sms?.validMobile || 0) : (item.statsSnapshot.mobileNumber || 0);
      } else if (this.channels[0] === 'sms' && !(this.campaignOid || this.eventOid)) {
        const statSnapshotIsNew = typeof item?.statsSnapshot?.sms?.optedIn !== 'undefined';
        totalNumber = statSnapshotIsNew ? (item.statsSnapshot?.sms?.optedIn || 0) : (item.statsSnapshot?.sms || 0);
      } else if(this.channels[0] === 'facebookMessenger') {
        const pageId = this.facebookPageId || item?.meta?.facebookMessenger?.pageId;
        if (!!item.statsSnapshot.facebook && (item.statsSnapshot.facebook[pageId] || item.statsSnapshot.facebook[pageId] === 0)) {
          totalNumber = item.statsSnapshot.facebook[pageId];
        } else if (item.statsSnapshot.facebookMessenger) { // facebookMessenger is the old method of tracking FB stats. We'll remove this eventually
          totalNumber = item.statsSnapshot.facebookMessenger;
        } else {
          return '';
        }
      } else if (deDupedName && !!item.statsSnapshot[deDupedName]) {
        totalNumber = item.statsSnapshot[deDupedName];
      } else {
        totalNumber = item.statsSnapshot[this.channels[0]];
      }

      if (totalNumber === 1) {
        return `(1 recipient)`;
      } else {
        return `(${accounting.formatNumber(totalNumber)} of ${accounting.formatNumber(item.statsSnapshot.total)} recipients)`;
      }
    },
    handleSelect(item) {
      this.selectedMessageListOid = item.oid;
      this.$emit('input', item.oid);
      this.$emit('select', item);
    },
  },
};
</script>
