<template>
  <div class="brand-select">
    <ar-select
      ref="select"
      :items="selectItems"
      :style="{
        height: '100%',
      }"
      enable-filter
      :filter-placeholder="filterPlaceholder"
      @select="handleSelect"
      @actionClick="handleActionClick"
      @close="() => { $emit('close') }"
      @filterStringInput="handleFilterStringInput"
      data-test-id="brand-select-dropdown"
      layer="global-regular"
      :default-filter-string="brandName"
      :filter="brandFilter"
    >

      <template
        slot="selected-item"
      >
        <div
          :style="{
            display: 'flex',
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0 14px',
          }"
          :data-test-id="`brand-select-out-of-scope-selected-item`"
        >
          <ar-text
            size="xs"
            :text="brandName || 'Enter brand name...'"
            :style="{
              color: brandName ? $arStyle.color.blueGrey800 : $arStyle.color.blueGrey600,
            }"
          />
        </div>
      </template>

      <template
        slot="item"
        slot-scope="itemScope"
      >
        <!-- Action Item -->
        <div
          v-if="itemScope.item.isAction"
          :data-test-id="`brand-action-item-${itemScope.item.key}`"
          class="brand-action-item"
          :style="{
            background: itemScope.active ? $arStyle.color.purple100 : null,
          }"
        >
          <ar-icon
            class="plus-icon"
            name="circle-plus"
            :color="$arStyle.color.purple500"
            height="16px"
            width="16px"
          />
          <ar-text
            size="xs"
            :text="itemScope.item.name"
            :style="{
              width: 'auto',
              color: $arStyle.color.purple500,
              marginLeft: '8px',
            }"
            weight="bold"
          />
        </div>
        <!-- Normal Item -->
        <div
          v-else
          :data-test-id="`brand-item-${itemScope.item.key}`"
          :style="{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '55px',
            padding: '0 15px',
            cursor: 'pointer',
            borderBottom: `1px solid ${$arStyle.color.blueGrey500}`,
            background: itemScope.active ? $arStyle.color.purple100 : null,
          }"
        >
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
              flexGrow: '1',
              overflow: 'hidden',
              justifyContent: 'flex-start',
            }"
          >
            <ar-text
              size="xs"
              :text="itemScope.item.name"
              :style="{
                width: 'auto',
                color: itemScope.active ? $arStyle.color.purple500 : $arStyle.color.blueGrey800,
              }"
              weight="normal"
            />
          </div>
          <ar-link-button
            v-if="itemScope.item.key != 'new_brand'"
            text="save"
            @click="saveButtonPressed(itemScope.item)"
            :data-test-id="`brand-select-${itemScope.item.key}-save`"
          />
          <ar-link-button
            v-if="itemScope.item.key != 'new_brand'"
            class="u-margin-left-2"
            text="delete"
            @click="deleteButtonPressed(itemScope.item)"
            :data-test-id="`brand-select-${itemScope.item.key}-delete`"
          />
        </div>
      </template>
    </ar-select>
  </div>
</template>

<script>
  /*
   * Shows a list of Email senders or FB Pages, based on which channel is provided
   * Used to tell the parent what the source of a message should be.
   */
  import { mapActions, mapMutations, mapState } from 'vuex';

  export default {
    name: 'BrandSelect',

    props: {
      brands: {
        type: Array,
        default: null,
      },
      isNewBrand: {
        type: Boolean,
        default: false,
      },
      value: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        creatingNewBrand: false,
        brandName: this.value,
      };
    },

    computed: {
      selectItems() {
        let newBrand = []

        const brandExists = this.brands.some(brand => {
          return brand.name.toLowerCase() === (this.brandName || '').toLowerCase();
        });

        if (!brandExists && this.brandName) {
          newBrand = [{
            isAction: true,
            name: `Create new brand \`${this.brandName}\``,
            key: 'create_new_brand',
          }];
        }

        const items = [
          ...newBrand,
          ...this.brands,
        ];

        return items;
      },
    },

    watch: {
      value(newVal) {
        this.brandName = newVal;
      },
    },

    methods: {
      ...mapActions([
        'SHOW_CONFIRM',
      ]),

      filterPlaceholder() {
        return 'Enter brand name...';
      },

      async saveButtonPressed(item) {
        const response = await this.SHOW_CONFIRM({ messageHtml: `Are you sure you want to <b>save</b> the actions into ${item.name}?<br />The current actions will be overwritten.` });
        if (response) {
          this.$emit('save', item)
        }
      },

      async deleteButtonPressed(item) {
        const response = await this.SHOW_CONFIRM({ messageHtml: `Are you sure you want to <b>delete</b> ${item.name}?<br />This action cannot be undone.` });
        if (response) {
          this.$emit('delete', item)
        }
      },

      handleSelect(item) {
        this.$emit('select', item);
      },

      handleActionClick(action) {
        if (action.key === 'create_new_brand') {
          this.$emit('create', this.brandName);
        }
      },

      handleFilterStringInput(string) {
        this.$emit('input', string);
        this.brandName = string
      },

      brandFilter(item) {
        return item;
      },
    }
  };
</script>

<style lang="scss" scoped>
.brand-select {
  height: 50px;
}

.brand-action-item {
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0 15px;
  cursor: pointer;
  border-bottom: 1px solid $blueGrey500;
}
</style>
