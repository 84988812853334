<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      class="campaign-social-action-settings-modal"
      :header="title"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
      data-test-id="campaign-social-action-settings-modal"
    >
      <div
        slot="body"
        :class="[
          'campaign-social-action-settings-modal-body',
        ]"
      >
        <div ref="fields">
          <ar-field
            v-if="displayLabel"
            label="Button label"
            :error-messages="[
              veeErrors.first('label'),
            ]"
          >
            <ar-input
              :data-test-id="`campaign-settings-button-label`"
              v-model="editLabel"
              data-vv-name="label"
              data-vv-as="button label"
              :placeholder="defaultLabel"
            />
          </ar-field>
          <ar-field
            label="Points"
            :style="{
              marginTop: '25px',
            }"
            :error-messages="[
              veeErrors.first('points'),
            ]"
          >
            <ar-input
              :data-test-id="`campaign-settings-points`"
              v-model="editPoints"
              v-validate="{
                required: true,
                numeric: true,
              }"
              data-vv-name="points"
              data-vv-as="points"
              placeholder="Points"
            />
          </ar-field>
          <ar-field
            v-if="displayDisclaimer"
            label="Custom Disclaimer"
            :style="{
              marginTop: '25px',
            }"
            :error-messages="[
              veeErrors.first('disclaimer'),
            ]"
          >
            <ar-textarea
              :data-test-id="`campaign-settings-custom-disclaimer`"
              :rows="4"
              v-model="editDisclaimer"
              data-vv-name="disclaimer"
              data-vv-as="disclaimer"
              placeholder="Enter disclaimer"
            />
          </ar-field>
        </div>
      </div>
      <div
        slot="footer"
        :class="[
          'campaign-social-action-settings-modal-footer',
        ]">
        <ar-simple-button
          text="Save"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
          data-test-id="save-campaign-social-action-modal"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
export default {
  name: 'CampaignSocialActionSettingsModal',
  components: {},
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    defaultLabel: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    points: {
      type: Number,
      default: null,
    },
    disclaimer: {
      type: String,
      default: null,
    },
    displayDisclaimer: {
      type: Boolean,
      default: false,
    },
    displayLabel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editLabel: null,
      editPoints: null,
      editDisclaimer: null,
    };
  },

  watch: {
    opened(val) {
      if (val) {
        this.editLabel = this.label;
        this.editPoints = this.points;
        this.editDisclaimer = this.disclaimer;
      }
    },

  },

  methods: {
    async validateFields() {
      return await this.$validator.validateAll();
    },
    async handleConfirmClick() {
      const valid = await this.validateFields();
      if (!valid) {
        return;
      }
      this.$emit('confirm', this.editLabel || null, this.editPoints ? parseInt(this.editPoints) : null, this.editDisclaimer || null);
      this.editLabel = null;
      this.editPoints = null;
      this.editDisclaimer = null;
    },
    handleCloseClick() {
      this.$emit('cancel');
      this.editLabel = null;
      this.editPoints = null;
      this.editDisclaimer = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-social-action-settings-modal {
  .campaign-social-action-settings-modal-body {
    padding: 24px 30px 46px;
  }
  .campaign-social-action-settings-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 28px 25px 23px;
  }
}
</style>
