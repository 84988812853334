var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"campaign-edit-connect"},[_c('am2-edit-privacy-policy-modal',{attrs:{"opened":_vm.displayEditPrivacyPolicyModal,"default-company-name":_vm.editPrivacyPolicyDefaultCompanyName,"default-url":_vm.editPrivacyPolicyDefaultUrl},on:{"confirm":_vm.handleEditPrivacyPolicyConfirm,"cancel":_vm.handleEditPrivacyPolicyCancel}}),_vm._v(" "),_c('am2-campaign-social-action-settings-modal',{attrs:{"opened":_vm.displayCampaignSocialActionSettingsModal,"title":_vm.campaignSocialActionData.title,"default-label":_vm.campaignSocialActionData.defaultLabel,"label":_vm.campaignSocialActionData.label,"points":_vm.campaignSocialActionData.points,"disclaimer":_vm.campaignSocialActionData.disclaimer,"display-disclaimer":['sms', 'email'].indexOf(_vm.campaignSocialActionData.key) > -1,"display-label":['facebook:messenger'].indexOf(_vm.campaignSocialActionData.key) === -1},on:{"confirm":_vm.handleSocialActionSettingsConfirm,"cancel":_vm.handleSocialActionSettingsCancel}}),_vm._v(" "),_c('ar-text',{staticClass:"step-heading",style:({
      color: '#9FA8B5',
      marginBottom: '8px',
    }),attrs:{"size":"xs","text":"STEP 6"}}),_vm._v(" "),_c('am2-heading',{style:({marginBottom: '38.5px'}),attrs:{"type":"h2","size":"lg","title":"Earn more points"}}),_vm._v(" "),_c('am2-heading',{style:({ marginBottom: '12px' }),attrs:{"type":"h3","size":"md","title":"Follow"}}),_vm._v(" "),_c('p',{staticClass:"large"},[_vm._v("\n    Fans earn points by completing additional actions. Choose what actions you want fans to do.\n  ")]),_vm._v(" "),(!!_vm.editCampaign && !!_vm.editCampaign.socialActions && !!_vm.editCampaign.socialActions.socialAccounts)?_c('div',_vm._l((_vm.editCampaign.socialActions.socialAccounts),function(account,accountIndex){return _c('ar-field',{key:("f_" + accountIndex),ref:"fields",refInFor:true,class:[
        'social-follow-channels',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3' ],style:({
        marginTop: '30px',
      }),attrs:{"data-test-id":("account-" + accountIndex),"name":("social-follow-channels_" + accountIndex)}},[_c('div',{staticClass:"follow-account-label"},[_c('div',{staticClass:"label-description"},[_c('div',{staticClass:"heading-section"},[_c('ar-text',{staticClass:"heading",attrs:{"size":"xs","text":"Brand name","weight":"bold"}}),_vm._v(" "),(_vm.editCampaign.socialActions.socialAccounts.length > 1)?_c('ar-icon-button',{staticClass:"remove-social-account",style:({
                minHeight: 'unset',
                
              }),attrs:{"icon-props":{
                name: 'discard',
                height: '14px',
              },"data-test-id":("remove-social-account-" + accountIndex)},on:{"click":function($event){return _vm.handleSocialAccountDiscardClick(accountIndex)}}}):_vm._e()],1),_vm._v(" "),_c('ar-link-button',{attrs:{"text":!!account.additionalInfo && !!account.additionalInfo.privacyPolicy ? "Edit privacy policy" : "Add privacy policy","data-test-id":("edit-privacy-policy-" + accountIndex)},on:{"click":function($event){return _vm.handleEditPrivacyPolicyClick(accountIndex)}}})],1),_vm._v(" "),_c('div',[_c('span',[_vm._v("Name of the event or brand")])]),_vm._v(" "),_c('br')]),_vm._v(" "),_c('am2-brand-select',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: !!account && !!account.actions && account.actions.length > 0,
        }),expression:"{\n          required: !!account && !!account.actions && account.actions.length > 0,\n        }"}],staticClass:"u-margin-bottom-1",attrs:{"data-test-id":("account-" + accountIndex + "-brand-select"),"data-vv-name":("accountName-" + accountIndex),"data-vv-as":"brand name","value":account.name,"account-name":account.name,"brands":_vm.brands,"is-new-brand":account.saveNew === true},on:{"create":function (name) { return _vm.handleBrandCreateNew(name, account); },"select":function (brand) { return _vm.handleBrandSelect(brand, accountIndex); },"delete":_vm.handleBrandDelete,"save":function (brand) { return _vm.handleBrandSave(brand, accountIndex); },"input":function (name) { return _vm.debounceHandleSaveAccountName(name, accountIndex); }}}),_vm._v(" "),(_vm.veeErrors.has(("accountName-" + accountIndex)))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"type":"error","text":_vm.veeErrors.first(("accountName-" + accountIndex))}}):_vm._e(),_vm._v(" "),_c('draggable',{attrs:{"list":account.actions},on:{"change":function (event) { return _vm.handleMove(event, accountIndex); },"end":_vm.onDragEnd,"start":_vm.onDragStart}},[_c('transition-group',_vm._l((account.actions),function(socialAction,socialActionIdx){
        var _obj;
return _c('div',{key:socialAction.widgetToken,staticClass:"social-account__item",attrs:{"data-test-id":("account-" + accountIndex + "-action-" + (socialAction.key) + "-" + socialActionIdx)}},[_c('div',{style:({ margin: '5px auto' })},[_c('div',{class:[
                'social-account__item-inner',
                _vm.currentFocusFollowOption === ("socialInput-" + accountIndex + "-" + socialActionIdx) && 'social-account__item-inner-focus' ]},[_c('ar-icon',{staticClass:"connect-icon",attrs:{"name":_vm.followOptionsObject[socialAction.key] ? _vm.followOptionsObject[socialAction.key].iconName : null,"color":_vm.followOptionsObject[socialAction.key] ? _vm.followOptionsObject[socialAction.key].iconColor : null,"height":"16px","width":"16px"}}),_vm._v(" "),_c('ar-text',{staticClass:"social-account__item-label",attrs:{"size":"xs","weight":"bold","text":_vm.formatName(socialAction.key)}}),_vm._v(" "),(socialAction.key === 'email')?_c('am2-message-list-select',{directives:[{name:"validate",rawName:"v-validate",value:({
                    required: true,
                  }),expression:"{\n                    required: true,\n                  }"}],staticClass:"select",attrs:{"data-vv-scope":("account-" + accountIndex),"data-vv-name":(accountIndex + "-email-" + socialActionIdx),"data-vv-as":_vm.followOptionsObject[socialAction.key].name,"value":(socialAction.value != 'enable' && socialAction.value != 'disable') ? socialAction.value : null,"channels":['email'],"message-list-type":"manual","show-stats":false,"data-test-id":(accountIndex + "-email-" + socialActionIdx + "-dropdown")},on:{"input":function (messageListOid) { return _vm.handleEmailMessageListChange(messageListOid, accountIndex, socialActionIdx); }}}):(socialAction.key === 'sms')?_c('am2-message-list-select',{directives:[{name:"validate",rawName:"v-validate",value:({
                    required: true,
                  }),expression:"{\n                    required: true,\n                  }"}],staticClass:"select",attrs:{"data-vv-scope":("account-" + accountIndex),"data-vv-name":(accountIndex + "-sms-" + socialActionIdx),"data-vv-as":_vm.followOptionsObject[socialAction.key].name,"value":(socialAction.value != 'enable' && socialAction.value != 'disable') ? socialAction.value : null,"channels":['sms'],"message-list-type":"manual","show-stats":false,"data-test-id":(accountIndex + "-sms-" + socialActionIdx + "-dropdown")},on:{"input":function (messageListOid) { return _vm.handleSmsMessageListChange(messageListOid, accountIndex, socialActionIdx); }}}):_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.followOptionsObject[socialAction.key] ? ( _obj = {
                    required: true
                  }, _obj[("" + (_vm.followOptionsObject[socialAction.key].vValidateRule))] = true, _obj ) : null),expression:"followOptionsObject[socialAction.key] ? {\n                    required: true,\n                    [`${followOptionsObject[socialAction.key].vValidateRule}`]: true,\n                  } : null"}],ref:("socialInput-" + accountIndex + "-" + socialActionIdx),refInFor:true,staticClass:"input",style:({
                    background: 'none',
                  }),attrs:{"data-vv-scope":("account-" + accountIndex),"value":socialAction.value,"placeholder":_vm.followOptionsObject[socialAction.key] ? _vm.followOptionsObject[socialAction.key].placeholder : null,"data-vv-name":_vm.followOptionsObject[socialAction.key] ? (accountIndex + "-" + (_vm.followOptionsObject[socialAction.key].vValidateRule) + "-" + socialActionIdx) : null,"data-vv-as":("" + (_vm.followOptionsObject[socialAction.key] ? _vm.followOptionsObject[socialAction.key].placeholder : '')),"data-test-id":(accountIndex + "-input-" + socialActionIdx + "-" + (socialAction.key))},on:{"input":function (val) { return _vm.handleSocialActionInput(val, accountIndex, socialActionIdx); },"focus":function($event){_vm.currentFocusFollowOption = "socialInput-" + accountIndex + "-" + socialActionIdx},"enter":function($event){_vm.currentFocusFollowOption = null},"blur":_vm.fieldBlurAction}}),_vm._v(" "),_c('am2-icon-button-dropdown',{attrs:{"icon-props":{
                    name: 'menu',
                    height: '16px',
                  },"items":_vm.dropdownActions,"dropdown-style":{
                    width: '200px',
                  },"data-test-id":("action-" + accountIndex + "-setting-menu-" + socialActionIdx)},on:{"select":function (item) { return _vm.handleSettingOptionSelect(item, accountIndex, socialActionIdx); }}})],1),_vm._v(" "),_c('ar-state-message',{directives:[{name:"show",rawName:"v-show",value:(_vm.followOptionsObject[socialAction.key] && _vm.veeErrors.has(("account-" + accountIndex + "." + accountIndex + "-" + (_vm.followOptionsObject[socialAction.key].vValidateRule) + "-" + socialActionIdx))),expression:"followOptionsObject[socialAction.key] && veeErrors.has(`account-${accountIndex}.${accountIndex}-${followOptionsObject[socialAction.key].vValidateRule}-${socialActionIdx}`)"}],style:({ marginLeft: '8px' }),attrs:{"text":_vm.followOptionsObject[socialAction.key] && _vm.veeErrors.first(("account-" + accountIndex + "." + accountIndex + "-" + (_vm.followOptionsObject[socialAction.key].vValidateRule) + "-" + socialActionIdx)),"type":"error"}})],1)])}),0)],1),_vm._v(" "),_c('am2-simple-button-dropdown',{staticClass:"dropdown-button",style:({
          'width': '100%',
          'marginTop': '20px',
        }),attrs:{"items":_vm.followOptions,"button-props":{
          text: 'Add action',
          type: 'purple',
          style: { height: '40px', width: '100%' },
          iconName: 'circle-plus',
          iconDistance: '9px',
        },"dropdown-max-height":'40vh',"dropdown-style":{
          width: '250px',
        },"align":"center","data-test-id":("add-action-button-" + accountIndex)},on:{"select":function (event) { return _vm.handleAddActionClick(event, accountIndex); }}})],1)}),1):_vm._e(),_vm._v(" "),_c('ar-link-button',{staticClass:"u-margin-top-8 u-margin-left-8",attrs:{"text":"+ Add another brand","data-test-id":"add-new-account-button"},on:{"click":_vm.handleAddBrandClick}}),_vm._v(" "),_c('am2-heading',{style:({ marginBottom: '12px', marginTop: '50px' }),attrs:{"type":"h3","size":"md","title":"Connect"}}),_vm._v(" "),_c('p',{staticClass:"large"},[_vm._v("\n    Fans earn points for connecting their social accounts. Select the social accounts fans can connect and you'll collect data including age, gender, location, number of social media followers and top artists/tracks.\n  ")]),_vm._v(" "),_c('am2-field',{class:[
      'social-connect-channels',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-3' ],attrs:{"name":"social-connect-channels"}},_vm._l((_vm.connectChannels),function(connect,index){return _c('div',{key:connect.key},[_c('ar-checkbox',{style:({ width: '100%', height: '52px' }),attrs:{"icon-name":connect.iconName,"icon-props":{ color: connect.iconColor },"label":connect.name,"align":"right"},model:{value:(connect.selected),callback:function ($$v) {_vm.$set(connect, "selected", $$v)},expression:"connect.selected"}}),_vm._v(" "),(index !== _vm.connectChannels.length - 1)?_c('ar-divider'):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }