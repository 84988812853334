<template>
  <EditableFrame
    class="send-email-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
  >
    <am2-email-editor-modal
      :opened="displayEmailEditorModal"
      template-type="unlayer"
      :default-template="emailTemplate"
      @update="handleEmailEdittingUpdate"
      @cancel="handleEmailEdittingCancel"
      @select="handleSelectEmailTemplateSelect"
    />
    <am2-email-preview-modal
      :opened="displayEmailPreviewModal"
      has-mode-tabs
      :subject="subject"
      :preview-text="previewText"
      :sender-name="senderName"
      :business-address="businessAddress"
      :email-html="emailHtmlWithInfo"
      @close="handleEmailPreviewClose"
    />
    <!-- Subject -->
    <ar-field
      :error-messages="[
        veeErrors.first('messageSender'),
      ]"
      data-test-id="automation-email-sender-field"
    >
      <div class="sender-section">
        <ar-text
          class="label"
          size="13px"
          text="SENDER"
          weight="bold"
          multiple-lines
          :max-lines="1"
          letter-spacing="1px"
        />
        <am2-message-sender-select
          class="sender-select"
          :value="messageSenderOid"
          v-validate="'required'"
          data-vv-name="messageSender"
          data-vv-as="message sender"
          @select="handleMessageSenderSelect"
        />
        <ar-snackbar
          v-if="messageSenderNotVerified"
          type="general"
          message="Please verify this email address. <a>Resend verification email</a>."
          @anchorClick="retryEmailVerification"
          :style="{
            marginTop: '10px',
            width: '100%',
          }"
        />
      </div>
    </ar-field>
    <!-- Subject -->
    <ar-field
      :error-messages="[
        veeErrors.first('subject'),
      ]"
      data-test-id="automation-email-subject-field"
    >
      <div class="subject-section">
        <div class="label-section">
          <ar-text
            class="label"
            size="13px"
            text="SUBJECT"
            weight="bold"
            multiple-lines
            :max-lines="1"
            letter-spacing="1px"
          />
          <ar-link-button
            v-if="!displayPreviewLine"
            text="Add preview line"
            @click="handleAddPreviewLineClick"
          />
        </div>
        <ar-input
          class="input"
          v-model="subject"
          v-validate="'required'"
          data-vv-name="subject"
          data-vv-as="subject"
          placeholder="Email subject"
          data-test-id="automation-email-subject-input-field"
        />
      </div>
    </ar-field>
    <!-- Preview Line -->
    <div
      v-if="displayPreviewLine"
      class="preview-line-section"
    >
      <div class="label-section">
        <ar-text
          class="label"
          size="13px"
          text="PREVIEW LINE"
          weight="bold"
          multiple-lines
          :max-lines="1"
          letter-spacing="1px"
        />
        <ar-link-button
          text="Remove preview line"
          @click="handleRemovePreviewLineClick"
          data-test-id="automation-email-remove-preview-link"
        />
      </div>
      <ar-input
        class="input"
        v-model="previewText"
        placeholder="Email preview line"
        data-test-id="automation-email-preview-input"
      />
    </div>
    <!-- Template -->
    <ar-field
      :error-messages="[
        veeErrors.first('emailTemplate'),
      ]"
      data-test-id="automation-email-design-field"
    >
      <div
        v-validate:emailHtmlBucketFieldsIsComplete="'required:true'"
        data-vv-name="emailTemplate"
        data-vv-as="email design"
      />
      <div
        class="design-section"
      >
        <div class="label-section">
          <ar-text
            class="label"
            size="13px"
            text="DESIGN"
            weight="bold"
            multiple-lines
            :max-lines="1"
            letter-spacing="1px"
          />
        </div>
        <template>
          <ar-skeleton
            v-if="isGeneratingEmailHtmlFromTemplate"
            class="html-preview"
            :style="{
              borderRadius: '3px',
            }"
          />
          <am2-html-preview
            v-else-if="emailHtml"
            class="html-preview"
            :html="emailHtmlWithInfo"
            enable-scroll
          />
        </template>
        <div class="action-buttons-section">
          <ar-simple-button
            class="launch-email-button"
            text="Launch email builder"
            outlined
            @click="handleLaunchEmailBuilderClick"
            data-test-id="automation-email-launch-builder-button"
          />
          <ar-simple-button
            v-if="emailHtml"
            class="review-email-button"
            icon-name="preview"
            text="Preview"
            type="grey"
            @click="handlePreviewEmailClick"
            data-test-id="automation-email-preview-button"
          />
        </div>
      </div>
    </ar-field>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SendEmail',
  components: {
    EditableFrame,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      emailTemplate: null,
      emailTemplateType: null,
      emailHtml: null,
      subject: null,
      previewText: null,
      messageSender: null,
      isGeneratingEmailHtmlFromTemplate: false,
      displayPreviewLine: false, 
      displayEmailEditorModal: false,
      displayEmailPreviewModal: false,
    }
  },
  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),
    savedButtonDisabled() {
      return !(this.messageSender && this.subject && this.emailHtml)
    },
    senderName() {
      return this.messageSender?.additionalInfo.senderName || null;
    },
    businessAddress() {
      return this.messageSender?.additionalInfo.businessAddress || null;
    },
    messageSenderNotVerified() {
      if (!this.messageSender) {
        return false;
      }
      return !this.messageSender.verified;
    },
    messageSenderOid() {
      return this.messageSender?.oid;
    },
    saveButtonDisabled() {
      return this.messageSenderNotVerified;
    },
    emailHtmlBucketFieldsIsComplete() {
      return !!this.emailTemplate
        && !!this.emailTemplateType
        && !!this.emailHtml;
    },
    emailHtmlWithInfo() {
      if (!this.emailHtml) {
        return null;
      }
      return this.generateEmailHtmlWithInfo(
        this.emailTemplateType,
        this.emailHtml,
        this.previewText,
        this.senderName,
        this.businessAddress,
      );
    },
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    }
  },
  methods: {
    ...mapActions({
      updateActionConfig: 'automation/UPDATE_ACTION_CONFIG',
      resendEmailVerification: 'messageSender/RESEND_EMAIL_VERIFICATION',
    }),
    generateEmailHtmlWithInfo(templateType, emailHtml, previewText, senderName, businessAddress) {
      if (templateType === 'unlayer') {
        return this.$arUtils.email.injectInformationToUnlayerHtml(
          emailHtml,
          previewText,
          senderName,
          businessAddress,
          true,
        );
      } else {
        return this.$arUtils.email.injectInformationToRichTextHtml(
          emailHtml,
          previewText,
          senderName,
          businessAddress,
          true,
        );
      }
    },
    async generateEmailHtmlFromTempalte(templateType, template) {
      try {
        this.isGeneratingEmailHtmlFromTemplate = true;
        if (templateType === 'unlayer') {
          const unlayerOutput = await this.$api.unlayer.exportHtml(template);
          this.emailHtml = unlayerOutput.html;
        } else if (templateType === 'rich-text') {
          this.emailHtml = template;
        }
      } catch (e) {
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to download email html',
        });
      } finally {
        this.isGeneratingEmailHtmlFromTemplate = false;
      }
    },
    async createEmailHtmlBucket(template, templateType, emailHtmlInfo) {
      return await this.$api.buckets.createLocalBucket(
        this.promoterOid,
        'email-html',
        'text/html',
        {
          template,
          templateType,
          emailHtml: emailHtmlInfo,
        },
      );
    },
    async handleSave() {
      const valid = await this.validateInputs();
      if (!valid) {
        return;
      }

      const newBucket = await this.createEmailHtmlBucket(
        this.emailTemplate,
        this.emailTemplateType,
        this.emailHtmlWithInfo,
      );
      const bucketOid = newBucket.oid;

      const contextData = {
        config: {
          subject: this.subject,
          previewText: this.previewText,
          promoterPropertyOid: this.messageSender.oid,
          bucketOid,
        },
        meta: {
          messageSender: this.messageSender,
          bucket: newBucket,
        },
      };

      this.updateActionConfig({
        id: this.item.id,
        data: contextData,
      });
      this.$emit('close');
    },
    async validateInputs() {
      const isValid = await this.$validator.validateAll();
      return isValid;
    },
    setItemVals() {
      const {
        config,
        meta,
      } = this.item;

      if (config?.['promoter-property-oid']) {
        this.messageSender = meta.messageSender;
      }
      if (config?.['bucket-oid']) {
        const { template, templateType } = meta.bucket.meta;
        this.emailTemplate = template;
        this.emailTemplateType = templateType;
        this.generateEmailHtmlFromTempalte(templateType, template);
      }
      this.subject = config?.subject || null;
      this.previewText = config?.['preview-text'] || null;

      if (this.previewText) {
        this.displayPreviewLine = true;
      }
    },
    retryEmailVerification() {
      this.resendEmailVerification(this.messageSender.oid);
    },
    handleMessageSenderSelect(messageSender) {
      this.messageSender = messageSender;
    },
    handleAddPreviewLineClick() {
      this.displayPreviewLine = true;
    },
    handleRemovePreviewLineClick() {
      this.displayPreviewLine = false;
    },
    handleLaunchEmailBuilderClick() {
      this.displayEmailEditorModal = true;
    },
    handleEmailEdittingUpdate(template, emailHtml) {
      this.displayEmailEditorModal = false;

      this.emailTemplate = template;
      this.emailTemplateType = 'unlayer';
      this.emailHtml = emailHtml;
    },
    handleEmailEdittingCancel() {
      this.displayEmailEditorModal = false;
    },
    handlePreviewEmailClick() {
      this.displayEmailPreviewModal = true;
    },
    handleEmailPreviewClose() {
      this.displayEmailPreviewModal = false;
    },
    handleSelectEmailTemplateSelect(template, templateType, emailHtml) {
      this.emailTemplate = template;
      this.emailTemplateType = templateType;
      this.emailHtml = emailHtml;
    },
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.send-email-wrapper {
  width: 100%;
  height: 100%;
  .sender-section {
    .sender-select {
      margin-top: 14px;
      width: 100%;
    }
  }
  .subject-section {
    margin-top: 31px;
    .label-section {
      display: flex;
      align-items: center;
      .label {
        flex-grow: 1;
      }
    }
    .input {
      margin-top: 14px;
    }
  }
  .preview-line-section {
    margin-top: 31px;
    .label-section {
      display: flex;
      align-items: center;
      .label {
        flex-grow: 1;
      }
    }
    .input {
      margin-top: 14px;
    }
  }
  .design-section {
    margin-top: 31px;
    .label-section {
      display: flex;
      align-items: center;
      .label {
        flex-grow: 1;
      }
    }
    .html-preview {
      margin-top: 14px;
      height: 200px;
    }
    .action-buttons-section {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-top: 20px;
      .review-email-button {
        width: 100%;
        height: 40px;
      }
      .launch-email-button {
        margin-bottom: 10px;
        width: 100%;
        height: 40px;
      }
    }
  }
}
</style>