<template>
  <portal
    to="modal"
    :disabled="!isOpen"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isOpen"
      class="add-fan-to-message-list-modal"
      :header="title"
      :has-back-link="step === 2"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @back="handleBackClick"
      @close="handleCloseClick"
    >
      <!-- Select list -->
      <div v-if="step === 1"
        slot="body"
        :class="[
          'add-fan-to-message-list-modal-body',
          $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <div :style="{ marginBottom: '100px' }">
          <label class="label">Select a list to add contacts to</label>
          <ar-simple-select
            placeholder="Please select..."
            enable-filter
            :items="messageLists"
            :default-select-index="selectedMessageListIndex"
            @select="handleMessageListSelect"
          />
        </div>
      </div>

      <!-- Select which channels to subscribe fans to -->
      <div v-else
        slot="body"
        :class="[
          'add-fan-to-message-list-modal-body',
          $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <div class="choose-channels-container">
          <ar-text
            size="xs"
            multiple-lines
            text="Select the messaging channels for these contacts."
            :style="{ alignSelf: 'baseline', marginBottom: '27px' }"
          />

          <!-- v-for="(settings, channel) in newSubscriptionSettings" -->
          <SubscribeBlock
            v-for="(channel) in availableChannels"
            class="subscription-block"
            :block-size="$arMediaQuery.pageContent.maxWidth('xs') ? 'xs' : 'regular'"
            :key="channel"
            :channel="channel"
            :loading="isFetchingAudienceOptInsStats"
            :total-opt-ins="audienceOptInsStats[channel].optIns"
            :total-available="audienceOptInsStats[channel].totalAvailable"
            :total-count="totalSelectedAudienceCount"
            :enable="newSubscriptionSettings[channel].enable"
            :enable-all="newSubscriptionSettings[channel].enableAll"
            :identifier="channelIdentifier(channel)"
            @change="(val) => handleSubscriptionChange(channel, val)"
            @optInAll="(val) => handleOptInAll(channel, val)"
          />
        </div>
      </div>
      <div slot="footer" class="add-fan-to-message-list-modal-footer">
        <ar-simple-button
          :text="step === 1 ? 'Next' : 'Add contacts'"
          :loading="isFetchingAudienceOptInsStats"
          :disabled="isButtonDisabled"
          @click="handleNextClick"
          style="padding: 18px 42px;"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import accounting from 'accounting';
import SubscribeBlock from './SubscribeBlock';
import { formatInteger } from '@/utils/helpers'

export default {
  name: 'AddFanToMessageListModal',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    searchString: {
      type: String,
      default: null,
    },
  },

  components: {
    SubscribeBlock
  },

  data() {
    const enabledChannels = [];

    if (process.env.arEnableEmailSending) {
      enabledChannels.push('email');
    }

    enabledChannels.push('sms');

    if (process.env.arEnableFbMessenger) {
      enabledChannels.push('facebookMessenger');
    }

    return {
      step: 1,
      enabledChannels,
      selectedMessageListOid: null,
      selectedMessageListIndex: null,
      selectedMessageList: null,
      subscriptions: {},
      newSubscriptionSettings: {},
      atLeastOneChannelSelected: false,
    }
  },

  computed: {
    ...mapState({
      audienceSelection: state => state.audience.audienceSelection,
      messageLists: state => state.messageList.messageLists,
      audienceOptInsStats: state => state.audience.audienceOptInsStats,
      isFetchingAudienceOptInsStats: state => state.audience.isFetchingAudienceOptInsStats,
    }),
    ...mapGetters({
      prunedScratchSegment: 'segment/prunedScratchSegment',
      totalSelectedAudienceCount: 'audience/totalSelectedAudienceCount',
      partlySelectedAudience: 'audience/partlySelectedAudience',
    }),
    appliedFilter() {
      return this.audienceSelection.useScratchSegment ? this.prunedScratchSegment.filter : { conditions: [], logic: [] }
    },
    availableChannels() {
      return Object.keys(this.newSubscriptionSettings).sort(
        // the fn for sorting returns 1 if the first arg should
        // come after the second arg and -1 if the reverse is true
        (channel1, channel2) =>
          this.enabledChannels.indexOf(channel1) >
          this.enabledChannels.indexOf(channel2) ? 1 : -1
      );
    },
    title() {
      const multipleFans = this.totalSelectedAudienceCount > 1;
      return `Add ${formatInteger(this.totalSelectedAudienceCount)} contact${multipleFans ? 's' : ''} to list`;
    },
    isButtonDisabled() {
      if (this.step === 1) {
        return this.selectedMessageListOid === null;
      } else {
        return !this.atLeastOneChannelSelected;
      }
    }
  },

  watch: {
    isOpen(val) {
      if (val) {
        this.atLeastOneChannelSelected = false;
        this.step = 1;
        this.selectedMessageListOid = null;
        this.selectedMessageList = null;
        this.selectedMessageListIndex = null;
        this['messageList/RESET_MESSAGE_LISTS']();
        this['messageList/FETCH_MORE_MESSAGE_LISTS']({
          top: 'all',
          type: 'manual',
          reload: true,
        });
      }
    },
  },

  methods: {
    ...mapMutations([
      'audience/GENERATE_AUDIENCE_OPT_INS_STATS',
      'messageList/RESET_MESSAGE_LISTS',
    ]),
    ...mapActions([
      'messageList/FETCH_MORE_MESSAGE_LISTS',
      'MASS_EDIT_FAN',
      'audience/FETCH_AUDIENCE_OPT_IN_STATISTICS',
    ]),
    formatNumber(number) {
      return accounting.formatNumber(number);
    },
    handleMessageListSelect(item, idx) {
      this.selectedMessageList = item;
      this.selectedMessageListOid = item.oid;
      this.selectedMessageListIndex = idx;
    },
    async handleSaveClick() {
      try {
        const response = await this.MASS_EDIT_FAN({
          fanOids: this.partlySelectedAudience.length > 0 ? this.partlySelectedAudience.map(fan => fan.oid) : null,
          filter: this.appliedFilter,
          searchString: this.searchString,
          messageList: {
            oid: this.selectedMessageListOid,
            subscriptions: this.newSubscriptionSettings,
          },
        });

        this.$arNotification.push({
          type: 'success',
          message: 'Successfully added valid contacts to list'
        });

        this.$emit('add');
      } catch (e) {
        this.$arNotification.push({ type: 'error', message: '' });
      }
    },
    async fetchCurrentOptIns() {
      this.newSubscriptionSettings = {};

      // Step 1: fill in the subscriptionSettings object with available keys
      ['facebookMessenger','sms', 'email'].forEach(channel => {
        const featureEnabled = this.enabledChannels.some(e => e === channel);
        if (this.selectedMessageList[channel] && featureEnabled){
          this.newSubscriptionSettings[channel] = {
            enable: false,
            enableAll: true,
          }
        }
      });

      if (this.audienceSelection.useScratchSegment) {
        this['audience/FETCH_AUDIENCE_OPT_IN_STATISTICS']({
          messageList: this.selectedMessageList,
          filter: this.appliedFilter,
          searchString: this.searchString,
        });
      } else {
        this['audience/GENERATE_AUDIENCE_OPT_INS_STATS']({
          audience: this.partlySelectedAudience,
          messageList: this.selectedMessageList,
        });
      }
    },
    handleSubscriptionChange(key, val) {
      this.$set(this.newSubscriptionSettings[key], 'enable', val );
      this.$forceUpdate(); // TODO - should remove this when a better solution presents
      // Computed props don't seem to reliably react to deep changes within the newSubscriptionSettings object
      this.atLeastOneChannelSelected =
        this.newSubscriptionSettings &&
        (!!this.newSubscriptionSettings?.email?.enable || !!this.newSubscriptionSettings?.sms?.enable);
    },
    handleOptInAll(key, val) {
      this.$set(this.newSubscriptionSettings[key], 'enableAll', val );
      this.$forceUpdate(); // TODO - should remove this when a better solution presents
    },
    channelIdentifier(channel) {
      switch (channel) {
        case 'facebookMessenger':
          return this.selectedMessageList.meta[channel].pageName;
          break;
        case 'email':
          return this.selectedMessageList.meta[channel].emailAddress;
          break;
        default:
          return null;
          break;
      }
    },
    handleNextClick() {
      if (this.step === 1) {
        this.step += 1;
        this.fetchCurrentOptIns();
      } else {
        this.handleSaveClick();
      }
    },
    handleBackClick() {
      this.step = 1;
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
  },
}
</script>

<style lang="scss" scoped>
.add-fan-to-message-list-modal {
  .add-fan-to-message-list-modal-body {
    padding: 27px 30px 30px;
    .label {
      display: block;
      font-size: 14px;
      color: $blueGrey800;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 6px;
      font-weight: bold;
    }

    .choose-channels-container {
      min-height: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .subscription-block {
        margin-bottom: 20px;
      }
    }
  }
  .add-fan-to-message-list-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
