var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"signup-form-modify-basic"},[_c('ar-text',{style:({
      color: '#9FA8B5',
    }),attrs:{"size":"xxs","text":"STEP 1","weight":"bold"}}),_vm._v(" "),_c('am2-heading',{staticClass:"u-margin-top-2",attrs:{"type":"h1","size":"lg","title":_vm.isEditSignupForm ? "Edit Signup Form" : "Setup your signup form","multiple-lines":""}}),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-13",attrs:{"label":"Heading","error-messages":[_vm.veeErrors.first('headline')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"value":_vm.scratchSignupForm.name,"autocomplete":"campaign-headline","data-vv-name":"headline","data-vv-as":"Heading"},on:{"input":_vm.handleTitleInput}})],1),_vm._v(" "),(_vm.showDescription)?_c('ar-field',{staticClass:"u-margin-top-12",attrs:{"label":"Description (optional)"}},[_c('ar-textarea',{attrs:{"value":_vm.scratchSignupForm.presentation.description,"rows":5},on:{"input":_vm.handleDescriptionInput}})],1):_vm._e(),_vm._v(" "),(!_vm.showDescription)?_c('ar-link-button',{staticClass:"u-margin-top-3",attrs:{"text":"Add a description"},on:{"click":function($event){_vm.showDescription = true}}}):_vm._e(),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-12",attrs:{"label":"Signup Form URL","error-messages":[_vm.veeErrors.first('url-slug')]}},[_c('am2-new-slug-input',{directives:[{name:"validate",rawName:"v-validate",value:({
        required: true,
        arCampaignSlug: {
          currentSlug: this.currentUrlSlug,
          prefix: '/m/',
        },
      }),expression:"{\n        required: true,\n        arCampaignSlug: {\n          currentSlug: this.currentUrlSlug,\n          prefix: '/m/',\n        },\n      }"}],staticClass:"u-margin-top-4",attrs:{"data-vv-name":"url-slug","data-vv-as":"Signup Form URL","data-vv-validate-on":"input","size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium',"value":_vm.scratchSignupForm.urlSlug,"prefix":(_vm.c2ClientDomain + "/m/"),"enableCopy":_vm.isEditSignupForm},on:{"input":_vm.handleUrlSlugInput}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-12",attrs:{"label":"Subscribe to list","error-messages":[_vm.veeErrors.first('list')]}},[_c('am2-message-list-select',{directives:[{name:"validate",rawName:"v-validate",value:({
        required: true,
        'has-enabled-channels': {
          email: !!_vm.channelValueInScratchSignupForm('email'),
          sms: !!_vm.channelValueInScratchSignupForm('sms'),
          messenger: !!_vm.channelValueInScratchSignupForm('facebook:messenger'),
        }
      }),expression:"{\n        required: true,\n        'has-enabled-channels': {\n          email: !!channelValueInScratchSignupForm('email'),\n          sms: !!channelValueInScratchSignupForm('sms'),\n          messenger: !!channelValueInScratchSignupForm('facebook:messenger'),\n        }\n      }"}],attrs:{"value":_vm.scratchSignupForm.messageListOid,"data-vv-name":"list","data-vv-as":"list","channels":['sms', 'email', 'facebookMessenger']},on:{"select":_vm.handleMessageListSelect}}),_vm._v(" "),(_vm.messageListInScratchSignupForm)?_c('div',{class:[
        'channels-select-section',
        'u-margin-top-2' ]},[_c('ar-text',{attrs:{"size":"xs","text":"Select enabled channels for this list:","multiple-lines":""}}),_vm._v(" "),_c('div',{class:[
          'u-margin-top-3',
          'u-display-flex',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'u-flex-flow-column',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'u-align-items-flex-start' : 'u-align-items-center' ]},[_c('ar-checkbox',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: !_vm.messageListInScratchSignupForm.email ? 'Email is not enabled for this list. Enable in List Settings.' : null,
          }),expression:"{\n            content: !messageListInScratchSignupForm.email ? 'Email is not enabled for this list. Enable in List Settings.' : null,\n          }"}],attrs:{"value":_vm.channelValueInScratchSignupForm('email'),"ticked-value":"enable","disabled":!_vm.messageListInScratchSignupForm.email,"label":"Email","icon-name":"email","icon-props":{
            width: '18px',
            color: _vm.$arStyle.color.email,
          },"data-test-id":"signup-form-email"},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleChannelEnableInput.apply(void 0, [ 'email' ].concat( argsArray ))}}}),_vm._v(" "),_c('ar-checkbox',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: !_vm.messageListInScratchSignupForm.sms ? 'SMS is not enabled for this list. Enable in List Settings.' : null,
          }),expression:"{\n            content: !messageListInScratchSignupForm.sms ? 'SMS is not enabled for this list. Enable in List Settings.' : null,\n          }"}],class:[
            _vm.$arMediaQuery.pageContent.minWidth('md') && 'u-margin-left-12',
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'u-margin-top-2' ],attrs:{"value":_vm.channelValueInScratchSignupForm('sms'),"ticked-value":"enable","disabled":!_vm.messageListInScratchSignupForm.sms,"label":"SMS","icon-name":"sms","icon-props":{
            width: '18px',
            color: _vm.$arStyle.color.sms,
          },"data-test-id":"signup-form-sms"},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleChannelEnableInput.apply(void 0, [ 'sms' ].concat( argsArray ))}}}),_vm._v(" "),_c('ar-checkbox',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: !_vm.messageListInScratchSignupForm.facebookMessenger ? 'Facebook Messenger is not enabled for this list. Enable in List Settings.' : null,
          }),expression:"{\n            content: !messageListInScratchSignupForm.facebookMessenger ? 'Facebook Messenger is not enabled for this list. Enable in List Settings.' : null,\n          }"},{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: 'Facebook Messenger is deprecated and will soon be removed'
          }),expression:"{\n            content: 'Facebook Messenger is deprecated and will soon be removed'\n          }",modifiers:{"top":true}}],class:[
            _vm.$arMediaQuery.pageContent.minWidth('md') && 'u-margin-left-12',
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'u-margin-top-2' ],attrs:{"value":_vm.channelValueInScratchSignupForm('facebook:messenger'),"ticked-value":_vm.messageListInScratchSignupForm.facebookMessenger ? _vm.messageListInScratchSignupForm.meta.facebookMessenger.pageId : 'nope',"disabled":!_vm.messageListInScratchSignupForm.facebookMessenger,"label":"Facebook Messenger","icon-name":"messenger","icon-props":{
            width: '18px',
            color: _vm.$arStyle.color.messenger,
          },"data-test-id":"signup-form-facebook"},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleChannelEnableInput.apply(void 0, [ 'facebook:messenger' ].concat( argsArray ))}}})],1)],1):_vm._e()],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-12",attrs:{"label":"Custom color","description":"Customize the Signup Form with your brand colors","error-messages":[_vm.veeErrors.first('customColor')]}},[_c('am2-color-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"value":_vm.scratchSignupForm.presentation.color,"data-vv-name":"customColor","data-vv-as":"customColor"},on:{"input":_vm.handleCustomColorInput}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-12",attrs:{"label":"Image (optional)","description":"Add an image for your Signup Form (Recommended dimensions are 1200x628)","error-messages":[_vm.veeErrors.first('image'), _vm.veeErrors.first('singupFormImageUploading')]}},[_c('div',{style:({
        height: (_vm.signupFormImageUrl || _vm.isUploadingSignupFormImage) ? null : '0',
        overflow: 'hidden',
      })},[(_vm.isUploadingSignupFormImage)?_c('div',{directives:[{name:"validate",rawName:"v-validate:isUploadingSignupFormImage",value:('uploadingAssets'),expression:"'uploadingAssets'",arg:"isUploadingSignupFormImage"}],attrs:{"data-vv-name":"singupFormImageUploading","data-vv-as":"singup form image"}}):_vm._e(),_vm._v(" "),_c('am2-dropzone',{ref:"img-dropzone",attrs:{"data-vv-name":"image","data-vv-as":"image","file-type":['image/jpeg', 'image/jpg', 'image/png'],"file-type-alias":"image","placeholder-icon":{
          name: 'image',
        },"image-options":{
          dimensions: {
            width: 1200,
            height: 628
          }
        },"ratio":0.525,"feedback-type":"image","feedback":_vm.signupFormImageUrl,"enable-ar-s3-bucket-upload":"","ar-s3-bucket-upload-options-list":[{
          assetType: 'campaign-image',
          campaignOid: _vm.scratchSignupForm.oid,
          eventOid: null,
        }]},on:{"delete":_vm.handleImageDelete,"uploadStart":function($event){_vm.isUploadingSignupFormImage = true},"uploadEnd":function($event){_vm.isUploadingSignupFormImage = false},"upload":_vm.handleImageUpload}})],1),_vm._v(" "),(!_vm.signupFormImageUrl && !_vm.isUploadingSignupFormImage)?_c('div',{staticClass:"u-display-flex"},[_c('ar-icon',{staticClass:"u-margin-right-2",attrs:{"name":"image","height":"21px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v(" "),_c('ar-link-button',{attrs:{"text":"Upload image"},on:{"click":_vm.handleUploadImageClick}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }