var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"campaign-setup-event-page"},[_c('ar-text',{staticClass:"step-heading",style:({
      color: '#9FA8B5',
      marginBottom: '8px',
    }),attrs:{"size":"xs","text":"SETUP"}}),_vm._v(" "),_c('am2-heading',{style:({marginBottom: '38.5px'}),attrs:{"type":"h1","size":"lg","title":"Select or create an event"}}),_vm._v(" "),_c('am2-list-view',{attrs:{"max-options":10,"initial-selected":_vm.selected,"fetch-data-adapter":_vm.fetchAdapter,"on-selected":_vm.selectEvent,"search-settings":{
      placeholder: 'Search your events',
      button: {
        text: 'Create new event',
        click: _vm.goToNewEvent,
      }
    }},scopedSlots:_vm._u([{key:"template",fn:function(option){return _c('div',{staticClass:"info"},[_c('img',{staticClass:"image",attrs:{"height":"50","width":"70","src":option.imageSrc}}),_vm._v(" "),_c('div',{staticClass:"label"},[_c('p',{staticClass:"title"},[_vm._v("\n          "+_vm._s(option.name)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"subtitle"},[_vm._v("\n          "+_vm._s(option.subTitle)+"\n        ")])])])}}])}),_vm._v(" "),_c('am2-create-event-modal',{attrs:{"is-open":_vm.displayCreateEventModal,"type":_vm.query.type,"campaign-type":_vm.query.key},on:{"cancel":function($event){_vm.displayCreateEventModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }