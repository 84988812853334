<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick" />
    <am2-ask-dice-access-token-modal
      :is-show="showAskDiceAccessTokenModal"
      :loading="isCreatingDiceIntegration"
      @confirm="handleDiceAccessTokenConfirm"
      @cancel="showAskDiceAccessTokenModal = false"
    />

    <am2-opt-in-settings-modal
      :is-show="showOptinSettingsModal"
      @cancel="showOptinSettingsModal = false"
      providerName="DICE"
      :message-list-oid="currentMessageListOid"
      :tag-fans="tagFans"
    />
    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="DICE"
          description="Automatically sync your members from DICE"
          :icon-props="{
            name: 'dice',
            color: $arStyle.color.dice,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingDiceIntegration && integrationsSummary">
        <am2-integration-account-select
          v-if="!isFetchingDiceIntegration && diceIntegration && currentIntegration"
          :default-selected-integration-oid="currentIntegration.oid"
          :available-integrations="integrationsSummary"
          @select="handleIntegrationChange"
          @new-account="handleAddNewIntegration"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Open DICE',
              value: 'goToDiceSite',
            },
            {
              name: 'Reconnect Integration',
              value: 'reconnectIntegration',
            },
            {
              name: 'DICE Settings',
              value: 'openSettingsModal',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
          @select="handleSettingOptionSelect" />
      </div>
    </div>


    <section v-if="isAddingDiceIntegration || isFetchingDiceIntegration" class="integration-loading-container">
      <am2-loading-bubble />
    </section>
    <section v-else>
      <section v-if="integrationFailed" class="integration-failed-message">
        <ar-snackbar
          type="warning"
          message="Your DICE connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
          @anchorClick="handleReconnectIntegration"
          :style="{ width: '100%' }"
        />
      </section>

      <SyncBlockContainer
        v-else
        class="sync-block-container"
        :is-fetching-sync-task="isFetchingSyncTask"
        :sync-status="syncStatus"
        :sync-time="syncTime"
        :sync-copies="{
          'in-progress': 'Syncing data from DICE',
          'pending': 'The data sync is still pending',
          'completed': 'Your DICE account is currently connected',
          'cancelled': 'Your DICE account connection is cancelled',
          'failed': this.syncErrorMessage,
          'default': 'Syncing tasks not found',
        }"
        @startSync="handleStartSync"
        @stopSync="handleStopSync"
      />
    </section>

  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SyncBlockContainer from '../components/sync-block-container';

export default {
  name: 'DiceIntegration',
  layout: 'default',
  components: {
    SyncBlockContainer,
  },

  data: () => ({
    currentlySelectedIntegrationIndex: 0,
    syncStatusPoller: null,
    showAskDiceAccessTokenModal: false,
    showOptinSettingsModal: false,
    currentMessageListOid: null,
    tagFans: null,
  }),

  computed: {
    ...mapState({
      diceIntegration: state => state.diceIntegration.integration,
      isFetchingDiceIntegration: state => state.diceIntegration.isFetchingIntegration,
      isAddingDiceIntegration: state => state.diceIntegration.isAddingIntegration,
      isCreatingDiceIntegration: state => state.diceIntegration.isCreatingIntegration,
      isDeletingDiceIntegration: state => state.diceIntegration.isDeletingIntegration,
      isFetchingSyncTask: state => state.diceIntegration.isFetchingSyncTask,
      syncTask: state => state.diceIntegration.syncTask,
      syncStatus: state => state.diceIntegration.syncStatus,
    }),

    diceIntegrationEnabled() {
      return process.env.arEnableDiceIntegration;
    },

    syncErrorMessage() {
      return this.syncTask?.statusDetails?.lastError || this.syncTask?.statusDetails?.reason;
    },

    syncTime() {
      return this.syncTask?.sysMtime || null;
    },

    integrationsSummary() {
      if (!this.diceIntegration) return null;
      const summary = this.diceIntegration
        .filter(i => i.status !== 'failed')
        .map(i => ({
          oid: i.oid,
          name: i.integration?.account?.name,
          email: i.integration?.account?.name,
          company: i.oauthToken?.info?.company?.name,
          messageListOid: i.meta?.messageListOid,
          tagFans: i.meta?.tagFans,
        }));

      if (this.currentlySelectedIntegrationIndex != null) {
        this.currentMessageListOid = !!summary[this.currentlySelectedIntegrationIndex] ? summary[this.currentlySelectedIntegrationIndex].messageListOid : null
        this.tagFans = !!summary[this.currentlySelectedIntegrationIndex] ? summary[this.currentlySelectedIntegrationIndex].tagFans : null
      }
      return summary
    },

    currentIntegration() {
      if (this.currentlySelectedIntegrationIndex === null) return null;
      if (!this.diceIntegration || this.diceIntegration.length < 1 || !this.diceIntegration[this.currentlySelectedIntegrationIndex]) return null;
      return this.diceIntegration[this.currentlySelectedIntegrationIndex];
    },

    integrationFailed() {
      if (!this.currentIntegration) return false;
      return this.currentIntegration.status === 'failed';
    },
  },

  async mounted() {
    if (!this.diceIntegrationEnabled) {
      this.handleBackLinkClick();
    }
    await this.fetchIntegration();
    this.startFetchTasksPolling();
  },

  beforeDestroy() {
    this['diceIntegration/RESET_INTEGRATION']();

    if (this.syncStatusPoller) {
      clearInterval(this.syncStatusPoller);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'diceIntegration/FETCH_INTEGRATION',
      'diceIntegration/DELETE_INTEGRATION',
      'diceIntegration/SYNC_START',
      'diceIntegration/SYNC_STOP',
      'diceIntegration/FETCH_SYNC_TASK',
      'diceIntegration/CREATE_DICE_INTEGRATION',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),
    ...mapMutations([
      'diceIntegration/RESET_INTEGRATION',
    ]),
    startFetchTasksPolling() {
      if (this.currentIntegration?.oid && !this.isFetchingSyncTask) {
        this.fetchTasks();
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },
    async fetchIntegration() {
      await this['diceIntegration/FETCH_INTEGRATION']();
      if (this.diceIntegration.length === 0) {
        this.$router.push({
          path: '/settings/integrations',
        });
      }
    },

    async fetchTasks() {
      await this['diceIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);

      if (this.currentIntegration && this.hasExistingTask && this.syncStatus !== "in-progress") {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }
    },

    async handleDiceAccessTokenConfirm({accessToken, messageList, tagFans}) {
      const succeed = await this['diceIntegration/CREATE_DICE_INTEGRATION']({
        accessToken,
        messageList,
        tagFans,
      });
      if (succeed) {
        this.fetchIntegration();

        this.showAskDiceAccessTokenModal = false;
      }
    },


    hasExistingTask() {
      return this.syncTask !== null;
    },

    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },

    handleIntegrationChange(integration, index) {
      this.currentlySelectedIntegrationIndex = index;

      this.updateCurrentMessageListOid();
      this.fetchTasks();
    },

    updateCurrentMessageListOid() {
      if (this.currentlySelectedIntegrationIndex === null || !this.integrationsSummary) {
        return null;
      }

      this.currentMessageListOid = !!this.integrationsSummary[this.currentlySelectedIntegrationIndex] ? this.integrationsSummary[this.currentlySelectedIntegrationIndex].messageListOid : null
    },

    async handleAddNewIntegration() {
      this.showAskDiceAccessTokenModal = true;
    },

    async handleStartSync() {
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
      await this['diceIntegration/SYNC_START']({ oid: this.currentIntegration.oid });
      if (this.syncStatus !== 'completed') {
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },

    handleStopSync() {
      this['diceIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
    },

    async handleReconnectIntegration() {
      const agreed = await this.SHOW_CONFIRM({
        messageHtml: `Make sure you are logging in with the DICE account <strong>${this.currentIntegration.integration.account.name}</strong> on DICE before reconnecting it.`,
        confirmButtonText: 'Reconnect account',
      });
      if (!agreed) {
        return;
      }

      const succeed = await this['diceIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
      if (!succeed) {
        return;
      }

      this.handleAddNewIntegration();
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the DICE integration will stop syncing your orders and customer data from DICE.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this['diceIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
            if (this.diceIntegration.length > 0) {
              this.currentlySelectedIntegrationIndex = 0;
              await this.fetchIntegration();
              this.startFetchTasksPolling();
            } else {
              this.$router.push({ path: '/settings/integrations' });
            }
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
          }
        }
      } else if (item.value === 'reconnectIntegration') {
        this.handleReconnectIntegration();
      } else if (item.value === 'goToDiceSite') {
        if (!this.currentIntegration) return;
        var win = window.open('https://dice.fm/', '_blank');
        win.focus();
      } else if (item.value == 'openSettingsModal') {
        if (!this.currentIntegration) return;

        this.showOptinSettingsModal = true;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  &.sm-max {
    padding: 0 24px;
  }
  &.xs-max {
    padding: 0 12px;
  }

  .integration-loading-container {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }


  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top:16px;
      }
    }
  }


  .sync-block-container {
    margin-top:64px;
  }
}
</style>
