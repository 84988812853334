<template>
  <section class="email-edit-page">
    <am2-put-email-template-name-modal
      :opened="displaySaveCurrentEmailTemplateModal"
      :loading="isCreatingEmailTemplate"
      @confirm="handleSaveCurrentEmailTemplateConfirm"
      @cancel="handleSaveCurrentEmailTemplateCancel"
    />
    <am2-put-email-template-name-modal
      :opened="displayCreateEmailTemplateModal"
      :loading="isCreatingEmailTemplate"
      @confirm="handleCreateEmailTemplateConfirm"
      @cancel="handleCreateEmailTemplateCancel"
    />
    <am2-select-email-template-modal
      :opened="displaySelectEmailTemplate"
      @select="handleSelectEmailTemplateSelect"
      @cancel="handleSelectEmailTemplateCancel"
    />
    <am2-send-test-email-modal
      :opened="displaySendTestEmailModal"
      @cancel="handleSendTestEmailCancel"
    />
      <!-- :message="scratchEmailMessage" -->
    <am2-email-preview-modal
      :opened="displayEmailPreviewModal"
      :has-mode-tabs="hasModeTabsOnEmailPreviewModal"
      :subject="scratchSubject"
      :preview-text="scratchPreviewText"
      :scheduled-at="scratchScheduledAt"
      :sender-name="currentSendername"
      :email-html="scratchEmailWithInfo"
      @close="handleEmailPreviewClose"
    />
    <div
      :class="[
        'header-section',
        displayRightSidePreview && 'not-visible',
      ]"
    >
      <am2-unlock-prompt
        v-if="displayUnlockPrompt"
        title="Unlock Email Marketing"
        subtitle="Upgrade to a paid plan now to start sending emails today!"
        @click="handleGoToPlansClick"
        :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      />
      <am2-top-create
        title="Send an email"
        :total-steps="totalStep"
        :current-step="currentStep"
        :exit-path="exitPath"
        :title-icon="{
          name: 'email',
          color: $arStyle.color.email,
          width: '18px',
        }"
        :style="{
          position: 'relative',
        }"
      >
        <div
          slot="content"
          class="top-create-content"
        >
          <div class="top-create-left-content">
            <am2-heading
              type="h1"
              size="sm"
              :title="stepTitle"
              weight="bold"
            />
            <ar-text
              size="xs"
              :text="`Step ${currentStep} of ${totalStep}`"
              :style="{
                marginTop: '11px',
                color: '#9FA8B5',
              }"
            />
          </div>
          <div class="top-create-right-content">
            <am2-link-button-dropdown
              v-if="step === 'template'"
              class="u-margin-right-9"
              placeholder="Template"
              :items="templateDropdownItems"
              :dropdown-style="{
                padding: '5px 0',
                width: '207px',
              }"
              :item-style="{
                height: '40px',
                padding: '0 20px',
              }"
              align="left"
              @select="handleTemplateItemSelect"
            />
            <am2-link-button-dropdown
              v-if="step === 'template'"
              placeholder="Preview"
              :items="previewDropdownItems"
              :dropdown-style="{
                padding: '5px 0',
                width: '207px',
              }"
              :item-style="{
                height: '40px',
                padding: '0 20px',
              }"
              align="left"
              @select="handlePreviewItemSelect"
            />
            <ar-link-button
              v-if="hasImportDynamicTagsLink && step === 'template'"
              text="Import dynamic tags"
              data-test-id="import-dynamic-tags-button"
              @click="handleImportDynamicTagsLinkClick"
            />
          </div>
        </div>
      </am2-top-create>
    </div>
    <div
      class="body-section"
    >
      <div
        :class="[
          'edit-message-section',
          (hasNoSpaceForRightSidePreview || fullScreenMode) && 'full-size',
        ]"
      >
        <div
          :class="[
            'edit-message-form-section',
            $arMediaQuery.pageContent.maxWidth('sm') && 'small-window-size',
            fullScreenMode && 'no-padding',
          ]"
        >
          <div
            :class="[
              'page-content',
              $arMediaQuery.pageContent.maxWidth('sm') && 'small-window-size',
              fullScreenMode && 'full-size',
            ]"
          >
            <nuxt-child
              ref="modify-email-page"
              :message-initialization-is-complete="messageInitializationIsCompleted"
              @saveDraftAndRedirectToSettings="handleSaveAndRedirectToSettings"
              @showImportDynamicTagsLink="hasImportDynamicTagsLink = true"
            />
          </div>
        </div>
        <div
          :class="[
            'edit-message-navigation-section',
            $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
          ]"
        >
          <ar-simple-button
            v-if="currentStep === 1"
            outlined
            text="Cancel"
            data-test-id="cancel-button"
            :style="{
              width: '100px',
            }"
            @click="handleCancelClick"
          />
          <ar-simple-button
            v-else-if="currentStep > 1"
            text="Back"
            data-test-id="back-button"
            outlined
            :style="{
              width: '100px',
            }"
            @click="handlePreviousStepClick"
          />
          <div class="navigation-right-section">
            <ar-text
              v-if="isAutoSaveEnabled"
              :text="autoSaveCopy"
              size="14px"
              class="u-margin-right-4"
              :style="{
                color: $arStyle.color.blueGrey700,
              }"
            />
            <ar-icon
              v-if="currentSelectedMessageScheduleCountdownCopy"
              class="u-margin-right-2"
              name="notification-warning"
              :color="$arStyle.color.purple500"
              v-tooltip="{
                content: currentSelectedMessageScheduleCountdownCopy,
              }"
            />
            <ar-link-button
              v-if="isNewEmailMessage"
              text="Save as draft"
              class="save-link"
              data-test-id="save-draft-button"
              :disabled="isSaveAsADraftButtonDisabled"
              @click="handleSaveAsDraftClick"
            />
            <ar-simple-button
              v-if="hasNoSpaceForRightSidePreview && !fullScreenMode"
              :text="rightSidePreviewIsToggledUp ? 'Edit' : 'Preview'"
              outlined
              data-test-id="mobile-preview-button"
              :icon-name="rightSidePreviewIsToggledUp ? null : 'mobile-preview'"
              :icon-props="{
                height: '26px',
                color: $arStyle.color.purple200,
              }"
              icon-distance="10px"
              @click="handlePreviewClick"
              :style="{
                marginRight: '12px',
                width: '120px',
              }"
            />
            <ar-simple-button
              v-if="currentStep < totalStep"
              text="Next"
              :style="{
                width: '120px',
              }"
              :disabled="isNextButtonDisabled"
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                action: handleNextStepClick,
                disabled: isNextButtonDisabled,
              }"
              @click="handleNextStepClick"
              data-test-id="next-step-button"
            />
            <ar-simple-button
              v-if="currentStep === totalStep && isEmailFeatureEnabled"
              :text="sendMessageButtonCopy"
              :style="{
                width: '120px',
              }"
              :loading="isSendingOutMessage || isUpdatingEmail"
              :disabled="sendButtonDisabled"
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                disabled: sendButtonDisabled,
                action: handleEmailSendClick,
              }"
              @click="handleEmailSendClick"
              data-test-id="send-email-button"
            />
            <ar-simple-button
              v-if="currentStep === totalStep && !isEmailFeatureEnabled"
              :text="isScheduledMessage ? 'Schedule' : 'Send now'"
              :style="{
                width: '120px',
              }"
              icon-name="lock"
              disabled
              outlined
              v-tooltip.top="{
                content: 'A <strong>Basic</strong> or <strong>Pro</strong> plan (with the Email Marketing Add-On) or a <strong>Plus</strong> plan is required before you can send emails.'
              }"
              @click="handleGoToPlansClick"
              data-test-id="send-email-button"
            />
          </div>
        </div>
      </div>
      <div
        :class="[
          'preview-section',
          (hasNoSpaceForRightSidePreview || fullScreenMode) && 'go-on-top-of-edit-section',
          $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
          displayRightSidePreview && 'is-visible',
        ]"
      >
        <div class="preview-wrapper">
          <div class="preview">
            <MessageMobileContainer
              :display-paginator="displayCsvPreviewPaginator"
              :contacts-count="csvPreviewContacts ? csvPreviewContacts.length : 0"
              :contact-index="selectedCsvPreviewContactIndex"
              @contactChange="handleCsvPreviewContactsIndexChange"
            >
              <am2-email-preview
                :opened="step !== 'basic'"
                :sender-name="currentSendername"
                :subject="scratchSubject"
                :preview-text="scratchPreviewText"
                :scheduled-at="scratchScheduledAt"
                :email-template="scratchEmailWithInfo"
                :preview-contact="selectedCsvPreviewContact"
              />
            </MessageMobileContainer>
          </div>
          <div
            v-if="currentStep !== 1"
            class="preview-bottom-link-section"
          >
            <ar-link-button
              v-if="csvPreviewContacts"
              :text="displayCsvPreviewContacts ? 'Hide live dynamic tags' : 'Preview dynamic tags'"
              @click="handleDisplayCsvPreviewContactsToggle"
              data-test-id="toogle-dynamic-tags-preview-link"
            />
            <ar-link-button
              v-else
              text="View desktop preview"
              data-test-id="view-desktop-preview-button"
              @click="handleViewDesktopPreviewClick"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import accounting from 'accounting';
import { debounce } from 'debounce';
import { capitalizeFirstLetter } from '@/utils/helpers';
import MessageMobileContainer from '@/components/containers/message-mobile-container';
import { recipientFilterToTargetingRules, recipientFilterToSidebar } from '@/store/modules/message/utils';

export default {
  name: 'EmailEdit',
  layout: 'edit',

  components: {
    MessageMobileContainer,
  },

  data() {
    return {
      unsavedMessage: null,
      displaySaveCurrentEmailTemplateModal: false,
      saveCurrentEmailTemplateSuccessCallBack: () => {},
      displayCreateEmailTemplateModal: false,
      displaySelectEmailTemplate: false,
      displaySendTestEmailModal: false,
      displayEmailPreviewModal: false,
      hasModeTabsOnEmailPreviewModal: false,
      previewDropdownItems: [
        {
          name: 'Send test email',
          key: 'send_test_email',
        },
        {
          name: 'Preview email',
          key: 'preview_email',
        },
      ],
      messageInitializationIsCompleted: false,
      totalStep: 3,
      steps: [
        'basic',
        'template',
        'schedule',
      ],
      stepTitleMap: {
        basic: 'Setup the basics',
        template: 'Create your email',
        schedule: 'Summary',
      },
      hasImportDynamicTagsLink: false,
      rightSidePreviewIsToggledUp: false,
      windowHeight: window.innerHeight,
      stopAutoSave: false,
      isDebouncingAutoSave: false,
    }
  },

  computed: {
    ...mapState({
      step: (state) => state.route.path.substring(state.route.path.lastIndexOf('/') + 1),
      messageOid: (state) => parseInt(state.route.params.oid, 10),
      query: state => state.route.query,
      currentMessageList: state => state.messageList.currentMessageList,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      scratchEmail: state => state.message.scratchEmail,
      isSendingOutMessage: state => state.message.isSendingOutMessage,
      isUpdatingEmail: state => state.message.isUpdatingEmail,
      isSavingMessageAsDraft: state => state.message.isSavingMessageAsDraft,
      csvPreviewContacts: state => state.message.csvPreviewContacts,
      selectedCsvPreviewContactIndex: state => state.message.selectedCsvPreviewContactIndex,
      displayCsvPreviewContacts: state => state.message.displayCsvPreviewContacts,
      scratchEmailMessageUnsaved: state => state.message.scratchEmailMessageUnsaved,
      currentMessageSender: state => state.messageSender.currentMessageSender,
      selectedUnlayerTemplate: state => state.emailTemplate.selectedUnlayerTemplate,
      isCreatingEmailTemplate: state => state.emailTemplate.isCreatingEmailTemplate,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
      advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
    }),

    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      hasNonExistingTagsInEmailMessage: 'message/hasNonExistingTagsInEmailMessage',
      nonExistingDynamicTagsInEmailMessageCopy: 'message/nonExistingDynamicTagsInEmailMessageCopy',
      injectInfoToScratchEmail: 'message/injectInfoToScratchEmail',
      scratchEmailMessageCampaignOid: 'message/scratchEmailMessageCampaignOid',
      scratchEmailMessageEventOid: 'message/scratchEmailMessageEventOid',
      currentSelectedMessageUiStatus: 'message/currentSelectedMessageUiStatus',
      currentSelectedMessageScheduleCountdownCopy: 'message/currentSelectedMessageScheduleCountdownCopy',
      selectedCsvPreviewContact: 'message/selectedCsvPreviewContact',
      currentMessageListHasNoEmailContacts: 'messageList/currentMessageListHasNoEmailContacts',
      currentMessageSenderNotVerified: 'messageSender/currentMessageSenderNotVerified',
      historicalCurrentPagePath: 'application/historicalCurrentPagePath',
    }),

    sanitizedReferringPage() {
      return this.historicalCurrentPagePath('/message-center/messages');
    },

    scratchSubject() {
      return this.scratchEmailMessage.meta?.messageBody?.subject || null;
    },

    scratchPreviewText() {
      return this.scratchEmailMessage.meta?.messageBody?.previewText || null;
    },

    scratchScheduledAt() {
      return this.scratchEmailMessage.scheduledAt || null;
    },

    currentSendername() {
      return this.currentMessageSender?.additionalInfo?.senderName || null;
    },

    displayCsvPreviewPaginator() {
      return this.csvPreviewContacts && this.displayCsvPreviewContacts;
    },

    templateDropdownItems() {
      const items = [];
      items.push({
        name: 'Use a template',
        key: 'select_template',
      });
      if (process.env.arEnableRichTextEmail) {
        if (this.scratchEmailMessage.meta.presentation.templateType !== 'rich-text') {
          items.push({
            name: 'Use text only',
            key: 'use_text_only',
          });
        }
        if (this.scratchEmailMessage.meta.presentation.templateType !== 'unlayer') {
          items.push({
            name: 'Use email editor',
            key: 'use_unlayer_editor',
          });
        }
      }
      items.push({
        name: 'Save as new template',
        key: 'save_template',
      });
      return items;
    },

    hasNoSpaceForRightSidePreview() {
      if (this.$arMediaQuery.window.maxWidth('md')) {
        return true;
      }
      if (this.displayUnlockPrompt) {
        return this.windowHeight < 700;
      } else {
        return this.windowHeight < 600;
      }
    },

    displayRightSidePreview() {
      return this.rightSidePreviewIsToggledUp && this.hasNoSpaceForRightSidePreview && !this.fullScreenMode;
    },
    isNewEmailMessage() {
      return !this.scratchEmailMessage.oid;
    },

    scratchEmailWithInfo() {
      return this.injectInfoToScratchEmail(this.currentMessageSender);
    },

    isScheduledMessage() {
      return !!this.scratchEmailMessage.scheduledAt || !!this.scratchEmailMessage.meta.presentation.timeZone;
    },

    sendMessageButtonCopy() {
      if (this.isScheduledMessage) {
        if (this.currentSelectedMessageUiStatus === 'scheduled') {
          return 'Update';
        } else {
          return 'Schedule';
        }
      } else {
        return 'Send Now';
      }
    },

    isEmailFeatureEnabled() {
      return this.isFeatureEnabled(['messages', 'email']);
    },

    displayUnlockPrompt() {
      return !this.isEmailFeatureEnabled;
    },

    currentStepIndex() {
     return this.steps.findIndex(step => step === this.step);
    },

    currentStep() {
      return this.currentStepIndex + 1;
    },

    isEditingTemplate() {
      return this.currentStep === 2;
    },

    stepTitle() {
      return this.stepTitleMap[this.step];
    },

    fullScreenMode() {
      return this.isEditingTemplate;
    },

    recipientsCount() {
      if (!this.currentMessageList) {
        return 0;
      }
      if (!this.currentMessageList.statsSnapshot) {
        return 0;
      }
      if (this.scratchEmailMessageCampaignOid || this.scratchEmailMessageEventOid) {
        return this.currentMessageList.statsSnapshot.total;
      }
      return this.currentMessageList.statsSnapshot.email ? this.currentMessageList.statsSnapshot.email : 0;
    },

    autoSaveCopy() {
      if (this.isSavingMessageAsDraft) {
        return 'Saving changes...';
      } else if (this.scratchEmailMessageUnsaved) {
        return 'Changes unsaved';
      } else {
        return 'All changes saved';
      }
    },

    isAutoSaveEnabled() {
      return this.currentSelectedMessageUiStatus === 'draft';
    },

    isSaveAsADraftButtonDisabled() {
      return this.isUpdatingEmail ||
        this.isSavingMessageAsDraft ||
        this.isSendingOutMessage;
    },

    isNextButtonDisabled() {
      return this.isEditingTemplate && !this.scratchEmail;
    },

    sendButtonDisabled() {
      if (this.isSavingMessageAsDraft) {
        return true;
      }
      if (this.currentMessageSenderNotVerified) {
        return true;
      }
      if (this.currentMessageListHasNoEmailContacts) {
        return true;
      }
      if (this.advancedMessageListTargeting?.type &&
          this.advancedMessageListTargeting?.type !== 'all' &&
          !this.filteredRecipientListCount) {
        return true;
      }
      return false;
    },
    exitPath() {
      // If it's a new message for campaign
      if (!!this.scratchEmailMessageCampaignOid
        && this.isNewMessage) {
        return `/campaigns/${this.scratchEmailMessageCampaignOid}/view/dashboard`;
      } else if (!!this.scratchEmailMessageEventOid
        && this.isNewMessage) {
        return `/events/${this.scratchEmailMessageEventOid}/view/sales`;
      } else {
        return this.sanitizedReferringPage;
      }
    },
  },

  watch: {
    scratchEmailMessage: {
      handler(val) {
        if (!this.messageInitializationIsCompleted) {
          return;
        }
        this['message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED'](true);
        if (this.isAutoSaveEnabled) {
          if (!this.isSavingMessageAsDraft && !this.isDebouncingAutoSave) {
            this.handleAutoSaveDebounce();
            this.handleAutoSaveDebounce.flush();
          } else {
            this.handleAutoSaveDebounce();
            this.isDebouncingAutoSave = true;
          }
        }
        sessionStorage.setItem('_ar_promoter_new_email_message_', JSON.stringify(val));
      },
      deep: true,
    },
    currentMessageList: {
      handler(newCurrentMessageList) {
        if (this.scratchEmailMessage?.meta?.email?.promoterPropertyOid) return;
        if (!newCurrentMessageList || !newCurrentMessageList.meta.email || !newCurrentMessageList.meta.email.promoterPropertyOid) return;

        this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({ oid: newCurrentMessageList.meta.email.promoterPropertyOid });
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            email: {
              promoterPropertyOid: newCurrentMessageList.meta.email.promoterPropertyOid,
            },
          },
        });
      },
      immediate: true,
    },
    scratchEmail: {
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this['message/RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_EMAIL_MESSAGE'](val);
        }
      },
      immediate: true,
    },
  },

  /**
   * If you need any data to be ready before we start rending the template,
   * please do it here.
   **/
  async asyncData({ store, route }) {
    store.commit('message/RESET_CURRENT_SELECTED_MESSAGE');
    store.commit('message/RESET_SCRATCH_EMAIL_TEMPLATE');
    store.commit('message/RESET_CSV_PREVIEW_CONTACTS');
    store.commit('messageList/RESET_CURRENT_MESSAGE_LIST');
    store.commit('messageSender/RESET_CURRENT_MESSAGE_SENDER');
    const unsavedMessage = sessionStorage.getItem('_ar_promoter_new_email_message_');

    if (route.params.oid) {
      await store.dispatch('message/FETCH_MESSAGE', parseInt(route.params.oid, 10));
    }

    return {
      unsavedMessage,
    };
  },

  async created() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleAutoSaveDebounce = debounce(this.handleAutoSave, 1000);
    
    this['segment/RESET_SCRATCH_SEGMENT']();

    this['message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED'](false);

    // Fetch dynamic tags
    await this['message/FETCH_ADDITIONAL_DYNAMIC_TAGS']();

    if (this.unsavedMessage) { // Scratch email message with unsaved changes
      this['message/SET_SCRATCH_EMAIL_MESSAGE'](JSON.parse(this.unsavedMessage));
    } else if (this.messageOid) { // Scratch email message from a saved email message
      this['message/SET_SCRATCH_EMAIL_MESSAGE'](this.currentSelectedMessage);
    } else { // New email message
      this['message/RESET_SCRATCH_EMAIL_MESSAGE']();
      // If you also have default selected Unlayer template id
      if (this.query.libraryTemplateId) {
        await this['emailTemplate/FETCH_SELECTED_UNLAYER_TEMPLATE'](this.query.libraryTemplateId);
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            presentation: {
              template: this.selectedUnlayerTemplate.design,
            },
          },
        });
      }
    }

    const savedMessageListOid = this.query && this.query.messageList ? this.query.messageList : null;
    const savedCampaignOid = this.query && this.query.campaignOid ? this.query.campaignOid : null;
    const savedEventOid = this.query && this.query.eventOid ? this.query.eventOid : null;

    if (!this.scratchEmailMessage.meta.messageListOid && savedMessageListOid && savedMessageListOid.length > 0) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageListOid: parseInt(savedMessageListOid),
        },
      });
    } else if (savedCampaignOid) { // The email message is from a camapign
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          initiator: {
            campaignOid: parseInt(savedCampaignOid),
          },
        },
      });
    } else if (savedEventOid) { // The email message is from an event
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          initiator: {
            eventOid: parseInt(savedEventOid),
          },
        },
      });
    }

    if (this.scratchEmailMessage.meta.presentation.template) {
      if (this.scratchEmailMessage.meta.presentation.templateType === 'unlayer') {
        this['message/GENERATE_SCRATCH_EMAIL_TEMPLATE'](this.scratchEmailMessage.meta.presentation.template);
      } else {
        this['message/SET_SCRATCH_EMAIL_TEMPLATE'](this.scratchEmailMessage.meta.presentation.template);
      }
    }

    if (!!this.currentSelectedMessage && !!this.currentSelectedMessage.meta) {
      await this['segment/FETCH_SEGMENTS']();
      const recipientFilterClear = recipientFilterToSidebar(this.currentSelectedMessage.meta.recipientFilter)
      this['segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT']({messageSegmentOid: this.messageOid, filter: recipientFilterClear});
    } else {
      this['segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT']({messageSegmentOid: null, filter: {conditions: [], logic: []}});
    }

    if (this.scratchEmailMessage.meta.messageListOid) {
      this.fetchMessageListAndRecipientCount();
    }
    if (this.scratchEmailMessageCampaignOid) {
      this['campaign/FETCH_CURRENT_CAMPAIGN'](this.scratchEmailMessageCampaignOid);
    }
    if (this.scratchEmailMessage?.meta?.email?.promoterPropertyOid) {
      this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({
        oid: this.scratchEmailMessage.meta.email.promoterPropertyOid
      });
    }
    // Download Dynamic Tags CSV and set contacts if exist
    if (this.scratchEmailMessage.meta.tagsResourceOid) {
      this['message/FETCH_CSV_PREVIEW_CONTACTS'](this.scratchEmailMessage.meta.tagsResourceOid);
    }

    this.$nextTick(this.handleEmailInitialzationComplete);
  },
  async beforeRouteLeave (to, from, next) {
    if (this.scratchEmailMessageUnsaved) {
      const response = await this.SHOW_CONFIRM({ messageHtml: 'Are you sure you want to leave?<br />Unsaved changes will be lost.' });
      if (response) {
        sessionStorage.removeItem('_ar_promoter_new_email_message_');
        next();
      } else {
        next(false);
        return;
      }
    } else {
      sessionStorage.removeItem('_ar_promoter_new_email_message_');
      next();
    }
  },

  beforeDestroy() {
    this.resetAllStates();
    this['message/RESET_ADDITIONAL_DYNAMIC_TAGS']();
    this['message/RESET_SCRATCH_EMAIL_MESSAGE']();
    this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
    window.removeEventListener('resize', this.handleWindowResize);
    this.handleAutoSaveDebounce.clear();
  },

  methods: {
    ...mapActions([
      'campaign/FETCH_CURRENT_CAMPAIGN',
      'message/FETCH_ADDITIONAL_DYNAMIC_TAGS',
      'message/FETCH_MESSAGE',
      'message/UPDATE_EMAIL_TEMPLATE_IN_SCRATCH_EMAIL_MESSAGE',
      'message/GENERATE_SCRATCH_EMAIL_TEMPLATE',
      'message/FETCH_CSV_PREVIEW_CONTACTS',
      'message/RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_EMAIL_MESSAGE',
      'emailTemplate/FETCH_SELECTED_UNLAYER_TEMPLATE',
      'emailTemplate/CREATE_EMAIL_TEMPLATE',
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'messageSender/FETCH_CURRENT_MESSAGE_SENDER',
      'SHOW_CONFIRM',
      'message/SAVE_MESSAGE_DRAFT',
      'message/SEND_OUT_MESSAGE',
      'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'segment/FETCH_SEGMENTS',
    ]),
    ...mapMutations([
      'campaign/RESET_CURRENT_CAMPAIGN',
      'messageList/RESET_CURRENT_MESSAGE_LIST',
      'messageSender/RESET_CURRENT_MESSAGE_SENDER',
      'message/RESET_ADDITIONAL_DYNAMIC_TAGS',
      'message/RESET_CURRENT_SELECTED_MESSAGE',
      'message/RESET_SCRATCH_EMAIL_MESSAGE',
      'message/SET_SCRATCH_EMAIL_MESSAGE',
      'message/SET_SCRATCH_EMAIL_TEMPLATE',
      'message/RESET_SCRATCH_EMAIL_TEMPLATE',
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      'message/RESET_CSV_PREVIEW_CONTACTS',
      'message/SET_SELECTED_CSV_PREVIEW_CONTACTS_INDEX',
      'message/TOGGLE_DISPLAY_CSV_PREVIEW_CONTACTS',
      'message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED',
      'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
      'messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING',
      'segment/RESET_SCRATCH_SEGMENT',
      'segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT',
    ]),
    resetAllStates() {
      this['message/RESET_CURRENT_SELECTED_MESSAGE']();
      this['campaign/RESET_CURRENT_CAMPAIGN']();
      this['messageList/RESET_CURRENT_MESSAGE_LIST']();
      this['messageSender/RESET_CURRENT_MESSAGE_SENDER']();
      this['message/RESET_SCRATCH_EMAIL_TEMPLATE']();
      this['message/RESET_CSV_PREVIEW_CONTACTS']();
      this['segment/RESET_SCRATCH_SEGMENT']();
    },
    async fetchMessageListAndRecipientCount() {
      await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({oid: this.scratchEmailMessage.meta.messageListOid});
      let newAdvancedMessageListTargeting = null;
      if (this.currentSelectedMessage?.meta?.recipientFilter) {
        newAdvancedMessageListTargeting = recipientFilterToTargetingRules(this.currentSelectedMessage.meta.recipientFilter);
      } else {
        newAdvancedMessageListTargeting = recipientFilterToTargetingRules(this.scratchEmailMessage?.meta?.recipientFilter || null);
      }
      this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
      if (!this.scratchEmailMessageCampaignOid) {
        this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({ channel: 'email' });
      }

    },
    getModifyEmailPageElem() {
      return this.$refs['modify-email-page'];
    },
    goToPreviousStep() {
      this.$router.push({
        path: `${this.steps[this.currentStepIndex - 1]}`,
        query: this.query,
      });
    },
    goToNextStep() {
      this.$router.push({
        path: `${this.steps[this.currentStepIndex + 1]}`,
        query: this.query,
      });
    },
    validateInputs() {
      return this.getModifyEmailPageElem().$validator.validateAll().then(valid => {
        if (valid) {
          return true;
        } else {
          if (this.isScheduledMessage) {
            this.$arNotification.push({ type: 'error', message: 'Could not schedule the email, please review error messages' });
          } else {
            this.$arNotification.push({ type: 'error', message: 'Could not send the email, please review error messages' });
          }
          return false;
        }
      });
    },
    async validateDynamicTags() {
      if (this.hasNonExistingTagsInEmailMessage) {
        await this.SHOW_CONFIRM({
          messageHtml: this.nonExistingDynamicTagsInEmailMessageCopy,
          confirmButtonText: 'Done',
          hideCancelButton: true,
        });
        return false;
      }
      return true;
    },
    hideOverviewPageFields() {
      if (this.getModifyEmailPageElem().hideFields) {
        this.getModifyEmailPageElem().hideFields();
      }
    },
    async sendMessage() {
      // Force completing auto save and set stopAutoSave to true
      this.handleAutoSaveDebounce.flush();
      this.stopAutoSave = true;

      this.hideOverviewPageFields();
      let actionWord = this.isScheduledMessage ? 'schedule' : 'send';
      let count = this.recipientsCount;
      if (this.currentSelectedMessage?.meta?.recipientFilter?.conditions?.length > 0 || (this.scratchEmailMessage?.meta?.recipientFilter?.conditions?.length > 0)) {
        count = this.filteredRecipientListCount;
      }

      const response = await this.SHOW_CONFIRM({
        title: 'Confirmation',
        confirmButtonText: `${capitalizeFirstLetter(actionWord)} Message`,
        messageHtml: `You are about to ${actionWord} a message to <strong>${accounting.formatNumber(count)}</strong> ${count > 1 ? 'people' : 'person'}. Do you want to continue?`
      });
      if (!response) {
        this.stopAutoSave = false;
        return
      };

      const emailTempalteUpdated = await this['message/UPDATE_EMAIL_TEMPLATE_IN_SCRATCH_EMAIL_MESSAGE'](this.scratchEmailWithInfo);
      if (!emailTempalteUpdated) {
        this.stopAutoSave = false;
        return;
      }

      const succeed = await this['message/SEND_OUT_MESSAGE']({
        ...this.scratchEmailMessage,
        provider: 'email',
      });

      if (succeed) {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        // It's saved, so it's backe to not updated
        this['message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED'](false);
        this.$router.push({ path: '/message-center/messages' });
      } else {
        this.stopAutoSave = false;
      }
    },
    async fetchCurrentMessage() {
      if (!this.messageOid) {
        return;
      }
      const succeed = await this['message/FETCH_MESSAGE'](this.messageOid);
      return succeed;
    },
    async saveMessageDraft() {
      this['message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED'](false);
      const succeed = await this['message/SAVE_MESSAGE_DRAFT']({
        ...this.scratchEmailMessage,
        provider: 'email',
      });

      if (!succeed) {
        this['message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED'](true);
      }

      return succeed;
    },
    async saveCurrentEmailAsTemplate(name, description) {
      const succeed = await this['emailTemplate/CREATE_EMAIL_TEMPLATE']({
        meta: {
          name,
          description,
          template: this.scratchEmailMessage.meta.presentation.template,
          templateType: this.scratchEmailMessage.meta.presentation.templateType,
        },
      });
      return succeed;
    },
    replaceEmailTemplate(template, templateType) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            template,
            templateType,
          },
        },
      });
      this.getModifyEmailPageElem().setEmailTemplate();
    },

    async handleAutoSave() {
      // Remember to set this flag to false
      this.isDebouncingAutoSave = false;
      if (!this.isAutoSaveEnabled || this.stopAutoSave) {
        return;
      }
      const succeed = await this.saveMessageDraft();
      if (!succeed) {
        return;
      }
      // Refresh the current message after it's updated
      this.fetchCurrentMessage();
    },
    async handleSaveAndRedirectToSettings() {
      this.hideOverviewPageFields();
      const succeed = await this.saveMessageDraft();
      if (succeed) {
        this.$router.push({ path: `/message-center/lists/${this.currentMessageList.oid}/settings` });
      }
    },
    async handleSaveAsDraftClick() {
      this.hideOverviewPageFields();
      const succeed = await this.saveMessageDraft();
      if (succeed) {
        this.$router.push({ path: '/message-center/messages' });
      }
    },
    handleCancelClick() {
      this.$router.push({
        path: this.exitPath,
      });
    },
    async handleSelectEmailTemplateSelect(template, templateType) {
      this.displaySelectEmailTemplate = false;

      const agreedToCreateNewTemplate = await this.SHOW_CONFIRM({
        messageHtml: 'Selecting a new template to use will overwrite your current email.<br><b>Do you want to save the current email so it\'s not lost?</b>',
        confirmButtonText: 'Save as a template',
        cancelButtonText: 'Just overwrite it',
      });
      if (agreedToCreateNewTemplate) {
        this.displaySaveCurrentEmailTemplateModal = true;
        this.saveCurrentEmailTemplateSuccessCallBack = () => {
          this.replaceEmailTemplate(template, templateType);
        };
      } else {
        this.replaceEmailTemplate(template, templateType);
      }
    },
    async handleSaveCurrentEmailTemplateConfirm({
      name,
      description,
    }) {
      const succeed = await this.saveCurrentEmailAsTemplate(name, description);
      if (succeed) {
        this.displaySaveCurrentEmailTemplateModal = false;
        this.saveCurrentEmailTemplateSuccessCallBack();
      }
    },
    handleSaveCurrentEmailTemplateCancel() {
      this.displaySaveCurrentEmailTemplateModal = false;
    },
    async handleCreateEmailTemplateConfirm({
      name,
      description
    }) {
      const succeed = await this.saveCurrentEmailAsTemplate(name, description);
      if (succeed) {
        this.displayCreateEmailTemplateModal = false;
      }
    },
    handleCreateEmailTemplateCancel() {
      this.displayCreateEmailTemplateModal = false;
    },
    handleSelectEmailTemplateCancel() {
      this.displaySelectEmailTemplate = false;
    },
    async handleSelectTemplateSelect() {
      this.displaySelectEmailTemplate = true;
    },
    handleTemplateItemSelect(templateItem) {
      if (templateItem.key === 'select_template') {
        this.handleSelectTemplateSelect();
      } else if (templateItem.key === 'use_text_only') {
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            presentation: {
              template: '',
              templateType: 'rich-text',
            },
          },
        });
        this.getModifyEmailPageElem().setEmailTemplate();
      } else if (templateItem.key === 'use_unlayer_editor') {
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            presentation: {
              template: null,
              templateType: 'unlayer',
            },
          },
        });
        this.getModifyEmailPageElem().setEmailTemplate();
      } else if (templateItem.key == 'save_template') {
        this.displayCreateEmailTemplateModal = true;
      }
    },
    handleSendTestEmailCancel() {
      this.displaySendTestEmailModal = false;
    },
    handlePreviewItemSelect(previewItem) {
      if (previewItem.key === 'send_test_email') {
        this.displaySendTestEmailModal = true;
      } else if (previewItem.key === 'preview_email') {
        this.displayEmailPreviewModal = true;
        this.hasModeTabsOnEmailPreviewModal = true;
      }
    },
    handleViewDesktopPreviewClick() {
      this.displayEmailPreviewModal = true;
      this.hasModeTabsOnEmailPreviewModal = false;
    },
    handleEmailPreviewClose() {
      this.displayEmailPreviewModal = false;
    },
    async handlePreviousStepClick() {
      this.goToPreviousStep();
    },
    async handleNextStepClick() {
      let valid = null;
      valid = await this.validateInputs();
      if (!valid) {
        return;
      }
      if (this.isEditingTemplate) {
        valid = await this.validateDynamicTags();
        if (!valid) {
          return;
        }
      }
      this.goToNextStep();
    },
    async handleEmailSendClick() {
      let valid = null;
      valid = await this.validateInputs();
      if (!valid) {
        return;
      }
      valid = await this.validateDynamicTags();
      if (!valid) {
        return;
      }
      this.sendMessage();
    },
    handleImportDynamicTagsLinkClick() {
      this.getModifyEmailPageElem().importDynamicTags();
    },

    async handleGoToPlansClick() {
      this.hideOverviewPageFields();
      const succeed = await this.saveMessageDraft();
      if (succeed) {
        this.$router.push({
          path: `/settings/subscriptions`,
        });
      }
    },

    handlePreviewClick() {
      this.rightSidePreviewIsToggledUp = !this.rightSidePreviewIsToggledUp;
    },
    handleWindowResize() {
      this.windowHeight = window.innerHeight;
    },

    handleEmailInitialzationComplete() {
      this.messageInitializationIsCompleted = true;
      const modifyEmailPageElem = this.getModifyEmailPageElem();
      if (!modifyEmailPageElem) {
        return;
      }
      if (modifyEmailPageElem.setEmailTemplate) {
        modifyEmailPageElem.setEmailTemplate();
      }
    },

    handleCsvPreviewContactsIndexChange(idx) {
      this['message/SET_SELECTED_CSV_PREVIEW_CONTACTS_INDEX'](idx);
    },

    handleDisplayCsvPreviewContactsToggle() {
      this['message/TOGGLE_DISPLAY_CSV_PREVIEW_CONTACTS']();
    },
  },
};
</script>

<style lang="scss" scoped>
.email-edit-page {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-flow: column;
  .header-section {
    position: relative;
    z-index: $zIndexRegular;
    flex-shrink: 0;
    min-width: 800px;
    &.not-visible {
      display: none;
    }

    .top-create-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .top-create-left-content {
        padding: 0 0 4px;
      }
      .top-create-right-content {
        display: flex;
        align-items: center;
        padding-right: 29px;
      }
    }
  }
  .body-section {
    flex: 1;
    min-width: 800px;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    .edit-message-section {
      flex: 1;
      position: relative;
      z-index: $zIndexRegular;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 700px;
      max-width: 760px;
      background: #fff;
      &.full-size {
        min-width: unset;
        max-width: 100%;
      }

      .edit-message-form-section {
        flex: 1;
        padding: 0 80px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        .page-content {
          max-width: 760px;
          margin: 50px auto;

          &.small-window-size {
            margin: 30px auto;
            &.full-size {
              margin: 0;
            }
          }

          &.full-size {
            height: 100%;
            max-width: 100%;
            margin: 0;
          }
        }
        &.small-window-size {
          padding: 0 12px;
        }
        &.no-padding {
          padding: 0;
        }
      }

      .edit-message-navigation-section {
        flex-shrink: 0;
        position: relative;
        width: 100%;
        z-index: $zIndexHighest;
        height: 110px;
        padding: 0 80px;
        border-top: 1px solid $skyBlueGrey500;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        &.small-window-size {
          height: 75px;
          padding: 0 12px;
        }

        .navigation-right-section {
          display: flex;
          align-items: center;
          .save-link {
            margin-right: 30px;
          }
        }
      }
    }
    .preview-section {
      flex-shrink: 0;
      padding: 45px 45px;
      z-index: $zIndexLow;
      overflow: hidden;

      &.is-visible {
        z-index: $zIndexHigh;
      }

      &.go-on-top-of-edit-section {
        padding: 12px;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 110px); // minus edit-message-edit-message-navigation-section-section height
        background-color: #FFF;

        &.small-window-size {
          height: calc(100% - 75px);
        }
      }

      .preview-wrapper {
        display: flex;
        flex-flow: column;
        align-items: center;
        height: 100%;
        max-height: 780px;
        .preview {
          flex: 1;
        }
        .preview-bottom-link-section {
          margin-top: 22px;
        }
      }
    }
  }
}
</style>
