<template>
  <section :class="`dialog-select ${classNames.join(' ')}`">
    <div class="triangle" :style="arrowAlignStyle">
      <div class="triangle-outer" />
      <div class="triangle-inner" />
    </div>
    <div v-if="tabs.length > 0" class="tabs-section">
      <am2-elegant-tabs
        :items="tabs"
        :tab-key="tabKey"
        @select="handleTabSelect"
      />
    </div>
    <div
      v-if="hasSearch"
      class="filter-section"
    >
      <am2-search
        ref="search"
        class="filter"
        :value="searchString"
        :placeholder="placeholder"
        :enable-clear="false"
        @input="handleSearchStringChange"
      />
      <div class="fader" />
    </div>
    <div
      ref="items-section"
      class="items-section"
      :style="{
        maxHeight: itemsSectionMaxHeight,
      }"
      @mouseleave="handleMouseLeaveItemsSection"
    >
      <template
        v-if="loading"
      >
        <div
          v-for="n in 3"
          :key="n"
          class="item"
          :style="itemStyle"
        >
          <am2-typography-skeleton size="xs" :level="2 * n - 1" />
        </div>
      </template>
      <template v-else-if="items.length > 0">
        <div
          v-for="(item, idx) of items"
          :key="idx"
          @mouseenter="handleMouseEnterItem(idx)"
          ref="dropdownList"
          :data-test-id="`dropdown-item-${generateSafeName(item)}`"
          :class="[
            'dropdown-item',
            item.disabled ? 'disabled' : ''
          ]"
        >
          <div
            v-if="item.type === 'header'"
            class="item header"
            :style="getHeaderItemStyle(idx)"
          >
            <ar-text
              size="xs"
              :text="item.name"
              weight="bold"
              v-bind="item.typography"
            />
          </div>
          <div
            v-else-if="item.type === 'divider'"
            class="item divider"
            :style="dividerStyle || item.style"
          />
          <div
            v-else-if="item.type === 'action'"
            class="item action"
            :style="itemStyle"
            @click="() => { handleItemClick(item); }"
          >
            <div class="action-icon">
              <ar-icon
                name="circle-plus"
                :color="$arStyle.color.purple500"
              />
            </div>
            <ar-text
              :text="item.name"
              size="xs"
              weight="bold"
              :style="{
                color: $arStyle.color.purple500,
              }"
              v-bind="item.typography"
            />
          </div>
          <div
            v-else-if="item.type === 'profile'"
            class="item profile"
            :style="itemStyle"
          >
            <ar-avatar
              :image-path="item.avatar"
              :name="`${item.firstName} ${item.lastName}`"
              class="avatar"
            />
            <div class="info-section">
              <ar-text
                size="xs"
                :text="`${item.firstName} ${item.lastName}`"
              />
              <ar-text
                size="xs"
                :text="item.emailAddress"
                class="subtitle"
              />
            </div>
          </div>
          <div
            v-else-if="item.type === 'extended-option'"
            class="item extended-option"
            :data-test-id="`option-${item.key}`"
            @click="() => { handleItemClick(item); }"
          >
            <div class="item-body">
              <div class="icon-container">
                <div
                  class="icon-wrapper"
                  :style="{
                    backgroundColor: item.iconColor,
                  }"
                >
                  <div
                    v-html="require(`~/assets/icons/${item.icon}.svg?raw`)"
                    class="icon"
                  />
                </div>
              </div>
              <div class="info-section">
                <ar-text
                  size="xs"
                  weight="bold"
                  :text="item.title"
                  class="title"
                />
                <ar-text
                  size="xs"
                  :text="item.description"
                  class="subtitle"
                  multipleLines
                />
              </div>
              <div
                v-if="!!item.tag"
                class="tag-container"
                :style="{
                  backgroundColor: item.tagBackground,
                }"
              >
                <div
                  v-if="!!item.tagIcon"
                  v-html="require(`~/assets/icons/${item.tagIcon}.svg?raw`)"
                  class="icon tag-icon"
                  :style="{
                    fill: item.tagColor,
                  }"
                />
                <ar-text
                  size="10px"
                  weight="bold"
                  :text="item.tag"
                  class="tag"
                  :style="{
                    color: item.tagColor,
                  }"
                />
              </div>
            </div>
            <div
                v-if="item.banner"
                class="banner"
                :style="{
                  backgroundColor: item.bannerBackground,
                }"
              >
                <ar-text
                  size="xs"
                  :text="item.banner"
                  class="subtitle"
                  weight="bold"
                  :style="{
                    color: item.bannerColor,
                  }"
                  multipleLines
                />
              </div>
          </div>
          <div
            v-else
            class="item text"
            :class="[arrowCounter === idx && item.type !== 'default-without-hover' && 'hover-style',
              item.type === 'default-without-hover' && 'default-without-hover']"
            :style="itemStyle"
            @click="() => { handleItemClick(item); }"
          >
            <div
              v-if="item.icon"
              class="icon-section"
            >
              <ar-icon
                class="icon"
                v-bind="item.icon"
              />
            </div>
            <ar-text
              class="typography"
              :text="item.name"
              size="xs"
              v-bind="item.typography"
            />
            <div v-if="item.controls" class="controls-section">
              <div
                v-for="(control, controlIdx) of item.controls"
                :key="controlIdx"
                class="control-wrapper"
              >
                <ar-icon-button
                  class="control"
                  :style="{
                    display: checkIsControlVisible(idx, control) ? 'inline-flex' : 'none',
                  }"
                  :color="control.pinned ? $arStyle.color.purple500 : $arStyle.color.purple200"
                  :icon-props="{
                    name: control.iconName,
                    width: '12px',
                  }"
                  @click="handleControlClick(item, control)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        v-else-if="hasSearch && items.length === 0"
        class="item"
        :style="itemStyle"
      >
        <ar-text
          :text="noOptionText"
          size="xs"
        />
      </div>
    </div>
    <div
      v-if="hasFixedFooter"
    >
      <div class="bottom-fader"></div>
      <div
        class="custom-divider"
        :style="itemStyle"
      >
        <ar-link-button
          text="+ Add custom field"
          :has-underline="false"
          :color="$arStyle.color.skyBlueGrey800"
          @click="handleCustomFieldClickManager"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DialogSelectBox',
  model: {
    prop: 'arrowCounter',
    event: 'updateArrowCounter'
  },
  props: {
    arrowCounter: {
      type: Number,
      default: -1
    },
    classNames: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    tabKey: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemStyle: {
      type: Object,
      default: () => {},
    },
    dividerStyle: {
      type: Object,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: 'Filter by...',
    },
    arrowAlign: {
      type: String,
      default: 'bottom-right',
    },
    arrowDistance: {
      type: String,
      default: '29px',
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: '340px',
    },
    noOptionText: {
      type: String,
      default: 'No option was found',
    },
    hasFixedFooter: {
      type: Boolean,
      default: false,
    },
    dropdownWidth: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      searchString: '',
      hoveredItemIdx: -1,
    };
  },
  computed: {
    verticalAlign() {
      return this.arrowAlign.indexOf('top') > -1 ? 'align-top' : 'align-bottom';
    },
    arrowAlignStyle() {
      switch (this.arrowAlign) {
        case 'top-left':
          return { bottom: '-5px', right: this.arrowDistance, transform: 'rotate(180deg)' };
        case 'top-right':
          return { bottom: '-5px', left: this.arrowDistance, transform: 'rotate(180deg)' };
        case 'top-center':
          return { bottom: '-5px', left: `${this.dropdownWidth/2}px`, transform: 'rotate(180deg)' };
        case 'bottom-left':
          return { top: '-5px', right: this.arrowDistance };
        case 'bottom-right':
          return { top: '-5px', left: this.arrowDistance };
        case 'bottom-center':
          return { top: '-5px', left: `${this.dropdownWidth/2}px` };
        default:
          return { top: '-5px', left: this.arrowDistance };
      }
    },
    itemsSectionMaxHeight() {
      if (!this.hasSearch) {
        return `calc(${this.maxHeight} - 20px)`;
      }
      return `calc(${this.maxHeight} - 65px)`;
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        if (this.hasSearch) {
          setTimeout(this.$refs.search.focus, 100);
        }
      } else {
        if (this.hasSearch) {
          this.handleSearchStringChange('');
        }
      }
    },
  },
  methods: {
    checkIsControlVisible(itemIndex, control) {
      return this.arrowCounter === itemIndex || control.pinned || this.$arMediaQuery.isTouchDevice;
    },
    updateArrowCounter(index) {
      if (this.arrowCounter === index) {
        return
      }

      this.$emit('updateArrowCounter', index)
    },
    getHeaderItemStyle(idx) {
      return {
        ...this.itemStyle,
        marginTop: idx === 0 ? '0' : '11px',
      };
    },
    generateSafeName(item) {
      const safeName = item.name || item.type || '';
      return safeName.replaceAll(/ /g, '-').replaceAll(/"/g, '-').replaceAll(/'/g, '-');
    },
    resetScroll() {
      this.$refs['items-section'].scrollTop = 0;
    },
    handleMouseEnterItem(itemIdx) {
      this.updateArrowCounter(itemIdx)
    },
    handleMouseLeaveItemsSection() {
      this.updateArrowCounter(-1)
    },
    handleTabSelect(tab) {
      this.$emit('tabSelect', tab);
    },
    handleItemClick(item) {
      if (item?.disabled) return
      this.$emit('select', item);
    },
    handleSearchStringChange(val) {
      this.searchString = val;
      this.$emit('searchStringChange', val);
    },
    handleControlClick(item, control) {
      if (control.closeOnClick) {
        this.$emit('close');
      }
      this.$emit('controlClick', item, control);
    },
    handleCustomFieldClickManager(item, control) {
      this.$emit('close');
      this.$emit('fixedFooterClick');
    }
  },
};
</script>

<style lang="scss" scoped>
.input-mirror {
  // FONT FAMILY CHANGES THE LINE HEIGHT
  padding-top: 3px;
  font-weight: bold;
}
.dialog-select {
  position: relative;
  display: inline-block;
  width: 200px;
  background: white;
  border: 1px solid $skyBlueGrey500;
  border-radius: 5px;
  padding: 8px 0;

  .triangle {
    position: absolute;
    width: 10px;

    .triangle-outer {
      position: absolute;
      top: 0;
      left: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $skyBlueGrey500;
    }

    .triangle-inner {
      position: absolute;
      top: 1px;
      left: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid white;
      transform: translateY(10%);
    }
  }

  .tabs-section {
    padding: 0 9px;
    margin-bottom: 11px;
  }

  .filter-section {
    padding: 0 9px;
    margin-bottom: -9px;
    margin-top: 4px;

    .filter {
      height: 40px;
    }

    .fader {
      height: 16px;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0) 100%);;
      position: relative;
      z-index: $zIndexHighest;
      width: calc(100% + 8px);
      margin: 3px 1px 0 1px;
    }
  }

  .bottom-fader {
    height: 16px;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0) 100%);;
    position: relative;
    z-index: $zIndexHighest;
    width: calc(100%);
    margin: -9px 0px 0 0px;
  }

  .custom-divider {
    padding: 12px 0 !important;
    margin: 0px 12px;
    border-top: 1px solid $skyBlueGrey500;

  }

  .items-section {
    overflow: auto;

    .dropdown-item {
      &.disabled {
        .item {
          &:hover {
            background: unset;
            cursor: not-allowed;
          }

          .ar-text {
            color: $skyBlueGrey600;
          }
        }

      }
    }

    .item {
      display: flex;
      align-items: center;
      padding: 12px 15px;

      .action-icon {
        line-height: 0;
        padding-right: 12px;
      }

      &.divider {
        padding: 0;
        margin: 12px 0;
        border-bottom: 1px solid $skyBlueGrey500;
      }

      &.text {
        display: flex;
        cursor: pointer;

        .icon-section {
          display: flex;
          align-items: center;
          width: 28px;

          .icon {
            width: 16px;
          }
        }

        .typography {
          width: calc(100% - 40px);
          flex-grow: 1;
        }

        .controls-section {
          display: flex;
          flex-flow: row;

          .control-wrapper {
            width: 20px;
          }
        }
      }

      &.profile {
        .avatar {
          flex-shrink: 0;
        }
        .info-section {
          overflow: hidden;
          margin-left: 10px;
          .subtitle {
            color: $skyBlueGrey700;
            margin-top: 4px;
          }
        }
      }

      &.action {
        cursor: pointer;
        span {
          color: $purple500;
        }

        &:hover {
          background: $purple100;
        }

        .action-name {
          position: relative;
          top: -2px;
        }
      }

      &.default-without-hover {
        cursor: unset;
      }

      &.extended-option {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;

        &:nth-of-type(1) {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        &:nth-last-of-type(1) {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        &:hover {
          background-color: #f4f4f4;
        }

        .title {
          margin-bottom: 5px;
        }
        .subtitle {

        }

        .icon {
          fill: white;
        }

        .tag-icon {
          width: 12px;
          height: 12px;
          display: flex;
          margin-right: 5px;
        }

        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 7px;
          height: 27px;
          width: 27px;
        }

        .icon-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 20px;
          height: 100%;
        }

        .item-body {
          display: flex;
          flex-direction: row;
        }

        .banner {
          width: 100%;
          margin-top: 15px;
          padding: 8px;
          border-radius: 4px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .tag-container {
          border-radius: 20px;
          margin-left: 10px;
          text-transform: uppercase;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: fit-content;
          padding: 5px 10px;
        }
      }
    }
  }

  .hover-style {
    background: $purple100;

    .typography, .icon {
      color: $purple500;
    }
  }
}
</style>
