var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'message-list-advanced-targeting-section' ]},[_c('div',{staticClass:"block-container  u-margin-top-2"},[_c('div',{style:({
    width: '50%',
    display: 'flex',
    verticalAlign: 'middle'
  })},[_c('ar-checkbox',{staticClass:"u-display-flex u-justify-content-space-between",attrs:{"label":"Ignore suppressions","data-test-id":"ignore-suppressions-toggle"},on:{"change":_vm.handleIgoreSuppression},model:{value:(_vm.ignoreSuppressed),callback:function ($$v) {_vm.ignoreSuppressed=$$v},expression:"ignoreSuppressed"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: 'Emails are placed on a suppression list when they have bounced 2 or more times. Enabling “Ignore suppressions” overrides the suppression list and allows you to send emails to fans on the suppression list.',
        }),expression:"{\n          content: 'Emails are placed on a suppression list when they have bounced 2 or more times. Enabling “Ignore suppressions” overrides the suppression list and allows you to send emails to fans on the suppression list.',\n        }",modifiers:{"top":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","color":_vm.$arStyle.color.skyBlueGrey600}})],1),_vm._v(" "),(_vm.showToggle)?_c('div',{staticClass:"toggle-module"},[(!_vm.toggle)?_c('ar-link-button',{attrs:{"text":_vm.toggleText,"has-underline":"","data-test-id":"message-list-targeting-toggle"},on:{"click":_vm.handleToggle}}):_vm._e()],1):_vm._e()]),_vm._v(" "),(_vm.showToggle && _vm.toggle)?_c('div',{staticClass:"targeting-module u-margin-top-8"},[_c('div',{staticClass:"u-display-flex u-justify-content-space-between u-margin-top-8"},[_c('ar-text',{attrs:{"text":"Quick filters","size":"xs","weight":"bold"}}),_vm._v(" "),_c('ar-link-button',{attrs:{"text":_vm.toggleText,"has-underline":"","data-test-id":"message-list-targeting-toggle"},on:{"click":_vm.handleToggle}})],1),_vm._v(" "),_c('ar-field',{style:({})},[_c('ar-simple-select',{staticClass:"select-filter-type u-margin-top-2",attrs:{"items":_vm.filterTypeItems,"default-select-index":_vm.selectedTypeIndex,"data-test-id":"message-targeting-type-select"},on:{"select":_vm.handleTypeSelect}})],1),_vm._v(" "),(_vm.displayedSubsection === 'tag')?_c('div',{class:['subsection',
             'tag-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        })},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Send only to people","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.tagSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent"},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Tag","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('am2-fan-tag-input',{staticClass:"tag-input",style:({
            backgroundColor:' #FFF',
            paddingTop: '5px',
            paddingBottom: '6px'
          }),attrs:{"placeholder":"Select tag...","allow-custom-input":false,"show-tag-count":false,"data-test-id":"message-targeting-tag-input"},on:{"input":_vm.handleTagInput},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}}),_vm._v(" "),_c('am2-link-button-dropdown',{style:({
            marginTop: '8px',
            float: 'right',
          }),attrs:{"items":_vm.tagSubdropdownItems,"item-key":_vm.selectedSubConditionKey,"dropdown-style":{

          },"data-test-id":"message-targeting-tag-subcondition-select"},on:{"select":_vm.handleTagSubdropdownItemSelect}})],1)],1):_vm._e(),_vm._v(" "),(_vm.displayedSubsection === 'segment')?_c('div',{class:['subsection',
             'segment-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        })},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Send only to people","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.segmentSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent"},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Segment","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"u-margin-top-3 segment-select u-width-100-percent",attrs:{"items":_vm.savedSegmentList,"default-select-index":_vm.selectedSegmentIndex,"enable-filter":"","placeholder":"Select segment...","data-test-id":"message-targeting-segment-select"},on:{"select":_vm.handleSegmentSelect}})],1)],1):_vm._e(),_vm._v(" "),(_vm.displayedSubsection === 'messages_opened')?_c('div',{class:['subsection',
             'message-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        })},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Send only to people","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.openedSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent"},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Message","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('am2-message-multiselect',{attrs:{"defaultValue":_vm.messageOid,"data-test-id":"message-targeting-message-select-opened"},on:{"select":_vm.handleSelect}})],1)],1):_vm._e(),_vm._v(" "),(_vm.displayedSubsection === 'messages_clicked')?_c('div',{class:['subsection',
             'message-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        })},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Send only to people","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.clickedSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent"},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Message","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('am2-message-multiselect',{attrs:{"defaultValue":_vm.messageOid,"data-test-id":"message-targeting-message-select-clicked"},on:{"select":_vm.handleSelect}})],1)],1):_vm._e(),_vm._v(" "),(_vm.displayedSubsection === 'events')?_c('div',{class:['subsection',
             'message-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        })},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Send only to people","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.eventSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent"},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Event","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('am2-event-multiselect',{attrs:{"defaultValue":_vm.eventOid,"data-test-id":"message-targeting-event-select"},on:{"select":_vm.handleSelect}})],1)],1):_vm._e(),_vm._v(" "),(_vm.displayedSubsection === 'campaigns')?_c('div',{class:['subsection',
             'message-subsection',
             _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min',
             _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']},[_c('ar-field',{staticClass:"field-condition",style:({
          minWidth: '184px'
        })},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Send only to people","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"select-filter-type",attrs:{"items":_vm.campaignSelectConditionItems,"default-select-index":_vm.selectedConditionIndex,"data-test-id":"message-targeting-condition-select"},on:{"select":_vm.handleConditionSelect}})],1),_vm._v(" "),_c('ar-field',{staticClass:"field-value u-width-100-percent"},[_c('ar-text',{staticClass:"label",style:({
            marginBottom: '8px',
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"14px","text":"Campaign","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('am2-campaign-multiselect',{attrs:{"defaultValue":_vm.campaignOid,"data-test-id":"message-targeting-campaign-select"},on:{"select":_vm.handleSelect}})],1)],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }