<template>
  <section
    class="integrations"
    :style="{
      padding: $arMediaQuery.pageContent.minWidth('sm') ? '28px 30px 0' : '28px 12px 0',
    }"
  >
    <am2-ask-moshtix-access-token-modal
      :is-show="showAskMoshtixAccessTokenModal"
      :loading="isCreatingMoshtixIntegration"
      @confirm="handleMoshtixAccessTokenConfirm"
      @cancel="showAskMoshtixAccessTokenModal = false"
    />
    <am2-ask-memberful-client-credentials-modal
      :is-show="showAskMemberfulCredentialsModal"
      :loading="isCreatingMemberfulIntegration"
      @confirm="handleMemberfulClientCredentialsConfirm"
      @cancel="showAskMemberfulCredentialsModal = false"
    />
    <am2-ask-ticketek-s3-credentials-modal
      :is-show="showAskTicketekS3CredentialsModal"
      :loading="isCreatingTicketekIntegration"
      @confirm="handleTicketekS3CredentialsConfirm"
      @cancel="showAskTicketekS3CredentialsModal = false"
    />
    <am2-ask-event-genius-access-token-modal
      :is-show="showAskEventGeniusAccessTokenModal"
      :loading="isCreatingEventGeniusIntegration"
      @confirm="handleEventGeniusAccessTokenConfirm"
      @cancel="showAskEventGeniusAccessTokenModal = false"
    />
    <am2-ask-dice-access-token-modal
      :is-show="showAskDiceAccessTokenModal"
      :loading="isCreatingDiceIntegration"
      @confirm="handleDiceAccessTokenConfirm"
      @cancel="showAskDiceAccessTokenModal = false"
    />
    <am2-ask-input-modal
      :is-show="showAskShopifyNameModal"
      :max-width="'590px'"
      title="Shopify Name"
      input-title="Shopify Name"
      input-suffix=".myshopify.com"
      input-placeholder="Enter Shopify name"
      @confirm="handleShopifyNameConfirm"
      @close="showAskShopifyNameModal = false"
    />

    <am2-category-tabs
      v-model="selectedCategory"
      :items="categoryTabs"
      :style="{
        marginBottom: '30px',
      }"
    />

    <section
      v-for="(integrationItem, integrationItemIdx) of integrationItems"
      :key="integrationItemIdx"
    >
      <am2-integration-card
        v-if="integrationItem.enabled && integrationItem.categories.indexOf(selectedCategory) > -1"
        class="integration-card"
        :title="integrationItem.title"
        :description="integrationItem.description"
        :logo-icon-name="integrationItem.icon"
        :logo-color="integrationItem.iconColor"
        :small-images="integrationItem.smallImages"
        :loading="integrationItem.loading"
        :disabled="integrationItem.disabled"
        :connected="integrationItem.connected"
        :failed="integrationItem.failed"
        @manage="integrationItem.onManage"
        @connect="integrationItem.onConnect"
        :data-test-id="`${integrationItem.key}-card`"
      />
    </section>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { generateFeatureLockerTitle, generateFeatureLockerMessage } from '@/utils/feature/';
import MoshtixLogoIcon from '~/assets/icons/moshtix-logo.svg?inline';

export default {
  components: {
    MoshtixLogoIcon,
  },
  name: 'Integrations',
  layout: 'default',

  data() {
    return {
      isConnecting: false,
      connectingService: null,
      showAskShopifyNameModal: false,
      showAskMoshtixAccessTokenModal: false,
      showAskEventGeniusAccessTokenModal: false,
      showAskMemberfulCredentialsModal: false,
      showAskTicketekS3CredentialsModal: false,
      showAskDiceAccessTokenModal: false,
      selectedCategory: 'all',
    };
  },
  computed: {
    ...mapState({
      promoter: state => state.auth.account,
      path: state => state.route.path,
      isFetchingIntegrations: state => state.integration.isFetchingIntegrations,
      isCreatingMoshtixIntegration: state => state.moshtixIntegration.isCreatingIntegration,
      isCreatingMemberfulIntegration: state => state.memberfulIntegration.isCreatingIntegration,
      isCreatingTicketekIntegration: state => state.ticketekIntegration.isCreatingIntegration,
      isCreatingEventGeniusIntegration: state => state.eventGeniusIntegration.isCreatingIntegration,
      isCreatingDiceIntegration: state => state.diceIntegration.isCreatingIntegration,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      firstEventbriteIntegration: 'integration/firstEventbriteIntegration',
      firstFacebookMessengerIntegration: 'integration/firstFacebookMessengerIntegration',
      firstFacebookCustomAudiencesIntegration: 'integration/firstFacebookCustomAudiencesIntegration',
      hasFacebookMessengerIntegrations: 'integration/hasFacebookMessengerIntegrations',
      hasFacebookCustomAudiencesIntegrations: 'integration/hasFacebookCustomAudiencesIntegrations',
      hasEventbriteIntegrations: 'integration/hasEventbriteIntegrations',
      hasShopifyIntegrations: 'integration/hasShopifyIntegrations',
      hasZoomIntegrations: 'integration/hasZoomIntegrations',
      hasPatreonIntegrations: 'integration/hasPatreonIntegrations',
      hasUniverseIntegrations: 'integration/hasUniverseIntegrations',
      hasStripeIntegrations: 'integration/hasStripeIntegrations',
      hasTicketekIntegrations: 'integration/hasTicketekIntegrations',
      hasMoshtixIntegrations: 'integration/hasMoshtixIntegrations',
      hasEventixIntegrations: 'integration/hasEventixIntegrations',
      hasMemberfulIntegrations: 'integration/hasMemberfulIntegrations',
      hasEventGeniusIntegrations: 'integration/hasEventGeniusIntegrations',
      hasDiceIntegrations: 'integration/hasDiceIntegrations',
    }),
    promoterIsInZoomWhitelist() {
      if (this.promoter.oid === 104) return true;
      if (this.promoter.emailAddress.endsWith('@arep.co')) return true;
      if (this.promoter.emailAddress.endsWith('@audiencerepublic.com')) return true;
      if (this.promoter.emailAddress == 'zoom.test@mailinator.com') return true;
      return false;
    },
    categoryTabs() {
      const tabs = [
        { text: 'All', key: 'all' },
        { text: 'Ticketing', key: 'ticketing' },
        { text: 'Virtual Events', key: 'virtual_events' },
        ...(process.env.arEnableShopifyIntegration ? [{ text: 'Ecommerce', key: 'ecommerce' }] : []),
        { text: 'Membership', key: 'membership' },
        { text: 'Advertising', key: 'advertising' },
        { text: 'Messaging', key: 'messaging' },
      ];
      return tabs;
    },
    // Currently get the first messenger integration's images
    fbMessengerPageImages() {
      if (!this.firstFacebookMessengerIntegration) {
        return null;
      }

      const msIntegration = this.firstFacebookMessengerIntegration.integration;
      if (!msIntegration || !msIntegration.pages) {
        return null;
      }
      const pages = msIntegration.pages;
      return Object.keys(pages).map(pageId => {
        const { name, profileImageUrl } = pages[pageId];
        return {
          name,
          src: profileImageUrl,
        };
      });
    },
    integrationItems() {
      const items = [
        {
          key: 'facebook-custom-audiences',
          enabled: process.env.arEnableFbCa,
          categories: ['all', 'advertising'],
          icon: 'facebook',
          iconColor: null,
          title: 'Facebook Custom Audiences',
          description: 'Create custom audiences to target with Facebook Ads',
          failed: this.integrationFailed('facebook', 'custom-audiences'),
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'facebook-custom-audiences'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'facebook-custom-audiences'),
          connected: this.hasFacebookCustomAudiencesIntegrations,
          arFeatureMask: {
            mode: 'transparent',
            show: !this.isFeatureEnabled(['integrations', 'facebookCustomAudience']),
            title: this.generateFeatureLockerTitle('Facebook Custom Audience'),
            message: this.generateFeatureLockerMessage('Facebook Custom Audience'),
          },
          onManage: () => { this.handleIntegrationManageClick('facebook', 'custom-audiences'); },
          onConnect: () => { this.handleConnectClick('facebook', 'custom-audiences'); },
        },
        {
          key: 'eventbrite-eventbrite',
          enabled: true,
          categories: ['all', 'ticketing'],
          icon: 'eventbrite',
          iconColor: this.$arStyle.color.eventbrite,
          title: 'Eventbrite',
          description: 'Automatically sync your ticket sales, events & attendees from Eventbrite',
          failed: this.integrationFailed('eventbrite', 'eventbrite'),
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'eventbrite'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'eventbrite'),
          connected: this.hasEventbriteIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('eventbrite', 'eventbrite'); },
          onConnect: () => { this.handleConnectClick('eventbrite', 'eventbrite'); },
        },
        {
          key: 'shopify-shopify',
          enabled: process.env.arEnableShopifyIntegration,
          categories: ['all', 'ecommerce'],
          icon: 'shopify',
          iconColor: this.$arStyle.color.shopify,
          title: 'Shopify',
          description: 'Automatically sync your sales data from Shopify',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'shopify'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'shopify'),
          connected: this.hasShopifyIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('shopify', 'shopify'); },
          onConnect: this.handleShopifyConnectClick,
        },
        {
          key: 'zoom-zoom',
          enabled: process.env.arEnableZoomIntegration && this.promoterIsInZoomWhitelist,
          categories: ['all', 'virtual_events'],
          icon: 'zoom',
          iconColor: this.$arStyle.color.zoom,
          title: 'Zoom',
          description: 'Automatically sync your events & attendees from Zoom',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'zoom'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'zoom'),
          connected: this.hasZoomIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('zoom', 'zoom'); },
          onConnect: () => { this.handleConnectClick('zoom', 'zoom'); },
        },
        {
          key: 'patreon-patreon',
          enabled: process.env.arEnablePatreonIntegration,
          categories: ['all', 'membership'],
          icon: 'patreon',
          iconColor: this.$arStyle.color.patreon,
          title: 'Patreon',
          description: 'Automatically sync your members from Patreon',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'patreon'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'patreon'),
          connected: this.hasPatreonIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('patreon', 'patreon'); },
          onConnect: () => { this.handleConnectClick('patreon', 'patreon'); },
        },
        {
          key: 'universe-universe',
          enabled: process.env.arEnableUniverseIntegration,
          categories: ['all', 'ticketing'],
          icon: 'universe',
          iconColor: this.$arStyle.color.universe,
          title: 'Universe',
          description: 'Automatically sync your ticket sales, events & attendees from Universe',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'universe'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'universe'),
          connected: this.hasUniverseIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('universe', 'universe'); },
          onConnect: () => { this.handleConnectClick('universe', 'universe'); },
        },
        {
          key: 'stripe-stripe',
          enabled: process.env.arEnableStripeIntegration,
          categories: ['all'],
          icon: 'stripe',
          iconColor: this.$arStyle.color.stripe,
          title: 'Stripe',
          description: 'Automatically sync your customers and transactions from Stripe',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'stripe'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'stripe'),
          connected: this.hasStripeIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('stripe', 'stripe'); },
          onConnect: () => { this.handleConnectClick('stripe', 'stripe'); },
        },
        {
          key: 'ticketek-ticketek',
          enabled: process.env.arEnableTicketekIntegration,
          categories: ['all', 'ticketing'],
          icon: 'ticketek',
          iconColor: this.$arStyle.color.ticketek,
          title: 'Ticketek',
          description: 'Automatically sync your ticket sales, events & attendees from Ticketek',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'ticketek'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'ticketek'),
          connected: this.hasTicketekIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('ticketek', 'ticketek'); },
          onConnect: this.handleTicketekConnectClick,
        },
        {
          key: 'moshtix-moshtix',
          enabled: process.env.arEnableMoshtixIntegration,
          categories: ['all', 'ticketing'],
          icon: 'moshtix',
          iconColor: this.$arStyle.color.moshtix,
          title: 'Moshtix',
          description: 'Automatically sync your ticket sales, events & attendees from Moshtix',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'moshtix'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'moshtix'),
          connected: this.hasMoshtixIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('moshtix', 'moshtix'); },
          onConnect: this.handleMoshtixConnectClick,
        },
        {
          key: 'eventix-eventix',
          enabled: process.env.arEnableEventixIntegration,
          categories: ['all', 'ticketing'],
          icon: 'eventix',
          iconColor: this.$arStyle.color.eventix,
          title: 'Eventix',
          description: 'Automatically sync your ticket sales, events & attendees from Eventix',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'eventix'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'eventix'),
          connected: this.hasEventixIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('eventix', 'eventix'); },
          onConnect: () => { this.handleConnectClick('eventix', 'eventix'); },
        },
        {
          key: 'memberful-memberful',
          enabled: process.env.arEnableMemberfulIntegration,
          categories: ['all', 'membership'],
          icon: 'memberful',
          iconColor: this.$arStyle.color.memberful,
          title: 'Memberful',
          description: 'Automatically sync your members from Memberful',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'memberful'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'memberful'),
          connected: this.hasMemberfulIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('memberful', 'memberful'); },
          onConnect: this.handleMemberfulConnectClick,
        },
        {
          key: 'event-genius-event-genius',
          enabled: process.env.arEnableEventGeniusIntegration,
          categories: ['all', 'ticketing'],
          icon: 'event-genius',
          iconColor: this.$arStyle.color.eventGenius,
          title: 'Event Genius',
          description: 'Automatically sync your ticket sales, events & attendees from Event Genius',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'event-genius'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'event-genius'),
          connected: this.hasEventGeniusIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('event-genius', 'event-genius'); },
          onConnect: this.handleEventGeniusConnectClick,
        },
        {
          key: 'dice-dice',
          enabled: process.env.arEnableDiceIntegration,
          categories: ['all', 'ticketing'],
          icon: 'dice',
          iconColor: this.$arStyle.color.dice,
          title: 'DICE',
          description: 'Automatically sync your ticket sales, events & attendees from DICE',
          failed: false,
          loading: this.isFetchingIntegrations || (this.isConnecting && this.connectingService === 'dice'),
          disabled: this.isFetchingIntegrations || (this.isConnecting && this.connectingService !== 'dice'),
          connected: this.hasDiceIntegrations,
          arFeatureMask: null,
          onManage: () => { this.handleIntegrationManageClick('dice', 'dice'); },
          onConnect: this.handleDiceConnectClick,
        },
      ];
      return items
        .sort((a, b) => {
          return (a.connected && !b.connected) ? -1 : 0;
        });
    },
  },

  mounted() {
    this['integration/FETCH_ALL_INTEGRATIONS']();
  },

  methods: {
    ...mapActions([
      'CONNECT_TO_INTEGRATION',
      'DELETE_INTEGRATION',
      'integration/FETCH_ALL_INTEGRATIONS',
      'moshtixIntegration/CREATE_MOSHTIX_INTEGRATION',
      'ticketekIntegration/CREATE_TICKETEK_INTEGRATION',
      'memberfulIntegration/CREATE_MEMBERFUL_INTEGRATION',
      'eventGeniusIntegration/CREATE_EVENT_GENIUS_INTEGRATION',
      'diceIntegration/CREATE_DICE_INTEGRATION',
    ]),

    generateFeatureLockerTitle(featureName) {
      return generateFeatureLockerTitle(featureName);
    },

    generateFeatureLockerMessage(featureName) {
      return generateFeatureLockerMessage(featureName);
    },

    handleIntegrationManageClick(provider, app) {
      let service;
      if (provider === app) {
        service = provider;
      } else {
        service = `${provider}-${app}`;
      }
      this.$router.push({
        path: `/settings/integrations/${service}`,
      });
    },

    integrationFailed(provider, app) {
      switch (`${provider}-${app}`) {
        case 'facebook-custom-audiences':
          if (!!this.firstFacebookCustomAudiencesIntegration && this.firstFacebookCustomAudiencesIntegration.status === 'failed') {
            return true;
          }
          break;
        case 'facebook-messenger':
          if (!!this.firstFacebookMessengerIntegration && this.firstFacebookMessengerIntegration.status === 'failed') {
            return true;
          }
          break;
        case 'eventbrite-eventbrite':
          if (!!this.firstEventbriteIntegration && this.firstEventbriteIntegration.status === 'failed') {
            return true;
          }
          break;
        default:
          break;
      }
      return false;
    },

    handleMemberfulConnectClick() {
      this.showAskMemberfulCredentialsModal = true;
    },

    handleEventGeniusConnectClick() {
      this.showAskEventGeniusAccessTokenModal = true;
    },

    handleMoshtixConnectClick() {
      this.showAskMoshtixAccessTokenModal = true;
    },

    handleTicketekConnectClick() {
      this.showAskTicketekS3CredentialsModal = true;
    },
    handleDiceConnectClick() {
      this.showAskDiceAccessTokenModal = true;
    },

    async handleMemberfulClientCredentialsConfirm(apiKey, siteName) {
      const succeed = await this['memberfulIntegration/CREATE_MEMBERFUL_INTEGRATION']({
        apiKey,
        siteName,
      });
      if (succeed) {
        this.$router.push({
          path: `/settings/integrations/memberful`,
        });
        this.showAskMemberfulCredentialsModal = false;
      }
    },

    async handleMoshtixAccessTokenConfirm(username, password) {
      const succeed = await this['moshtixIntegration/CREATE_MOSHTIX_INTEGRATION']({
        username,
        password,
      });
      if (succeed) {
        this.$router.push({
          path: `/settings/integrations/moshtix`,
        });
        this.showAskMoshtixAccessTokenModal = false;
      }
    },

    async handleTicketekS3CredentialsConfirm(s3BucketName, s3BucketRegion, s3BucketAccessKey, s3BucketSecretKey, messageList) {
      const succeed = await this['ticketekIntegration/CREATE_TICKETEK_INTEGRATION']({
        s3BucketName,
        s3BucketRegion,
        s3BucketAccessKey,
        s3BucketSecretKey,
        messageList,
      });
      if (succeed) {
        this.$router.push({
          path: `/settings/integrations/ticketek`,
        });
        this.showAskTicketekS3CredentialsModal = false;
      }
    },
    async handleEventGeniusAccessTokenConfirm(username, password) {
      const succeed = await this['eventGeniusIntegration/CREATE_EVENT_GENIUS_INTEGRATION']({
        username,
        password,
      });
      if (succeed) {
        this.$router.push({
          path: `/settings/integrations/event-genius`,
        });
        this.showAskEventGeniusAccessTokenModal = false;
      }
    },

    async handleDiceAccessTokenConfirm({accessToken, messageList, tagFans}) {
      const succeed = await this['diceIntegration/CREATE_DICE_INTEGRATION']({
        accessToken,
        messageList,
        tagFans,
      });
      if (succeed) {
        this.$router.push({
          path: `/settings/integrations/dice`,
        });
        this.showAskDiceAccessTokenModal = false;
      }
    },

    handleShopifyConnectClick() {
      this.showAskShopifyNameModal = true;
    },

    handleShopifyNameConfirm(shopName) {
      this.showAskShopifyNameModal = false;
      this.handleConnectClick('shopify', 'shopify', { shopName });
    },

    async handleConnectClick(provider, app, additionalInfo = null) {
      const existingIntegration = this.integrationFailed(provider, app);

      if (!!existingIntegration) {
        await this.DELETE_INTEGRATION(existingIntegration.oid);
      }

      let service;
      if (provider === app) {
        service = provider;
      } else {
        service = `${provider}-${app}`;
      }
      this.isConnecting = true;
      this.connectingService = service;


      try {
        const res = await this.CONNECT_TO_INTEGRATION({ provider, app, additionalInfo });
        this.$router.push({
          path: `/settings/integrations/${service}`,
        });
        this.isConnecting = false;
        this['integration/FETCH_ALL_INTEGRATIONS']();
      } catch (e) {
        // If it's closed by user, then it's all good
        if (e.name === 'USER_CLOSE') {
          return;
        }
        switch (`${provider}-${app}`) {
          case 'facebook-custom-audiences':
            this.$arNotification.push({ type: 'error', message: `Failed to connect Facebook Custom Audiences: ${e.message}` });
            break;
          case 'facebook-messenger':
            this.$arNotification.push({ type: 'error', message: `Failed to connect Facebook Messenger: ${e.message}` });
            break;
          case 'eventbrite-eventbrite':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Eventbrite: ${e.message}` });
            break;
          case 'shopify-shopify':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Shopify: ${e.message}` });
            break;
          case 'zoom-zoom':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Zoom: ${e.message}` });
            break;
          case 'patreon-patreon':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Patreon: ${e.message}` });
            break;
          case 'universe-universe':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Universe: ${e.message}` });
            break;
          case 'stripe-stripe':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Stripe: ${e.message}` });
            break;
          case 'ticketek-ticketek':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Ticketek: ${e.message}` });
            break;
          case 'moshtix-moshtix':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Moshtix: ${e.message}` });
            break;
          case 'eventix-eventix':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Eventix: ${e.message}` });
            break;
          case 'memberful-memberful':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to Memberful: ${e.message}` });
            break;
          case 'dice-dice':
            this.$arNotification.push({ type: 'error', message: `Failed to connect to DICE: ${e.message}` });
            break;
          default:
            this.$arNotification.push({ type: 'error', message: 'Failed to connect Eventbrite' });
            break;
        }
        console.error(e);
      } finally {
        this.isConnecting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.integrations {
  max-width: 1200px;
  margin: 0 auto;
  .integration-card {
    margin-bottom: 10px;
  }
}
</style>
