var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-modal',{staticClass:"import-data-modal",attrs:{"is-open":_vm.isVisible,"header":_vm.modalTitle,"has-back-link":_vm.step > 1,"hide-footer":_vm.step < 2,"width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.onClose,"back":function () { return _vm.step -= 1; }}},[(_vm.step===1)?_c('div',{class:[
      'import-data-modal__wrapper',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max' ],attrs:{"slot":"body"},slot:"body"},[_c('am2-dropzone',{attrs:{"file-type":['text/csv'],"file-size":209715200,"file-type-alias":"CSV file","placeholder-icon":{
        name: 'upload',
        width: '16px',
        height: '16px',
        color: _vm.$arStyle.color.purple500,
        wrapperStyle: {
          background: _vm.mouseOverCsvDropdown ? "white" : _vm.$arStyle.color.skyBlueGrey400,
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: _vm.mouseOverCsvDropdown ? '2px solid white' : null,
        },
      },"ratio":0.525},on:{"upload":_vm.handleCSVUploaded},nativeOn:{"mouseover":function($event){_vm.mouseOverCsvDropdown = true},"mouseleave":function($event){_vm.mouseOverCsvDropdown = false}}})],1):(_vm.step===2)?_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"mapping-instructions"},[_c('p',[_vm._v("\n        Map the fields to import into Audience Republic from your CSV file\n      ")]),_vm._v(" "),_c('p',{staticClass:"mapping-instructions__subtitle"},[_vm._v("\n        * indicates a compulsory field\n      ")])]),_vm._v(" "),_c('div',{class:[
      'import-data-modal__wrapper',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max' ]},[_c('p',{staticClass:"column-text"},[_c('span',[_vm._v("Audience Republic")]),_c('span',[_vm._v("CSV File")])]),_vm._v(" "),_c('div',{staticClass:"form"},_vm._l((_vm.arToPromoterMap),function(arTableHeader,index){return _c('div',{key:index,staticClass:"form-row"},[_c('p',{staticClass:"col-xs-12 col-sm-4 form-row__label"},[_vm._v(_vm._s(("" + (arTableHeader.name) + (arTableHeader.required ? '*' : '')))+"\n            "),(arTableHeader.hint && _vm.$arMediaQuery.window.minWidth('sm'))?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: arTableHeader.hint,
            }),expression:"{\n              content: arTableHeader.hint,\n            }",modifiers:{"top":true}}],staticClass:"icon",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-8 form-row__input"},[_c('ar-simple-select',{staticClass:"selector",attrs:{"enable-filter":"","items":_vm.csvHeadersOptions(arTableHeader.mappedTo),"placeholder":"Please select...","enable-clear":"","default-select-index":arTableHeader.mappedTo === null ? null : 0,"data-test-id":("csv-colum-" + (arTableHeader.serverHeaderName))},on:{"select":function (val) { return _vm.setMapping(val, arTableHeader); },"clear":function () { return _vm.setMapping({ index: null }, arTableHeader); }}})],1)])}),0)])]):(_vm.step===3)?_c('div',{class:[
      'import-data-modal__wrapper',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max' ],attrs:{"slot":"body"},slot:"body"},[_c('ar-text',{attrs:{"text":("<b>" + _vm.numberOfNewFans + "</b> contacts have been mapped"),"size":"xs","allow-html":""}}),_vm._v(" "),_c('ar-divider',{style:({
        margin: '15px 0 20px 0',
        height: '0.5px',
        background: _vm.$arStyle.color.$bluegrey500
      })}),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('ar-text',{staticClass:"col-xs-12 col-sm-4 form-row__label",style:({
          marginBottom: '4px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
        }),attrs:{"text":"Add to list","size":"xs","weight":"bold"}}),_vm._v(" "),_c('div',{staticClass:"message-list-select col-xs-12 col-sm-8"},[_c('am2-message-list-select',{attrs:{"channels":_vm.mobileNumberMapped ? ['email', 'sms'] : ['email'],"message-list-type":"manual","show-stats":false,"disabled":!_vm.canChangeMessageList},on:{"select":_vm.handleMessageListChange},model:{value:(_vm.messageListSettings.oid),callback:function ($$v) {_vm.$set(_vm.messageListSettings, "oid", $$v)},expression:"messageListSettings.oid"}}),_vm._v(" "),_c('ar-text',{staticClass:"message-list-subtitle",attrs:{"text":("Imported contacts can only be subscribed to lists with Email" + (_vm.mobileNumberMapped ? ' or SMS' : '') + " enabled"),"size":"xxxs","multiple-lines":"","line-height":"20px"}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageListSettings.oid !== null),expression:"messageListSettings.oid !== null"}],staticClass:"channel-choices"},[_c('ar-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageListSettings.listContainsEmail),expression:"messageListSettings.listContainsEmail"},{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.messageListSettings.emailCanBeEnabled ? null : "One or more of your imported contacts does not have a valid email address"
            }),expression:"{\n              content: messageListSettings.emailCanBeEnabled ? null : `One or more of your imported contacts does not have a valid email address`\n            }",modifiers:{"top":true}}],staticClass:"tick-section-checkbox",attrs:{"disabled":!_vm.messageListSettings.emailCanBeEnabled,"label":"Email","icon-name":"email","icon-props":{
              width: '18px',
              color: _vm.$arStyle.color.email,
            }},model:{value:(_vm.messageListSettings.emailEnabled),callback:function ($$v) {_vm.$set(_vm.messageListSettings, "emailEnabled", $$v)},expression:"messageListSettings.emailEnabled"}}),_vm._v(" "),_c('ar-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageListSettings.listContainsSms),expression:"messageListSettings.listContainsSms"},{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.messageListSettings.smsCanBeEnabled ? null : "One or more of your imported contacts does not have a valid phone number"
            }),expression:"{\n              content: messageListSettings.smsCanBeEnabled ? null : `One or more of your imported contacts does not have a valid phone number`\n            }",modifiers:{"top":true}}],staticClass:"tick-section-checkbox",attrs:{"disabled":!_vm.messageListSettings.smsCanBeEnabled,"label":"SMS","icon-name":"sms","icon-props":{
              width: '18px',
              color: _vm.$arStyle.color.sms,
            }},model:{value:(_vm.messageListSettings.smsEnabled),callback:function ($$v) {_vm.$set(_vm.messageListSettings, "smsEnabled", $$v)},expression:"messageListSettings.smsEnabled"}})],1)],1)],1),_vm._v(" "),_c('ar-divider',{style:({
        margin: '26px 0 21px 0',
        height: '0.5px',
        background: _vm.$arStyle.color.$bluegrey500
      })}),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('ar-text',{staticClass:"col-xs-12 col-sm-4 form-row__label",style:({
          marginBottom: '4px',
          height: '50px'
        }),attrs:{"text":"Add Tags","size":"xs","weight":"bold"}}),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-8"},[_c('am2-fan-tag-input',{model:{value:(_vm.newFanTags),callback:function ($$v) {_vm.newFanTags=$$v},expression:"newFanTags"}})],1)],1)],1):(_vm.step===4)?_c('div',{class:[
      'import-data-modal__wrapper',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max' ],attrs:{"slot":"body"},slot:"body"},[_c('p',{staticClass:"column-text"},[_c('span',[_vm._v("Audience Republic")]),_c('span',[_vm._v("CSV File")])]),_vm._v(" "),_c('p',{staticClass:"import-data-modal__wrapper__import-message"},[_vm._v("\n      "+_vm._s(_vm.importCompleteMessage)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.step >= 2)?_c('div',{class:[
      'import-data-modal__footer',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('div',[(_vm.error)?_c('div',{staticClass:"error-message"},[_vm._v("\n        "+_vm._s(_vm.error)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',[_c('ar-simple-button',{style:({ float: 'right' }),attrs:{"text":_vm.step === 3 ? 'Import contacts' : 'Continue',"loading":_vm.isImportingAudienceCsv,"disabled":_vm.nextButtonDisabled},on:{"click":_vm.handleNextStep}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }