<template>
  <div
    v-infinite-scroll="fetchMoreFans"
    infinite-scroll-disabled="disableFetchMoreFans"
    :infinite-scroll-distance="50"
  >
    <am2-fan-mass-edit-modal
      :is-open="displayMassFansEdit"
      :search-string="searchString"
      @edit="handleMassFansEdit"
      @cancel="handleMassFansEditCancel"
    />
    <am2-add-fan-to-message-list-modal
      :is-open="displayAddFanToMessageListModal"
      :search-string="searchString"
      @add="handleAddFanToMessageListComplete"
      @cancel="handleAddFanToMessageListCancel"
    />
    <am2-edit-fan-tags-modal
      :is-open="displayEditFanTagsModal"
      :action="editFanTagsAction"
      :search-string="searchString"
      @edit="handleFanTagsEdit"
      @cancel="handleFanTagsEditCancel"
    />
    <am2-fan-mass-delete-modal
      :is-open="displayFansDeleteModal"
      :search-string="searchString"
      @delete="handleFansDelete"
      @cancel="handleFansDeleteCancel"
    />
    <am2-unlock-prompt
      v-if="!isAudienceFeatureEnabled"
      :title="$arMediaQuery.pageContent.minWidth('sm') ? `Unlock Audience` : `Audience`"
      :subtitle="$arMediaQuery.pageContent.minWidth('sm') ? `Store all your ticket sales data & contacts in one place. Create segments for targeting, helping you sell more tickets. <a href='https://audiencerepublic.com/audience' target='_blank' style='color: #FFF;'>Learn more</a>` : `Store all your ticket sales data & contacts in one place. <a href='https://audiencerepublic.com/audience' target='_blank' style='color: #FFF;'>Learn more</a>`"
      :button-text="$arMediaQuery.pageContent.minWidth('sm') ? `Upgrade Plan` : `Upgrade`"
      @click="goToPlans"
      :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      v-ar-sticky-top="{
        priority: 1,
      }"
    />
    <section
      class="audience-page-wrapper"
    >
      <div
        :class="{
          content: true,
          ['audience-page-content']: true,
        }"
      >
        <div
          :class="[
            'audience-page-top-wrapper',
            $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          ]"
        >
          <div
            class="audience-page-top-left-section">
            <div class="audience-page-filter-title">
              <ar-text
                size="lg"
                text="Audience"
              />
            </div>
            <am2-segment-dropdown
              :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'large'"
              :style="{
                marginTop: '8px',
              }"
            />
          </div>
          <div
            v-if="!displayImportContactsSection"
            :class="[
              'audience-page-top-right-section',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
              $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
            ]"
          >
            <div class="audience-page-top-right-section-button">
              <ar-simple-button
                :text="$arMediaQuery.pageContent.maxWidth('xs') ? 'Import' : 'Import Contacts'"
                @click="handleImportClick"
                data-test-id="import-contacts-button"
                :style="{ width: '100%' }"
              />
            </div>
          </div>
        </div>
        <ar-divider
          :style="{
            marginTop: '29px',
          }"
        />

        <ar-snackbar
          v-if="hasValidPendingTasks"
          type="warning"
          :message="pendingTasksString"
          :style="{ marginTop: '10px' }"
        />
        <ImportContactsBox
          v-if="displayImportContactsSection"
          :size="$arMediaQuery.pageContent.maxWidth('sm') ? 'small' : 'medium'"
          :style="{
            marginTop: '28px',
            marginBottom: '30px',
          }"
          @connectIntegrations="handleConnectIngegrationsClick"
          @importCsv="handleImportClick"
        />
        <div
          :style="{
            marginTop: '30px',
            minHeight: '20px',
          }"
        >
          <ar-text
            v-if="!hideContactsCountText"
            size="xs"
            allow-html
            :text="contactsCountText"
          />
        </div>
        <am2-table-control-section
          v-ar-sticky-top="{
            priority: 1,
          }"
          :style="{
            marginTop: '8px',
            backgroundColor: '#f6f9fc',
          }"
          :fans-search="{
            action: serverSideSearch,
          }"
          :column-settings="{
            selectedColumnKeys: selectedColumnKeys,
            availableColumns: availableColumns,
            action: handleSelectedColumnKeysUpdate,
            disabled: !isFeatureEnabled(['audience']),
          }"
          :csv-export="{
            action: handleExportCsvClick,
            disabled: !isFeatureEnabled(['audience']),
          }"
          :loading="(isFetchingAudience || isWaitingForFirstFilter) && audience.length === 0"
          :reset-default-columns="resetDefaultColumns"
          show-filter-and-search-bar
          show-filter-options-bar
          show-checked-rows-options-bar
          @fansAddToMessageList="handleAddFanToMessageList"
          @fansEdit="handleFanEditClick"
          @fansEditTags="handleFanEditTagsClick"
          @fansDelete="handleFanDeleteClick"
        />
        <am2-fan-table
          ref="audience-table"
          v-ar-feature-mask="{
            show: showFeatureMask,
            showUpgradeOn: filterIsNotEmpty || forceFeatureMask ? 'always' : 'hover',
            iconName: 'audience',
            title: 'Put your data to use, to sell more tickets',
            message: 'Store all your ticket sales data & contacts in one place. Create segments for targeting, helping you sell more tickets.',
            margin: {
              top: 50
            },
          }"
          :locked="showFeatureMask"
          :use-dummy-fans="useDummyFans"
          :head="displayedTableHeadings"
          :custom-column-widths="customColumnWidths"
          :body="audience"
          :loading="isFetchingAudience || (isWaitingForFirstFilter && audience.length === 0)"
          empty-text="No audience data"
          :default-sort-by="orderBy"
          :window-width="windowWidth"
          enable-row-tick
          :style="{
            minHeight: showFeatureMask ? '380px' : null,
          }"
          has-sticky-header
          :enable-row-click="customerProfilesEnabled"
          @tableMouseLeave="handleTableMouseLeave"
          @cellMouseEnter="handleCellMouseEnter"
          @sortBy="handleSortChange"
          @rowClick="handleRowClick"
          @columnResize="handleColumnResize"
          has-control-section
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { debounce } from 'debounce';
import accounting from 'accounting';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import { magicDownload } from '@/utils/html-element/';

import { clone, arraysEqualUnsorted } from '@/utils/helpers/';

import ImportContactsBox from './import-contacts-box';
import { segmentsAreMeaningfullyDifferent } from '@/store/modules/segment/utils';

const lockColumnReason = 'Purchase a plan and see which of your contacts is spending the most.';
const tableConfig = [
  {
    name: 'Name',
    key: 'name',
    format: 'name',
    // Specialise the sort key because the server does not store a "name" field but rather first and last name separately.
    sortKey: 'firstName',
    attributeKey: ['firstName', 'lastName'],
  },
  {
    name: 'Email',
    key: 'emailAddress',
    format: 'emailAddress',
    sortKey: 'emailAddress',
    attributeKey: 'emailAddress',
  },
  {
    name: 'Mobile',
    key: 'mobileNumber',
    format: 'text',
    sortKey: 'mobileNumber',
    attributeKey: 'mobileNumber',
  },
  {
    name: 'Campaigns',
    key: 'totalCampaignCount',
    format: 'number',
    sortKey: 'totalCampaignCount',
    attributeKey: 'totalCampaignCount',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Referrals',
    key: 'totalReferrals',
    format: 'number',
    sortKey: 'totalReferrals',
    attributeKey: 'totalReferrals',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Events Purchased',
    key: 'totalEventCount',
    format: 'number',
    sortKey: 'totalEventCount',
    attributeKey: 'totalEventCount',
    lockIfDisabled: true,
    lockReason: `Purchase a plan and see which of your fans have attended the most of your events`,
  },
  {
    name: 'Tickets Purchased',
    key: 'totalTickets',
    format: 'number',
    sortKey: 'totalTickets',
    attributeKey: 'totalTickets',
    lockIfDisabled: true,
    lockReason: `Purchase a plan and see which of your fans have purchased the most tickets`,
  },
  {
    name: 'Total Points',
    key: 'totalPoints',
    format: 'number',
    sortKey: 'totalPoints',
    attributeKey: 'totalPoints',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Total Ticket Sales',
    key: 'totalTicketSales',
    format: 'currency',
    sortKey: 'totalTicketSales',
    attributeKey: 'totalTicketSales',
    lockIfDisabled: true,
    lockReason: `Purchase a plan and see which of your fans have spent the most on tickets`,
  },
  {
    name: 'City',
    key: 'city',
    format: 'text',
    sortKey: 'city',
    attributeKey: 'city',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Zip/Post Code',
    key: 'postcode',
    format: 'text',
    sortKey: 'postcode',
    attributeKey: 'postcode',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'State',
    key: 'state',
    format: 'text',
    sortKey: 'state',
    attributeKey: 'state',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Country',
    key: 'country',
    format: 'country',
    sortKey: 'country',
    attributeKey: 'country',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Date of Birth',
    key: 'dob',
    format: 'dob',
    sortKey: 'dob',
    attributeKey: 'dob',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Gender',
    key: 'gender',
    format: 'text',
    sortKey: 'gender',
    attributeKey: 'gender',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Age',
    key: 'age',
    format: 'number',
    sortKey: 'age',
    attributeKey: 'age',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Date Modified',
    key: 'sysMtime',
    format: 'sysMtime',
    sortKey: 'sysMtime',
    attributeKey: 'sysMtime',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  // {
  //   name: 'Total Messages Sent',
  //   key: 'totalMessagesSent',
  //   format: 'number',
  //   sortKey: 'totalMessagesSent',
  //   attributeKey: 'totalMessagesSent',
  //   lockIfDisabled: true,
  //   lockReason: lockColumnReason,
  // },
  // {
  //   name: 'Total Messages Opened',
  //   key: 'totalMessagesOpened',
  //   format: 'number',
  //   sortKey: 'totalMessagesOpened',
  //   attributeKey: 'totalMessagesOpened',
  //   lockIfDisabled: true,
  //   lockReason: lockColumnReason,
  // },
  // {
  //   name: 'Total Messages Clicked',
  //   key: 'totalMessagesClicked',
  //   format: 'number',
  //   sortKey: 'totalMessagesClicked',
  //   attributeKey: 'totalMessagesClicked',
  //   lockIfDisabled: true,
  //   lockReason: lockColumnReason,
  // },
  // {
  //   name: 'Total Points',
  //   sortable: true,
  //   format: 'points',
  //   key: 'totalPoints',
  //   attributeKey: 'totalPoints',
  //   visible: true,
  // },
];

const defaultColumnValuesWhenAudienceDisabled = ['name', 'emailAddress', 'mobileNumber', 'totalTicketSales', 'totalTickets', 'totalEventCount'];

const requiredColumnValues = ['name', 'emailAddress'];

const availableColumns = [
  {
    name: 'Name',
    value: 'name',
    disabled: true,
  },
  {
    name: 'Email',
    value: 'emailAddress',
    disabled: true,
  },
  {
    name: 'Mobile',
    value: 'mobileNumber',
  },
  {
    name: 'Campaigns',
    value: 'totalCampaignCount',
  },
  {
    name: 'Referrals',
    value: 'totalReferrals',
  },
  {
    name: 'Events Purchased',
    value: 'totalEventCount',
  },
  {
    name: 'Tickets Purchased',
    value: 'totalTickets',
  },
  {
    name: 'Total Points',
    value: 'totalPoints',
  },
  {
    name: 'Total Ticket Sales',
    value: 'totalTicketSales',
  },
  {
    name: 'Zip/Post Code',
    value: 'postcode',
  },
  {
    name: 'City',
    value: 'city',
  },
  {
    name: 'State',
    value: 'state',
  },
  {
    name: 'Country',
    value: 'country',
  },
  {
    name: 'Age',
    value: 'age',
  },
  {
    name: 'Date of Birth',
    value: 'dob',
  },
  {
    name: 'Gender',
    value: 'gender',
  },
  {
    name: 'Date Modified',
    value: 'sysMtime',
    skipFetch: true, // We need this, otherwise attempting to explicitly fetch sysMtime will break the request!
  },
  // {
  //   name: 'Total Messages Sent',
  //   value: 'totalMessagesSent',
  // },
  // {
  //   name: 'Total Messages Opened',
  //   value: 'totalMessagesOpened',
  // },
  // {
  //   name: 'Total Messages Clicked',
  //   value: 'totalMessagesClicked',
  // },
];
const resetDefaultColumns = ['mobileNumber', 'totalEventCount', 'totalTickets', 'totalTicketSales']

export default {
  name: 'Audience',
  layout: 'default',
  mixins: [
    checkoutPageAccessibility({ featureKeys: ['audience'], featureName: 'Audience', skip: process.env.arEnableActivationState }),
  ],

  components: {
    ImportContactsBox,
  },

  data: () => ({
    displayMassFansEdit: false,
    displayAddFanToMessageListModal: false,
    displayEditFanTagsModal: false,
    displayFansDeleteModal: false,
    editFanTagsAction: null,
    tableConfig: tableConfig,
    defaultColumnValuesWhenAudienceDisabled,
    requiredColumnValues,
    searchString: '',
    isWaitingForFirstFilter: true,
    orderBy: { key: 'totalTicketSales', order: 'desc' },
    availableColumns, // This is for multiple select modal, it's always fixed
    resetDefaultColumns,
    selectedColumnKeys: [],
    enableMessage: process.env.arEnableFbMessenger,
    isFacebookMessengerFeatureEnabled: false,
    forceFeatureMask: false,
    fansOptionPaneShowing: false,
    customColumnWidths: null,
    initialAudienceFetchRequested: false,
    windowWidth: null,
  }),

  computed: {
    ...mapState({
      displaySegmentDrawer: state => state.layout.displaySegmentDrawer,
      account: state => state.auth.account,
      scratchSegmentInfo: state => state.segment.scratchSegmentInfo,
      audience: state => state.audience.audience,
      totalAudienceCount: state => state.audience.totalAudienceCount,
      isFetchingAudience: state => state.audience.isFetchingAudience,
      isFetchingAudienceCount: state => state.audience.isFetchingAudienceCount,
      isNoMoreAudience: state => state.audience.isNoMoreAudience,
      hasFetchAudienceFailed: state => state.audience.hasFetchAudienceFailed,
      audienceSelection: state => state.audience.audienceSelection,
      isExportingAudienceCsv: state => state.audience.isExportingAudienceCsv,
      pendingTasks: state => state.promoterTasks.pendingTasks,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      prunedScratchSegment: 'segment/prunedScratchSegment',
      scratchSegmentHasCompleteConditions: 'segment/scratchSegmentHasCompleteConditions',
      partlySelectedAudience: 'audience/partlySelectedAudience',
      promoterAudienceCountIsLessThanHundred: 'audience/promoterAudienceCountIsLessThanHundred',
      promoterAccountOid: 'auth/promoterAccountOid',

      hasPendingTasks: 'promoterTasks/hasPendingTasks',
      pendingImportTasks: 'promoterTasks/pendingImportTasks',
      pendingMassEditTasks: 'promoterTasks/pendingMassEditTasks',
      pendingExportTasks: 'promoterTasks/pendingExportTasks',
      pendingTagTasks: 'promoterTasks/pendingTagTasks',
      pendingFanDeleteTasks: 'promoterTasks/pendingFanDeleteTasks',
      pendingMessageListMassEditsTasks: 'promoterTasks/pendingMessageListMassEditsTasks',
    }),
    hasValidPendingTasks() {
      return this.pendingImportTasks.length > 0 ||
        this.pendingMassEditTasks.length > 0 ||
        this.pendingExportTasks.length > 0 ||
        this.pendingTagTasks.length > 0 ||
        this.pendingMessageListMassEditsTasks.length > 0 ||
        this.pendingFanDeleteTasks.length > 0
    },
    pendingTasksString() {
      if (this.pendingImportTasks.length > 0 &&
        this.pendingMassEditTasks.length === 0 &&
        this.pendingExportTasks.length === 0 &&
        this.pendingTagTasks.length === 0 &&
        this.pendingMessageListMassEditsTasks.length === 0 &&
        this.pendingFanDeleteTasks.length === 0) {
        return "CSV import in progress. Your contacts will be added soon.";

      } else if (this.pendingImportTasks.length === 0 &&
        this.pendingMassEditTasks.length > 0 &&
        this.pendingExportTasks.length === 0 &&
        this.pendingTagTasks.length === 0 &&
        this.pendingMessageListMassEditsTasks.length === 0 &&
        this.pendingFanDeleteTasks.length === 0) {
        return "Mass edit in progress. Your contacts will be updated soon.";

      } else if (this.pendingImportTasks.length === 0 &&
        this.pendingMassEditTasks.length === 0 &&
        this.pendingExportTasks.length > 0 &&
        this.pendingTagTasks.length === 0 &&
        this.pendingMessageListMassEditsTasks.length === 0 &&
        this.pendingFanDeleteTasks.length === 0) {
        return "CSV export in progress. You'll receive an email soon.";

      } else if (this.pendingImportTasks.length === 0 &&
        this.pendingMassEditTasks.length === 0 &&
        this.pendingExportTasks.length === 0 &&
        this.pendingTagTasks.length > 0 &&
        this.pendingMessageListMassEditsTasks.length === 0 &&
        this.pendingFanDeleteTasks.length === 0) {
        return "Tag changes in progress. Your contacts will be updated soon.";

      } else if (this.pendingImportTasks.length === 0 &&
        this.pendingMassEditTasks.length === 0 &&
        this.pendingExportTasks.length === 0 &&
        this.pendingTagTasks.length === 0 &&
        this.pendingMessageListMassEditsTasks.length > 0 &&
        this.pendingFanDeleteTasks.length === 0) {
        return "Message list addition in progress. Your list will be updated soon.";

      } else if (this.pendingImportTasks.length === 0 &&
        this.pendingMassEditTasks.length === 0 &&
        this.pendingExportTasks.length === 0 &&
        this.pendingTagTasks.length === 0 &&
        this.pendingMessageListMassEditsTasks.length === 0 &&
        this.pendingFanDeleteTasks.length > 0) {
        return "Contact deletion in progress. Your selected contacts will be removed soon.";
      }

      const tasks = [];
      if (this.pendingImportTasks.length > 0) tasks.push('CSV import');
      if (this.pendingMassEditTasks.length > 0) tasks.push('Mass edit');
      if (this.pendingExportTasks.length > 0) tasks.push('CSV export');
      if (this.pendingTagTasks.length > 0) tasks.push('Tag edit');
      if (this.pendingMessageListMassEditsTasks.length > 0) tasks.push('List addition');
      if (this.pendingFanDeleteTasks.length > 0) tasks.push('Contact deletion');

      return `Multiple tasks currently in progress (${tasks.join(', ')})`;
    },
    displayImportContactsSection() {
      return this.promoterAudienceCountIsLessThanHundred;
    },
    hideContactsCountText() {
      return this.isFetchingAudienceCount
        || this.isWaitingForFirstFilter
        || this.audience.length === 0;
    },
    appliedFilter() {
      return this.prunedScratchSegment ? this.prunedScratchSegment.filter : { conditions: [], logic: [] }
    },
    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },
    disableFetchMoreFans() {
      return this.isFetchingAudience || this.isNoMoreAudience || this.hasFetchAudienceFailed;
    },
    contactsCountText() {
      if (!this.prunedScratchSegment) { return null; }
      if (this.prunedScratchSegment.filter.conditions.length === 0) {
        return `Showing all <strong>${this.filterStatsCount}</strong> contacts`;
      }
      return `Showing <strong>${this.filterStatsCount}</strong> contacts`;
    },
    showFeatureMask() {
      if (this.forceFeatureMask) {
        return true;
      }
      // Only when you don't have filter feature, you get chacne to see this mask
      if (!this.isAudienceFeatureEnabled && !this.isFetchingAudience && !this.isWaitingForFirstFilter) {
        // If you don't have any fans, show it
        if (this.audience.length === 0) {
          return true;
        } else if (this.audience.length !== 0 && this.filterIsNotEmpty) {
          // If you got fans but you are trying to filter them
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // We show dummy fans only to a very small subset of users
    useDummyFans() {
      if (this.isWaitingForFirstFilter || !this.initialAudienceFetchRequested) return false;

      if (
        !this.isAudienceFeatureEnabled
        && !this.isFetchingAudienceCount
        && !this.isFetchingAudience
        && this.totalAudienceCount === 0
      ) {
        return true;
      }

      return false;
    },
    isAudienceFeatureEnabled() {
      return this.isFeatureEnabled(['audience']);
    },

    displayedTableHeadings() {
      return this.tableConfig.filter(heading => {
        if (this.selectedColumnKeys.includes(heading.key)) {
          return heading;
        }
      });
    },

    // This is the keys for fetching datas from server
    displayedAttributeKeys() {
      let res = [];
      for (let i = 0; i < this.tableConfig.length; i += 1) {
        const heading = this.tableConfig[i];
        if (heading.lockIfDisabled && !this.isAudienceFeatureEnabled) continue;
        let shouldSkip = false;
        const columnItem = availableColumns.find( column => column.value === heading.key );
        if (columnItem && columnItem.skipFetch) shouldSkip = true;
        if (this.selectedColumnKeys.includes(heading.key) && heading.attributeKey && !shouldSkip) {
          if (Array.isArray(heading.attributeKey)) {
            res = res.concat(heading.attributeKey);
          } else {
            res.push(heading.attributeKey);
          }
        }
      }
      return res;
    },

    // Gets a list of the attribute keys which are currently selected for viewing and which we can order results by
    sortableAttributeKeys() {
      let res = [];
      for (let i = 0; i < this.tableConfig.length; i += 1) {
        const heading = this.tableConfig[i];
        if (heading.lockIfDisabled && !this.isAudienceFeatureEnabled) continue;
        if (this.selectedColumnKeys.includes(heading.key) && heading.attributeKey) {
          if (Array.isArray(heading.attributeKey)) {
            res = res.concat(heading.attributeKey);
          } else {
            res.push(heading.attributeKey);
          }
        }
      }
      return res;
    },

    filterIsNotEmpty() {
      if (!this.prunedScratchSegment) return false;
      return this.prunedScratchSegment.filter.conditions.length !== 0;
    },

    filterStatsCount() {
      return accounting.formatNumber(this.totalAudienceCount);
    },
  },

  watch: {
    prunedScratchSegment: {
      async handler(newVal, oldVal) {
        const meaningfulDifference = segmentsAreMeaningfullyDifferent(newVal, oldVal);
        if (meaningfulDifference) {
          this.debouncedHandleAppliedFilterUpdate();
        } else {
          console.log("Ignoring meaningless filter update...")
        }
      },
      immediate: false,
    },
    selectedColumnKeys(newSettings, oldSettings) {
      const settings = {
        oid: this.promoterAccountOid,
        columns: newSettings,
      };
      window.localStorage.setItem('_ar_promoter_audience_preferences_', JSON.stringify(settings));
      if (oldSettings.length !== 0) this.reloadFans()
    },
    orderBy(newOrderBy) {
      window.sessionStorage.setItem('_ar_promoter_audience_order_by_', JSON.stringify(newOrderBy));
    },
  },

  created() {
    this.debouncedHandleAppliedFilterUpdate = debounce(this.handleAppliedFilterUpdate, 600)
    const audienceTableSettings = window.localStorage.getItem('_ar_promoter_audience_preferences_');
    const orderBy = window.sessionStorage.getItem('_ar_promoter_audience_order_by_');
    const columnWidths = window.localStorage.getItem('_ar_promoter_audience_column_widths_');
    let orderByObject;
    let customColumnWidthsObject;

    try {
      orderByObject = orderBy ? JSON.parse(orderBy) : { key: 'totalTicketSales', order: 'desc' };
    } catch(e) {
      orderByObject = { key: 'totalTicketSales', order: 'desc' };
    }
    this.orderBy = orderByObject;

    try {
      let lastPromoterOid;
      lastPromoterOid = JSON.parse(columnWidths).oid;
      customColumnWidthsObject = parseInt(lastPromoterOid) === this.promoterAccountOid && columnWidths ? JSON.parse(columnWidths) : { oid: this.promoterAccountOid, columns: [] };
      const testObject = customColumnWidthsObject.oid && customColumnWidthsObject.columns.length; // Just to check integrity of the object
    } catch(e) {
      customColumnWidthsObject = { oid: this.promoterAccountOid || null, columns: [] };
    }

    const availableColumnKeys = availableColumns.map(({ value }) => value);

    let selectedColumnKeys;
    if (audienceTableSettings) {
      // Wrap JSON.parse in try/catch, because we can't guarantee what the structure of user data will be
      try {
        let lastPromoterOid;
        lastPromoterOid = JSON.parse(audienceTableSettings).oid;
        selectedColumnKeys = parseInt(lastPromoterOid) === this.promoterAccountOid ? JSON.parse(audienceTableSettings).columns.filter((key => availableColumnKeys.indexOf(key) > -1)) : [...defaultColumnValuesWhenAudienceDisabled];
      } catch(e) {
        selectedColumnKeys = [...defaultColumnValuesWhenAudienceDisabled];
      }
    } else {
      selectedColumnKeys = this.availableColumns.reduce((arr, { value }) => {
        arr.push(value);
        return arr;
      }, []);
    }
    if (!selectedColumnKeys || selectedColumnKeys.length < 1) {
      selectedColumnKeys = [...defaultColumnValuesWhenAudienceDisabled];
    }

    // Finally, make sure the required column keys are always present
    this.requiredColumnValues.forEach( requiredValue => {
      if (selectedColumnKeys.indexOf(requiredValue) === -1) selectedColumnKeys.push(requiredValue);
    });

    this.selectedColumnKeys = !this.isAudienceFeatureEnabled ? selectedColumnKeys.filter(key => defaultColumnValuesWhenAudienceDisabled.indexOf(key) > -1) : selectedColumnKeys;
    this.customColumnWidths = customColumnWidthsObject;
    this.windowWidth = this.customColumnWidths.windowWidth;

    this.tableConfig = this.tableConfig.map( item => {
      return {
        ...item,
        lock: !this.isAudienceFeatureEnabled && item.lockIfDisabled
      }
    });
    this['application/HISTORY_RESET']('/audience');
    this.debouncedHandleAppliedFilterUpdate();
  },

  beforeDestroy() {
    this['audience/CLEAR_ALL_SELECTED_AUDIENCE']();
    this['audience/RESET_AUDIENCE']();
  },

  methods: {
    ...mapActions([
      'audience/EXPORT_AUDIENCE_CSV',
      'audience/FETCH_MORE_AUDIENCE',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'OPEN_IMPORT_CONTACTS_MODAL',
    ]),
    ...mapMutations([
      'layout/TOGGLE_SEGMENT_DRAWER',
      'audience/CLEAR_ALL_SELECTED_AUDIENCE',
      'audience/RESET_AUDIENCE',
      'application/HISTORY_RESET',
    ]),

    switchFilterSidebar() {
      this['layout/TOGGLE_SEGMENT_DRAWER']();
    },

    handleImportClick() {
      this.OPEN_IMPORT_CONTACTS_MODAL();
    },

    getOrderBy () {
      if (this.isAudienceFeatureEnabled) {
        if (this.sortableAttributeKeys.includes(this.orderBy.key) && this.selectedColumnKeys.includes(this.orderBy.key)) {
          return this.orderBy
        } else {
          return { key: 'emailAddress', order: 'asc' }
        }
      } else {
        return { key: 'sysMtime', order: 'desc' }
      }
    },

    async fetchMoreFans() {
      if (!this.prunedScratchSegment || this.isWaitingForFirstFilter) return;
      await this['audience/FETCH_MORE_AUDIENCE']({
        searchString: this.searchString,
        filter: this.prunedScratchSegment.filter,
        orderBy: this.getOrderBy(),
        // we need additionalInfo now because we may need to aggregate FBM opt-ins
        selectKeys: [...this.displayedAttributeKeys, 'additionalInfo'],
      });
      this.$nextTick(() => {
        if (!this.initialAudienceFetchRequested) this.initialAudienceFetchRequested = true;
      });
    },

    async reloadFans() {
      if (this.isWaitingForFirstFilter) this.isWaitingForFirstFilter = false;
      await this['audience/FETCH_MORE_AUDIENCE']({
        searchString: this.searchString,
        filter: this.prunedScratchSegment.filter,
        orderBy: this.getOrderBy(),
        selectKeys: [...this.displayedAttributeKeys, 'additionalInfo'],
        reload: true,
      });
      this.$nextTick(() => {
        if (!this.initialAudienceFetchRequested) this.initialAudienceFetchRequested = true;
      });
      // Fetch tasks for CSV imports, Mass Edits and CSV Exports. Refresh these on reload of fans.
      this['promoterTasks/START_POLLING_PENDING_TASKS']({
        reload: true,
      });
    },

    async serverSideSearch(searchString) {
      // Remember to clear selection
      this.searchString = searchString;
      this.initialAudienceFetchRequested = false;
      this.reloadFans();
    },

    handleAppliedFilterUpdate() {
      // Scratch Filter must be based on something, if source is null, skip it
      if (this.scratchSegmentInfo.source === null) { return; }

      this.$nextTick(() => {

        // All filters have to have oid && promter hasn't logged out
        if (!!this.prunedScratchSegment && this.account) {
          this.reloadFans();
        }
      });
    },

    clearSelection() {
      this['audience/CLEAR_ALL_SELECTED_AUDIENCE']();
    },

    handleFanEditClick() {
      this.displayMassFansEdit = true;
    },

    handleMassFansEdit() {
      this.displayMassFansEdit = false;
      this.clearSelection();
      this.reloadFans();
    },

    handleMassFansEditCancel() {
      this.displayMassFansEdit = false;
    },

    handleAddFanToMessageList() {
      this.displayAddFanToMessageListModal = true;
    },

    handleAddFanToMessageListComplete() {
      this.displayAddFanToMessageListModal = false;
      this.startPollingPendingTasks();
    },

    // Add a slight timeout so that the server has time to process the task and add it to the DB.
    startPollingPendingTasks() {
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({
          reload: true,
        });
      }, 1250);
    },

    handleAddFanToMessageListCancel() {
      this.displayAddFanToMessageListModal = false;
    },

    handleConnectIngegrationsClick() {
      this.$router.push({
        path: '/settings/integrations',
      });
    },

    handleExportCsvClick() {
      if (this.isExportingAudienceCsv) {
        return;
      }
      // The export CSV request will fail if the orderBy column is not also selected in selectKeys.
      // This will default the orderBy to a safe column if that happens, or if audience feature is disabled.
      const safeOrderBy = {
        ...this.orderBy
      };
      if (!this.isAudienceFeatureEnabled || !this.displayedAttributeKeys.some(item => item === this.orderBy.key)) {
        safeOrderBy.key = 'sysMtime';
        safeOrderBy.order = 'desc';
      }
      this['audience/EXPORT_AUDIENCE_CSV']({
        fans: this.partlySelectedAudience,
        filter: this.appliedFilter,
        orderBy: safeOrderBy,
        searchString: this.searchString,
        selectKeys: this.displayedAttributeKeys,
      });

      this.startPollingPendingTasks();
    },

    async handleFanEditTagsClick(action) {
      this.displayEditFanTagsModal = true;
      this.editFanTagsAction = action;
    },

    handleFanTagsEdit() {
      this.displayEditFanTagsModal = false;
      // Remember to clear selection
      this.clearSelection();
      this.reloadFans();
    },

    handleFanTagsEditCancel() {
      this.displayEditFanTagsModal = false;
    },

    handleFansDelete() {
      this.displayFansDeleteModal = false;
      this.clearSelection();
      this.startPollingPendingTasks();
    },

    handleFansDeleteCancel() {
      this.displayFansDeleteModal = false;
    },

    handleFanDeleteClick() {
      this.displayFansDeleteModal = true;
    },

    handleClearSelectionClick() {
      this.clearSelection();
    },

    handleSortChange(orderObj) {
      let key = orderObj.key;
      const order = orderObj.order;

      this.orderBy = {
        key,
        order,
      };

      this.reloadFans();
    },

    handleSearchKeywordChange(keyword) {
      this.searchString = keyword;
    },

    handleSelectedColumnKeysUpdate(columnKeys) {
      this.selectedColumnKeys = columnKeys;
    },

    async goToPlans() {
      this.$router.push({
        path: `/plans`,
      });
    },

    handleRowClick(target) {
      if (this.customerProfilesEnabled && target && target.oid) {
        this.$router.push({
          path: `/audience/${target.oid}/view/overview`,
        });
      }
    },

    handleColumnResize(columnsData) {
      const existingData = window.localStorage.getItem('_ar_promoter_audience_column_widths_');
      let existingDataObject;
      try {
        existingDataObject = existingData ? JSON.parse(existingData) : { oid: this.promoterAccountOid, columns: [] };
        const testObject = existingDataObject.oid && existingDataObject.columns.length; // Just to check integrity of the object
      } catch(e) {
        existingDataObject = { oid: this.promoterAccountOid, columns: [] };
      }
      existingDataObject.columns = columnsData;
      existingDataObject.windowWidth = window.innerWidth;
      const stringifiedExistingDataObject = JSON.stringify(existingDataObject);
      window.localStorage.setItem('_ar_promoter_audience_column_widths_', stringifiedExistingDataObject);
    },

    handleCellMouseEnter(head) {
      // TODO - Think about keeping it or removing it
      // if(!head.lock) return;
      // this.forceFeatureMask = true;
    },

    handleTableMouseLeave() {
      // TODO - Think about keeping it or removing it
      // this.forceFeatureMask = false;
    },

    handleFansControlPanelOptionsPaneToggle(val) {
      this.fansOptionPaneShowing = val;
    }

  },
};
</script>

<style lang="scss" scoped>
.audience-page-wrapper {
  display: flex;
  .audience-page-content {
    transition: 0.3s all;
    margin-left: 0;
    width: 100%;

    .audience-page-top-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 50px;

      .audience-page-top-left-section {
        position: relative;
        top: -1px;
        display: flex;
        flex-direction: column;
        min-width: 0; // default min-width is auto, we have to replace it with 0
        align-items: flex-start;
        width: 100%;

        .audience-page-filter-title {
          margin-right: 4px;
          overflow: hidden;
        }
      }

      .audience-page-top-right-section {
        display: flex;
        flex-direction: row;

        .audience-page-top-right-section-button {
          margin-left: 15px;
          width: 156px;
        }

        &.sm-max {
          width: 100%;
          justify-content: flex-end;

          .audience-page-top-right-section-button {
            width: 156px;
          }
        }

        &.xs-max {
          .audience-page-top-right-section-button {
            margin-left: 0;
            width: 80px;
          }
        }
      }
    }

    .audience-page-function-button {
      position: relative;
      background: $skyBlueGrey400;
      top: 0;
      margin-left: 0;

      &.disabled {
        cursor: not-allowed;
      }

      &.has-margin-left {
        margin-left: 7px;
      }

      .audience-page-function-button-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        width: 30px;
        height: 30px;
      }

      .audience-page-function-button-text {
        display: inline-block;
        vertical-align: middle;
        top: 2px;
      }
    }
  }
}
.premium {
   margin: 30px;
   font-size: 2em;
}

</style>
