var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"campaign-edit-invite"},[_c('EditInvitationMethodModal',{attrs:{"is-open":_vm.editInviteMethodModalChannel !== null,"invitation-method":_vm.editInviteMethodModalChannel,"current-button-copy":_vm.editInviteMethodModalChannelProps.buttonCopy},on:{"save":_vm.handleEditInvitationMethodModalSave,"cancel":_vm.handleEditInvitationMethodModalCancel}}),_vm._v(" "),_c('ar-text',{staticClass:"step-heading",style:({
      color: '#9FA8B5',
      marginBottom: '8px',
    }),attrs:{"size":"xs","text":"STEP 5"}}),_vm._v(" "),_c('am2-heading',{style:({marginBottom: '38.5px'}),attrs:{"type":"h1","size":"lg","title":"Invite friends"}}),_vm._v(" "),_c('p',{staticClass:"large smaller-margins"},[_vm._v("\n    "+_vm._s(_vm.copy)+"\n  ")]),_vm._v(" "),_c('am2-field',{class:[
      'social-invite-friends',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"name":"social-invite-friends"}},[(!!_vm.campaignViewModel && !!_vm.campaignViewModel.inviteChannels && Array.isArray(_vm.campaignViewModel.inviteChannels))?_c('draggable',{attrs:{"list":_vm.campaignViewModel.inviteChannels,"data-test-id":"draggable-invite-channels"}},[_c('transition-group',{attrs:{"name":"overlay-fade","tag":"div"}},_vm._l((_vm.campaignViewModel.inviteChannels),function(channel,index){return _c('div',{key:channel.key,attrs:{"data-test-id":("invite-channel-" + (channel.key))}},[_c('div',{staticClass:"u-display-flex",on:{"mouseenter":function () { return _vm.toggleVisibilityOfEditButton(index, false); },"mouseleave":function () { return _vm.toggleVisibilityOfEditButton(null, false); }}},[_c('ar-checkbox',{style:({ width: 'calc(100% - 32px)', height: '52px' }),attrs:{"icon-name":channel.iconName,"icon-props":{ color: channel.iconColor },"label":channel.name,"comment":channel.mandatory ? 'Mandatory' : null,"align":"right","data-test-id":("invitation-method-" + (channel.key) + "-checkbox"),"disabled":channel.mandatory || false},model:{value:(channel.selected),callback:function ($$v) {_vm.$set(channel, "selected", $$v)},expression:"channel.selected"}}),_vm._v(" "),_c('div',{style:({
                  alignContent: 'center',
                  display: 'flex',
                  minWidth: '32px',
              })},[(_vm.visibleEditButtonRow.index === index)?_c('am2-icon-button-dropdown',{style:({
                  alignContent: 'center',
                  display: 'flex',
                }),attrs:{"icon-props":{
                  name: 'menu',
                },"color":_vm.$arStyle.color.blueGrey600,"items":_vm.inviteMethodOptions,"data-test-id":("invitation-method-" + (channel.key) + "-edit-dropdown")},on:{"select":function (item) { return _vm.handleInviteMethodOptionSelect(item.eventName, channel); },"toggle":function (isOpen) { return _vm.toggleVisibilityOfEditButton((isOpen ? index : null), true); }}}):_vm._e()],1)],1),_vm._v(" "),(index !== _vm.campaignViewModel.inviteChannels.length - 1)?_c('ar-divider'):_vm._e()],1)}),0)],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"settings-container"},[_c('ar-link-button',{staticClass:"show-settings",attrs:{"text":_vm.showRewardSettings ? 'Hide settings' : 'Show settings',"data-test-id":"toggle-rewards-settings"},on:{"click":_vm.toggleRewardSettings}}),_vm._v(" "),(_vm.showRewardSettings)?_c('am2-field',{class:[
        'social-invite-friends',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"name":"reward-settings"}},[_c('am2-heading',{attrs:{"title":"Settings"}}),_vm._v(" "),_c('am2-field',{staticClass:"input-field",attrs:{"name":"points-input","label":"Points for Rererral (any channel)","description":"Insert a value between 0 and 100","help":"Modifying referral points mid-campaign will only apply to new referrals"}},[_c('ar-input',{attrs:{"placeholder":"Points for Rererral (default 10)","type":"number","data-test-id":"referral-reward-points-input"},model:{value:(_vm.rewardPoints),callback:function ($$v) {_vm.rewardPoints=$$v},expression:"rewardPoints"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }