import { PromoterIntegrationTask } from '@/api/promoter-integration-task/types';
import { FormattedPromoterTask } from './types';
import dayjs from 'dayjs';


function generateClickthrough(task: PromoterIntegrationTask): string {
  if (!!task.messageListOid) {
    return `/message-center/lists/${task.messageListOid}/contacts`;
  } else if (!!task.messageTaskOid) {
    return `/message-center/messages/${task.messageTaskOid}/view`;
  } else if (!!task.campaignOid) {
    return `/campaigns/${task.campaignOid}/view/dashboard`;
  } else if (!!task.eventOid) {
    return `/events/${task.eventOid}/view/sales`;
  } else if (task.name === 'fan-message') {
    return `/message-center/messages/${task.oid}/view`;
  }
  return '/audience';
}

export function formatTask(task: PromoterIntegrationTask): FormattedPromoterTask | null {
  let name = '';
  let subtitle = '';
  let type = task.name;
  let icon = '';
  let clickthrough = generateClickthrough(task);
  let status = task.status;

  if (type === 'background-fan-import-csv') {
    const provider = task.provider && task.provider.length > 0 && task.provider !== "unknown" ? task.provider.charAt(0).toUpperCase() + task.provider.slice(1) : null;
    name = `CSV import`;
    if (provider) name = `${name} from ${provider}`;
    subtitle = `started ${dayjs(task.started).format('hh:mm a')}`;
    icon = 'upload';

  } else if (type === 'mass-edit-tags') {
    name = "Mass edit contact tags";
    subtitle = `started ${dayjs(task.started).format('hh:mm a')}`;
    icon = 'edit';

  } else if (type === 'mass-edit-fan-attributes') {
    name = "Mass edit contacts";
    subtitle = `started ${dayjs(task.started).format('hh:mm a')}`;
    icon = 'edit';

  } else if (type === 'mass-edit-message-list') {
    name = `Change list subscriptions`;
    subtitle = `started ${dayjs(task.started).format('hh:mm a')}`;
    icon = 'edit';

  } else if (type === 'mass-delete-fans') {
    name = "Delete contacts";
    subtitle = `started ${dayjs(task.started).format('hh:mm a')}`;
    icon = 'discard';

  } else if (type === "fan-message") {
    const provider = task.provider;
    name = `Send ${provider} message`;
    icon = 'email';

  } else if (type === 'message-list-oid-fan-export-csv') {
    name = `Export contacts from list`;
    icon = 'export';

  } else if (type === 'audience-filter-fan-export-csv') {
    name = `Export contacts`;
    icon = 'export';

  } else if (type === 'message-task-oid-fan-export-csv') {
    name = `Export message recipients`;
    icon = 'export';

  } else if (type === 'loyalty-programs-data-synch') {
    const provider = task.provider && task.provider.length > 0 ? task.provider.charAt(0).toUpperCase() + task.provider.slice(1) : 'loyalty program';
    name = `Sync data from ${provider}`;
    icon = 'audience-alt';

  } else if (type === 'event-data-synch') {
    const provider = task.provider && task.provider.length > 0 ? task.provider.charAt(0).toUpperCase() + task.provider.slice(1) : 'integration';
    name = `Sync data from ${provider}`;
    icon = 'audience-alt';

  } else if (type === 'fans-to-custom-audience-synch') {
    name = `Facebook Custom Audience sync`;
    icon = 'audience-alt';

  } else {
    return null;
  }

  return {
    name,
    subtitle,
    type,
    icon,
    clickthrough,
    startTime: task.started,
    status,
  }
}


export function pendingTasksOfResourceAndOid(pendingTasks: PromoterIntegrationTask[], resource: string, oid: number) {
  // @ts-ignore
  return pendingTasks.filter(item => item[resource] === parseInt(oid));
}


export function pendingTaskOfTypeAndResourceAndOid(pendingTasks: PromoterIntegrationTask[], type: string, resource: string, oid: number) {
  // @ts-ignore
  return pendingTasks.filter( item => item.name === type && item[resource] === parseInt(oid));
}
