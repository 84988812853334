<template>
  <section class="u-display-inline">
    <am2-expand-button-dropdown
      v-if="kind==='expand-icon-button'"
      align="left"
      :button-props="{ text: preventExpand ? '' : 'Settings', iconName: 'settings' }"
      :items="actions"
      @select="handleSettingOptionSelect"
      :data-test-id="`campaign-dropdown-${campaignNameInKebabCase}`"
    />
    <am2-icon-button-dropdown
      v-else
      :icon-props="{
        name: 'menu',
        height: '16px',
      }"
      :color="menuIconColor"
      :hovered-color="menuIconColor === 'white' ? $arStyle.color.blueGrey700 : $arStyle.color.purple500"
      :items="actions"
      :dropdown-style="{
        width: '200px',
      }"
      :style="{ marginBottom: '10px' }"
      @select="handleSettingOptionSelect"
      :data-test-id="`campaign-dropdown-${campaignNameInKebabCase}`"
    />
    <am2-create-campaign-widget-modal
      :is-open="displayCreateCampaignWidgetModal"
      :campaign="campaign"
      @cancel="displayCreateCampaignWidgetModal = false"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { campaignBaseUri } from '@/utils/campaign';
import { sanitizeHtmlChild } from '@/utils/html-element/';

export default {
  name: 'CampaignDropdown',

  props: {
    kind: {
      type: String,
      required: true
    },
    preventExpand: {
      type: Boolean,
    },
    menuStyle: {
      type: String,
      default: 'expand-icon-button',
    },
    menuIconColor: {
      type: String,
      default: null,
    },
    campaign: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      displayCreateCampaignWidgetModal: false,
    };
  },

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),

    actions() {
      if (!this.campaign) return [];
      const actions = [
        {
          name: 'Edit campaign',
          action: this.openCampaignEdit
        },
        {
          name: 'Edit event',
          action: this.openEventEdit
        },
        {
          name: 'View campaign',
          action: this.openCampaignPreview
        },
        {
          name: 'View results',
          action: this.openViewResults
        },
      ];

      if (!this.isFreeCampaign) {
        actions.push({
          name: 'Set as tour',
          action: this.createTour
        })
        actions.push({
          name: 'Create widget',
          action: this.openWidgetModal
        })
      }

      if (this.isFreeCampaign || this.isPremiumCampaignEnabled) {
        actions.push({
          name: 'Duplicate',
          action: this.duplicateCampaign
        })
      }

      actions.push({
        name: 'Delete',
        action: this.deleteCampaign
      });

      return actions;
    },

    isFreeCampaign() {
      return this.campaign && this.campaign?.presentation.flow.length === 1;
    },

    isPremiumCampaignEnabled() {
      return this.isFeatureEnabled(['campaigns']);
    },

    campaignNameInKebabCase() {
      if (!this.campaign) {
        return '';
      }
      return this.$arUtils.general.generateDataTestPrettyName(this.campaign.name);
    },
  },

  methods: {
    ...mapActions(['CLONE_CAMPAIGN', 'DELETE_CAMPAIGN', 'SHOW_CONFIRM', 'OPEN_WIDGET_MODAL']),

    handleSettingOptionSelect(item) {
      item.action();
    },

    openWidgetModal() {
      this.displayCreateCampaignWidgetModal = true;
    },

    openCampaignEdit() {
      return this.$router.push({
        path: `/campaigns/${this.campaign.oid}/edit/details?key=${this.isFreeCampaign ? 'free' : 'premium'}`
      });
    },

    openCampaignPreview() {
      return window.open(this.previewURL(), '_blank');
    },

    openViewResults() {
      return this.$router.push(`/campaigns/${this.campaign.oid}/view/dashboard`);
    },

    createTour() {
      return this.$router.push({ path: `/tours/setup?campaignOid=${this.campaign.oid}` })
    },

    openEventEdit() {
      return this.$router.push({ path: `/events/${this.campaign.eventOid}/edit` })
    },

    previewURL() {
      return `${campaignBaseUri(this.campaign.type)}${this.campaign.urlSlug}`;
    },

    async duplicateCampaign() {

      const res = await this.CLONE_CAMPAIGN(this.campaign.oid);
      this.$arNotification.push({ type: 'success', message: `Campaign "${this.campaign.name}" duplicated` });
      this.$router.push({
        path: `/campaigns/${res.oid}/view/dashboard`
      });
    },

    async deleteCampaign() {
      const res = await this.SHOW_CONFIRM({ messageHtml: `Are you sure you want to delete the campaign<br /><b>${sanitizeHtmlChild(this.campaign.name)}</b>?` })
      if(res) {
        await this.DELETE_CAMPAIGN(this.campaign.oid);
        this.$arNotification.push({ type: 'success', message: `Event "${this.campaign.name}" deleted` });
        this.$emit('delete');
      }
    },
  },
};
</script>




