<template>
  <section class="campaign-edit-invite">
    <EditInvitationMethodModal
      :is-open="editInviteMethodModalChannel !== null"
      :invitation-method="editInviteMethodModalChannel"
      :current-button-copy="editInviteMethodModalChannelProps.buttonCopy"
      @save="handleEditInvitationMethodModalSave"
      @cancel="handleEditInvitationMethodModalCancel" />

    <ar-text
      class="step-heading"
      size="xs"
      text="STEP 5"
      :style="{
        color: '#9FA8B5',
        marginBottom: '8px',
      }"
    />
    <am2-heading
      type="h1"
      size="lg"
      title="Invite friends"
      :style="{marginBottom: '38.5px'}"
    />

    <p class="large smaller-margins">
      {{copy}}
    </p>

    <am2-field
      name="social-invite-friends"
      :class="[
        'social-invite-friends',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      ]"
    >

      <draggable
        v-if="!!campaignViewModel && !!campaignViewModel.inviteChannels && Array.isArray(campaignViewModel.inviteChannels)"
        :list="campaignViewModel.inviteChannels"
        data-test-id="draggable-invite-channels"
      >
        <transition-group name="overlay-fade" tag="div">
          <div
            v-for="(channel, index) in campaignViewModel.inviteChannels"
            :key="channel.key"
            :data-test-id="`invite-channel-${channel.key}`"
          >
            <div
              class="u-display-flex"
              @mouseenter="() => toggleVisibilityOfEditButton(index, false)"
              @mouseleave="() => toggleVisibilityOfEditButton(null, false)"
              >
              <ar-checkbox
                v-model="channel.selected"
                :icon-name="channel.iconName"
                :icon-props="{ color: channel.iconColor }"
                :label="channel.name"
                :comment="channel.mandatory ? 'Mandatory' : null"
                align="right"
                :data-test-id="`invitation-method-${channel.key}-checkbox`"
                :disabled="channel.mandatory || false"
                :style="{ width: 'calc(100% - 32px)', height: '52px' }"
              />
              <div
                :style="{
                    alignContent: 'center',
                    display: 'flex',
                    minWidth: '32px',
                }"
              >
                <am2-icon-button-dropdown
                  v-if="visibleEditButtonRow.index === index"
                  :icon-props="{
                    name: 'menu',
                  }"
                  :color="$arStyle.color.blueGrey600"
                  :items="inviteMethodOptions"
                  @select="(item) => handleInviteMethodOptionSelect(item.eventName, channel)"
                  @toggle="(isOpen) => toggleVisibilityOfEditButton((isOpen ? index : null), true)"
                  :data-test-id="`invitation-method-${channel.key}-edit-dropdown`"
                  :style="{
                    alignContent: 'center',
                    display: 'flex',
                  }"
                />
              </div>

            </div>
            <ar-divider
              v-if="index !== campaignViewModel.inviteChannels.length - 1"
            />
          </div>
        </transition-group>
      </draggable>
    </am2-field>

    <div class="settings-container">
      <ar-link-button
        class="show-settings"
        :text="showRewardSettings ? 'Hide settings' : 'Show settings'"
        data-test-id="toggle-rewards-settings"
        @click="toggleRewardSettings"
      />
      <am2-field 
        v-if="showRewardSettings"
        name="reward-settings"
        :class="[
          'social-invite-friends',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        ]"
      >
        <am2-heading title="Settings" />
        <am2-field 
          name="points-input" 
          label="Points for Rererral (any channel)"
          description="Insert a value between 0 and 100"
          class="input-field"
          help="Modifying referral points mid-campaign will only apply to new referrals"
        >
          <ar-input
            v-model="rewardPoints"
            placeholder="Points for Rererral (default 10)"
            type="number"
            data-test-id="referral-reward-points-input"
          />
        </am2-field>
      </am2-field>
    </div>
  </section>
</template>

<script>
import { copyGenerator, inviteMethodDefaultCopy } from '@/utils/campaign';
import EditInvitationMethodModal from './components/edit-invitation-method-modal/EditInvitationMethodModal';
import draggable from "vuedraggable";

const defaultRewardPoints = 10;

// We append the default invites channels to the invites list 
// in order to set points value the same to all channels
const defaultInviteChannels = ['copy'];

export default {
  name: 'EditInvite',

  components: {
    EditInvitationMethodModal,
    draggable,
  },

  data: () => ({
    campaignViewModel: {
      inviteChannels: {},
      meta: {}
    },
    visibleEditButtonRow: {
      index: null,
      force: false,
    },
    editInviteMethodModalChannel: null,
    editInviteMethodModalChannelProps: {
      buttonCopy: null,
    },
    showRewardSettings: false,
    rewardPoints: defaultRewardPoints,
  }),

  props: {
    editCampaign: {
      type: Object
    },
    setEditCampaign: {
      type: Function
    }
  },

  watch: {
    campaignViewModel: {
      handler(value) {
        this.updateCampaignPreview(value, this.rewardPoints);
      },
      deep: true
    },
    rewardPoints: {
      handler(value) {
        this.updateCampaignPreview(this.campaignViewModel, value);
      }
    },
  },

  computed: {
    inviteMethodOptions() {
      return [{
        name: 'Edit',
        eventName: 'edit',
      }]
    },
    copy() {
      return copyGenerator('invite', this.editCampaign.type);
    },
    meta() {
      return this.editCampaign.presentation?.meta
    }
  },

  created() {
    this.initializeCampaignViewModel();
    this.initializeRewardPoints();
  },

  methods: {
    updateCampaignPreview(campaignViewModel, rewardPoints) {
      const { inviteChannels } = campaignViewModel;
      const parsedPoints = parseInt(rewardPoints, 10);

      const invitesMap = inviteChannels.reduce((accum, item, index) => {
        accum[item.key] = {
          enabled: item.selected,
          key: item.key,
          order: index,
        };
        return accum;
      }, {});

      const defaults = {
        points: {
          ...this.editCampaign.defaults.points,
          referral: parsedPoints,
        },
      };

      const socialActions = {
        invites: this.addDefaultInviteChannels(invitesMap),
      };

      this.setEditCampaign({ socialActions, defaults });
    },
    addDefaultInviteChannels(invitesMap) {
      const parsedPoints = parseInt(this.rewardPoints, 10);

      defaultInviteChannels.forEach(key => {
        invitesMap[key] = {
          enabled: false,
          key,
          order: -1,
          points: isNaN(parsedPoints) ? this.defaultRewardPoints : parsedPoints,
        };
      });

      return invitesMap;
    },
    handleInviteMethodOptionSelect(eventName, channel) {
      if (eventName === 'edit') {
        // Right now it'll just keep reset back to whatever the default is.
        this.editInviteMethodModalChannelProps = {
          buttonCopy: this.meta?.invite?.[channel.key]?.buttonCopy ? this.meta.invite[channel.key].buttonCopy : inviteMethodDefaultCopy(channel.key),
        }
        this.editInviteMethodModalChannel = channel.key
      }
      // Support for other eventNames in case we want the dropdown to show additional options
    },
    handleEditInvitationMethodModalSave(newData) {
      // TODO - Action the data from newData
      this.editInviteMethodModalChannel = null;

      const { invitationMethod, buttonCopy } = newData

      let newInviteObj = {}
      newInviteObj[invitationMethod] = { buttonCopy }

      let meta = {
        ...this.campaignViewModel.meta,
        invite: {
          ...newInviteObj
        }
      }

      let presentation = {
        ...this.editCampaign.presentation,
        meta: {
          ...meta
        }
      }

      this.setEditCampaign({ presentation })
    },
    handleEditInvitationMethodModalCancel() {
      this.editInviteMethodModalChannel = null;
    },
    toggleRewardSettings() {
      this.showRewardSettings = !this.showRewardSettings;
    },
    // This allows to us forcefully keep the edit button visible if the user opens the menu
    toggleVisibilityOfEditButton(index, force) {
      if (this.visibleEditButtonRow.force && !force) return;
      this.visibleEditButtonRow = {
        index,
        force: index === null ? false : force
      }
    },

    initializeRewardPoints() {
      // Initialize rewardPoints with the referral points from the campaign
      const campaignPoints = this.editCampaign?.defaults?.points?.referral;
      if (campaignPoints) {
        this.rewardPoints = campaignPoints;
      }
    },

    initializeCampaignViewModel() {
      const invites = this.editCampaign.socialActions ? this.editCampaign.socialActions.invites || {} : {};
      const isInvitesNewVersion = typeof invites.sms === 'object'

      // A.R. 2022-07-12
      // Changed structure of each of the invite channels into object with { key, order, enabled }
      // But we need to make sure in terms of backwards compatibility that we still accept boolean

      let defaultInviteChannels = [
          {
            name: 'Facebook Messenger',
            key: 'messenger',
            iconName: 'messenger',
            iconColor: this.$arStyle.color.messenger,
          },
          {
            name: 'Instagram Direct Message',
            key: 'instagram',
            iconName: 'instagram-direct-message',
            iconColor: this.$arStyle.color.instagramDirectMessage,
          },
          {
            name: 'Twitter',
            key: 'twitter',
            iconName: 'twitter',
            iconColor: this.$arStyle.color.twitter,
            selected: invites.twitter !== false, // default true,
          },
          {
            name: 'WhatsApp',
            key: 'whatsapp',
            iconName: 'whatsapp',
            iconColor: this.$arStyle.color.whatsapp,
          },
          {
            name: 'SMS',
            key: 'sms',
            iconName: 'sms',
            iconColor: this.$arStyle.color.sms,
          },
        ]

      let inviteChannels = defaultInviteChannels.map((defaultInviteChannel, index) => {
        let selected = true;
        if (isInvitesNewVersion) {
          if (invites[defaultInviteChannel.key]?.enabled !== undefined && invites[defaultInviteChannel.key]?.enabled !== null && typeof invites[defaultInviteChannel.key]?.enabled == 'boolean') {
            selected = invites[defaultInviteChannel.key]?.enabled
          }
        } else if (invites[defaultInviteChannel.key] !== undefined && invites[defaultInviteChannel.key] !== null && typeof invites[defaultInviteChannel.key] == 'boolean') {
          selected = invites[defaultInviteChannel.key]
        }

        let order = index
        if (isInvitesNewVersion && invites[defaultInviteChannel.key]?.order !== undefined && invites[defaultInviteChannel.key]?.order !== null) {
          order = invites[defaultInviteChannel.key]?.order
        }

        return {
          ...defaultInviteChannel,
          selected,
          order,
        }
      })

      if (isInvitesNewVersion) {
        inviteChannels = inviteChannels.sort((fieldA, fieldB) => {
          return fieldA.order - fieldB.order;
        })
      }

      this.campaignViewModel = {
        inviteChannels,
      }
    },
  },
};
</script>


<style lang="scss">
  @import '../../../assets/styles/base/mixins';
.campaign-edit-invite {
  .social-invite-friends {
    border-radius: 4px;
    padding: 1rem 3rem;
    border: 1px solid $blueGrey500;

    &.xs-max {
      padding: 18px 12px;
    }
  }
  .smaller-margins {
    margin-bottom: -15px;
  }
  .settings-container {
    margin-top: 44px;
  }
  .input-field {
    margin-top: 32px !important;
    margin-bottom: 22px;
  }
  .inline-help {
    display: flex;
    flex-direction: row;
  }
}
</style>
