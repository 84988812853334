<template>
  <section
    class="event-orders-container"
    v-infinite-scroll="loadMoreData"
    infinite-scroll-disabled="infiniteScrollDisabled"
    infinite-scroll-distance="10"
  >
    <div
      class="orders-header"
      :style="{
        padding: '20px 0 22px',
        backgroundColor: '#F7F9FC',
      }"
      v-ar-sticky-top="{
        priority: 1,
      }"
    >
      <am2-heading
        type="h1"
        size="md"
        :title="`Orders`"
        :style="{
          marginRight: '15px',
        }"
      />
      <div class="filter-wrapper">
        <am2-link-button-dropdown
          :items="dropdown"
          :item-key="activeDropdownFilter"
          :style="{
            marginTop: '8px',
          }"
          @select="handleDropdownItemSelect"
        />
      </div>
    </div>

    <am2-table-control-section
      v-ar-sticky-top="{
        priority: 1,
      }"
      :style="{
        marginTop: '8px',
        backgroundColor: '#f6f9fc',
      }"
      :column-settings="{
        selectedColumnKeys: selectedColumnKeys,
        availableColumns: availableColumns,
        action: handleSelectedColumnKeysUpdate,
      }"
      :fans-search="{
        action: serverSideSearch,
      }"
      :loading="(isFetchingOrders) && orders.length === 0"
      show-checked-rows-options-bar
      show-filter-and-search-bar
      :showFilterButton="false"
      fan-appellation="order"
    />

    <am2-orders-table
      :head="displayedTableHeadings"
      :body="tableBody"
      :loading="isFetchingOrders && tableBody.length === 0"
      empty-text="No order data"
      @sortBy="handleSortChange"
      :enable-row-click="customerProfilesEnabled"
      @rowClick="handleRowClick"
      has-sticky-header
      tableType="orders"
    />
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

const initialOrderBy = { key: 'orderDate', order: 'desc' };

const tableHeadings = [
  {
    name: 'Name',
    // sortKey: 'fanAccount[firstName]',
    key: 'name',
    format: 'name',
    attributeKeys: 'firstName',
  },
  {
    name: 'Qty',
    key: 'totalTickets',
    format: 'qty',
    sortKey: 'totalTickets',
    attributeKeys: 'totalTickets',
  },
  {
    name: 'Order Total',
    key: 'orderTotal',
    format: 'orderTotal',
    // sortKey: 'value',
    attributeKeys: 'value',
  },
  {
    name: 'Order ID',
    key: 'orderId',
    sortKey: 'orderId',
    format: 'orderId',
    attributeKeys: 'orderId',
  },
  {
    name: 'Source',
    key: 'source',
    sortKey: 'provider',
    format: 'source',
    attributeKeys: 'provider',
  },
  {
    name: 'Status',
    key: 'status',
    sortKey: 'status',
    format: 'status',
    attributeKeys: 'status',
  },
  {
    name: 'Order Date',
    key: 'orderTime',
    sortKey: 'orderDate',
    format: 'orderTime',
    attributeKeys: 'orderDate',
  },
];

export default {
  name: 'EventOrders',

  data() {
    return {
      tableHeadings,
      orderBy: initialOrderBy,
      searchString: '',
      availableColumns: [
        {
          name: 'Name',
          value: 'firstName',
          disabled: true,
        },
        {
          name: 'Qty',
          value: 'totalTickets',
        },
        {
          name: 'Order Total',
          value: 'value',
        },
        {
          name: 'Order ID',
          value: 'orderId',
        },
        {
          name: 'Source',
          value: 'provider',
        },
        {
          name: 'Status',
          value: 'status',
        },
        {
          name: 'Order Date',
          value: 'orderDate',
        },
      ],
      selectedColumnKeys: [],
      filter: {
        logic: [],
        conditions: [
          {
            name: 'events',
            type: 'condition-search-picker',
            data: {
              condition: 'true_to_all',
              values: [ this.$route.params.oid ],
            },
          },
        ],
      },
      dropdown: [
        {
          name: 'All orders',
          count: 0,
          key: 'all'
        },
        {
          name: 'Completed',
          count: 0,
          key: 'completed',
        },
        {
          name: 'Paid',
          count: 0,
          key: 'paid',
        },
        {
          name: 'Pending',
          count: 0,
          key: 'pending',
        },
        {
          name: 'Refunded',
          count: 0,
          key: 'refunded',
        },
        {
          name: 'Partially Refunded',
          count: 0,
          key: 'partially-refunded',
        },
        {
          name: 'Transferred',
          count: 0,
          key: 'transferred',
        },
        {
          name: 'Deleted',
          count: 0,
          key: 'deleted',
        },
      ],

      activeDropdownFilter: 'all',
      fanAttributeKeys: ['fanAccount','fanOid','oid','orderCosts','orderDate','orderId','promoterOid','provider','sourceInfo',
        'sourceType','status','sysActivep','sysCtime','sysMtime','totalTickets'],
    };
  },

  computed: {
    ...mapState({
      orders: state => state.orders.orders,
      totalOrdersCount: state => state.orders.totalOrdersCount,
      isFetchingOrders: state => state.orders.isFetchingOrders,
      isNoMoreOrders: state => state.orders.isNoMoreOrders,
    }),
    infiniteScrollDisabled() {
      return this.isFetchingOrders || this.isNoMoreOrders;
    },

    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },

    tableBody() {
      if (!this.orders) {
        return [];
      }

      return this.orders.map(attendee => {
        attendee.avatar = attendee.profileImageUrl;
        attendee.class = attendee.status;

        return attendee;
      });
    },

    displayedTableHeadings() {
      return this.tableHeadings.filter(heading => {
        if (this.selectedColumnKeys && this.selectedColumnKeys.includes(heading.attributeKeys)) {
          return heading;
        }
      });
    },
  },

  created() {
    const orderTableSettings = window.localStorage.getItem('_ar_promoter_order_preferences_');
    this.$store.registerModule("event-view-child", {
      mutations: {
        FORCED_EVENT_UPDATE(state) {
          this.loadMoreData();
        }
      }
    });
    this.reloadData();

    this.selectedColumnKeys = this.availableColumns.reduce((keys, { value }) => {
      keys.push(value);
      return keys;
    }, [])
  },

  beforeDestroy() {
    // this.$store.unregisterModule("event-view-child");
    this['orders/RESET_ORDERS']();
  },

  watch: {
    selectedColumnKeys() {
      this.updateFanAttributeKeys()
    }
  },

  methods: {
    ...mapActions([
      'orders/FETCH_MORE_ORDERS',
      'orders/EXPORT_ORDERS_CSV',
    ]),
    ...mapMutations(['orders/RESET_ORDERS']),

    async handleSortChange(orderObj) {
      let key = orderObj.key;
      const order = orderObj.order;

      if (key == 'value') {
        key = 'orderCosts[gross,value]'
      }
      if (key == 'firstName') {
        key = 'fanAccount[firstName]'
      }

      this.orderBy = {
        key,
        order,
      };

      this.reloadData()
    },

    loadMoreData() {
      this['orders/FETCH_MORE_ORDERS']({
        searchString: this.searchString,
        selectKeys: this.fanAttributeKeys,
        filter: this.filter,
        orderBy: this.orderBy,
        eventOid: this.$route.params.oid,
        dropdownFilter: this.activeDropdownFilter,
      });
    },

    async serverSideSearch(searchString) {
      this.searchString = searchString;
      this.reloadData();
    },

    reloadData() {
      this['orders/FETCH_MORE_ORDERS']({
        searchString: this.searchString,
        selectKeys: this.fanAttributeKeys,
        filter: this.filter,
        orderBy: this.orderBy,
        reload: true,
        eventOid: this.$route.params.oid,
        dropdownFilter: this.activeDropdownFilter,
      });
    },

    handleRowClick(target) {
      if (this.customerProfilesEnabled && target && target.fanOid) {
        this.$router.push({
          path: `/audience/${target.fanOid}/view/overview`,
        });
      }
    },

    async serverSideSearch(searchString) {
      // Remember to clear selection
      this.searchString = searchString;
      this.reloadData();
    },

    handleSelectedColumnKeysUpdate(columnKeys) {
      this.selectedColumnKeys = columnKeys;

      this.reloadData();
    },

    handleDropdownItemSelect (item) {
      this.activeDropdownFilter = item.key;

      this.reloadData();
    },

    updateFanAttributeKeys() {
      let res = ['fanAccount', 'orderCosts'];
      for (let i = 0; i < this.tableHeadings.length; i += 1) {
        const heading = this.tableHeadings[i];
        if (this.selectedColumnKeys.includes(heading.attributeKeys) && heading.attributeKeys) {
          if (Array.isArray(heading.attributeKeys)) {
            res = res.concat(heading.attributeKeys);
          } else {
            res.push(heading.attributeKeys);
          }
        }
      }

      this.fanAttributeKeys = res;
    },
  },
};
</script>

<style lang="scss">
/* LEADERBOARD ------------------------ */
.event-orders-container {
  padding: 0 10px;
  margin-bottom: 30px !important;

  .orders-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .filter-wrapper {
      border-left: 1px solid #DCE0E4;
      height: 30px;
      padding-left: 15px;
    }
  }

  table {
    td:nth-child(1) {
      width: 60%;
    }
  }
}
</style>
