<template>
  <section>
    <am2-heading
      type="h1"
      size="lg"
      title="Admin Fixes"
      class="u-margin-top-6 u-margin-bottom-2" />
    <ar-text
      class="u-margin-bottom-8"
      multiple-lines
      size="xs"
      text="Trigger actions to recalculate data and fix broken resources on promoter accounts. Please don't abuse this
            functionality. Each of these buttons may potentially trigger a lot of work on the server, so we should avoid
            spamming them."
    />

    <div class="fixes-page-wrapper">
      <am2-heading
        type="h3"
        title="Select account"
        size="md"
        :style="{ marginBottom: '10px' }" />

      <am2-promoter-select
        class="select u-margin-bottom-4"
        :value="125"
        @input="handlePromoterSelect"
        :style="{ width: '500px' }"
      />


      <div v-if="selectedPromoterOid">
        <ar-divider
          class="u-margin-y-4"
        />
        <am2-card-container
          class="u-padding-5"
          layout="soft">
            <div
              :style="{ marginBottom: '12px', display: 'flex', width: '100%' }">
              <ar-icon
                width="24px"
                name="ar-logo-mono"
                class="u-margin-right-3"
              />
              <am2-heading
                type="h3"
                title="Fix Dashboard Sales Graph Data"
                size="md" />
            </div>

            <ar-text
              class="u-margin-bottom-3"
              multiple-lines
              size="xs"
              text="If a promoter's dashboard sales chart is incorrect, we can delete and re-create the underlying data to fix it.
                    While this fix is running, the chart may show incomplete or incorrect data!"
            />
          <ar-text
            class="u-margin-bottom-5"
            multiple-lines
            size="xs"
            weight="bold"
            text="Running time - approx 1s per day of sales (assume 5 minutes for a year of continuous daily sales)."
          />
          <div :style="{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }">
            <div :style="{minWidth: '418px'}">
              <ar-simple-button
                id="refresh-sales-stats-snapshots-button"
                data-test-id="refresh-sales-stats-snapshots-button"
                :text="`${salesStatsRefreshPrefixWord}Delete and Recreate Sales Stats Snapshots`"
                :icon-name="confirmRefreshSalesSnapshots || isRefreshingSalesSnapshots ? 'exclamation-mark' : 'refresh'"
                :icon-props="{
                  width: confirmRefreshSalesSnapshots || isRefreshingSalesSnapshots ? '18px' : '14px',
                }"
                :disabled="isRefreshingSalesSnapshots"
                :type="confirmRefreshSalesSnapshots || isRefreshingSalesSnapshots ? 'red' : 'purple'"
                @click="handleRefreshSalesSnapshotsClick"
              />
            </div>
            <ar-text
              v-if="confirmRefreshSalesSnapshots"
              text="<a>Cancel refresh</a>"
              size="xs"
              class="u-margin-left-4"
              allow-html
              :style="{
                alignSelf: 'center',
                color: 'red',
              }"
              @anchorClick="handleCancelSalesStatsRefresh"
            />
          </div>
        </am2-card-container>
      </div>
    </div>

  </section>

</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'AdminFixes',
    layout: 'default',

    data: () => ({
      selectedPromoterOid: null,
      chargebeePromoterPlan: null,
      demoPromoterPlan: null,
      isUpdatingPromoterPlan: false,
      isRefreshingSalesSnapshots: false,
      confirmRefreshSalesSnapshots: false,
    }),

    computed: {
      ...mapGetters ({
        isAdminAccount: 'auth/isAdminAccount',
      }),

      salesStatsRefreshPrefixWord() {
        if (this.isRefreshingSalesSnapshots) {
          return 'RUNNING: ';
        }
        if (this.confirmRefreshSalesSnapshots) {
          return 'CONFIRM: ';
        }
        return '';
      }
    },

    created() {
      if (!this.isAdminAccount) {
        this.$router.push({
          path: '/audience',
        });
      }
    },

    methods: {
      ...mapActions([
        'admin/FORCE_REFRESH_PROMOTER_SALES_STATS',
      ]),

      handleRefreshSalesSnapshotsClick() {
        if (this.isRefreshingSalesSnapshots) return;
        if (!this.confirmRefreshSalesSnapshots) {
          this.confirmRefreshSalesSnapshots = true;
          return;
        }
        this.isRefreshingSalesSnapshots = true;
        this.confirmRefreshSalesSnapshots = false;
        this['admin/FORCE_REFRESH_PROMOTER_SALES_STATS']({targetPromoterOid: this.selectedPromoterOid})
      },

      handleCancelSalesStatsRefresh() {
        this.confirmRefreshSalesSnapshots = false;
      },

      async handlePromoterSelect(promoterOid) {
        this.resetFlags();
        this.selectedPromoterOid = promoterOid;
      },

      resetFlags() {
        this.isRefreshingSalesSnapshots = false;
        this.confirmRefreshSalesSnapshots = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .fixes-page-wrapper {
    margin: 30px;

    .rotation {
      animation: rotator 3s infinite linear;
    }
    @keyframes rotator {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }
</style>
